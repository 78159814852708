import { html } from 'lit';
import type { OneUxDatePickerElement } from '../OneUxDatePickerElement.js';
import { isoDateString, isoDate } from '../IsoDate.js';
import type { OneUxListElement } from '../../one-ux-list/OneUxListElement.js';
import { modulo } from '../../../utils/modulo.js';
import { register as _registerElement } from "../../one-ux-widget-button/register-element.js";
import { register as _registerElement2 } from "../../one-ux-adapter/register-element.js";
import { register as _registerElement3 } from "../../one-ux-dropdown/register-element.js";
import { register as _registerElement4 } from "../../one-ux-list/register-element.js";
_registerElement4("list-43c555bf7dbdc8c2cbe459d354aca553");
_registerElement3("dropdown-dbd1c4803e030682bfba47a81d754e77");
_registerElement2("adapter-b2430a6bc1198aae2e98c39d869b6450");
_registerElement("widget-button-b4c10b74f4e37bdb49c0cd71fc2bd07f");
export function Navigation(this: OneUxDatePickerElement, {
  activeDate,
  monthNames,
  onActiveChange
}: {
  activeDate: isoDateString;
  monthNames: string[];
  onActiveChange: (activeDate: isoDateString) => void;
}) {
  const numberOfMonthsBefore = 3;
  const numberOfMonthsAfter = 8;
  const [year, month] = isoDate.getParts(activeDate);
  const deltaYearOptions = Array.from({
    length: numberOfMonthsBefore + 1 + numberOfMonthsAfter
  }, (_, index) => {
    const deltaMonths = index - numberOfMonthsBefore;
    const accumulatedMonthValue = month - 1 + deltaMonths;
    const deltaYears = Math.floor(accumulatedMonthValue / 12);
    return {
      text: `${monthNames[modulo(accumulatedMonthValue, 12)]} ${year + deltaYears}`,
      value: deltaMonths
    };
  });
  return html`
    <div class="navigation">
      <widget-button-b4c10b74f4e37bdb49c0cd71fc2bd07f
        icon="toggle-left"
        @click=${() => onActiveChange(isoDate.addMonths(activeDate, -1))}
        ?disabled=${this.disabled}
      ></widget-button-b4c10b74f4e37bdb49c0cd71fc2bd07f>

      <adapter-b2430a6bc1198aae2e98c39d869b6450 disable-notify>
        <dropdown-dbd1c4803e030682bfba47a81d754e77 tabindex="-1" ?disabled=${this.disabled}>
          <span slot="preview" class="month-and-year">${monthNames[month - 1]} ${year}</span>
          <list-43c555bf7dbdc8c2cbe459d354aca553
            .value=${[0]}
            .options=${deltaYearOptions}
            @input=${(e: Event & {
    target: OneUxListElement;
  }) => onActiveChange(isoDate.addMonths(activeDate, e.target.value[0] as number))}
          ></list-43c555bf7dbdc8c2cbe459d354aca553>
        </dropdown-dbd1c4803e030682bfba47a81d754e77>
      </adapter-b2430a6bc1198aae2e98c39d869b6450>

      <widget-button-b4c10b74f4e37bdb49c0cd71fc2bd07f
        icon="toggle-right"
        @click=${() => onActiveChange(isoDate.addMonths(activeDate, 1))}
        ?disabled=${this.disabled}
      ></widget-button-b4c10b74f4e37bdb49c0cd71fc2bd07f>
    </div>
  `;
}