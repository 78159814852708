import type { OneUxCardElement } from '../../../one-ux-card/OneUxCardElement.js';
import type { OneUxStackElement } from '../../OneUxStackElement.js';
import type { IStackContext, stackContext } from '../../types.js';
import { cardBaseIndentation } from './constants.js';
const transitionClassName = 'one-ux-stack-transition';
export class CardStackContext implements IStackContext {
  #element: OneUxStackElement;
  get contextType(): stackContext {
    return 'one-ux-card';
  }
  constructor($element: OneUxStackElement) {
    this.#element = $element;
  }
  #animationFrameId = -1;
  openAction(): Promise<void> {
    return new Promise(resolve => {
      this.#element.addEventListener('transitionend', () => {
        cancelAnimationFrame(this.#animationFrameId);
        this.#element.classList.toggle(transitionClassName, false);
        resolve();
      }, {
        once: true
      });
    });
  }
  beforeOpenAction(): void {
    this.#element.classList.toggle(transitionClassName, true);
    this.#updateTransitionState();
  }
  #updateTransitionState() {
    cancelAnimationFrame(this.#animationFrameId);
    const update = () => {
      this.#updateCardClippingAndHeight();
      this.#animationFrameId = requestAnimationFrame(update);
    };
    update();
  }
  #updateCardClippingAndHeight() {
    const $cards = this.#element.children as HTMLCollectionOf<OneUxCardElement>;
    const thresholdDistance = 8;
    const clipAnchor = $cards[0].getBoundingClientRect().bottom - thresholdDistance;
    for (let i = 1; i < $cards.length; i++) {
      const $card = $cards[i];
      const {
        top,
        height
      } = $card.getBoundingClientRect();
      const clipDistance = clipAnchor - top >= 0 ? Math.ceil(clipAnchor - top) : 0;
      $card.style.setProperty('--one-ux-stack--card-height', `${height}px`);
      $card.style.setProperty('--one-ux-stack--card-clip-distance', `${clipDistance}px`);
    }
  }
  onRender(): void {
    requestAnimationFrame(() => {
      const $cards = this.#element.children as HTMLCollectionOf<OneUxCardElement>;
      const stackWidth = $cards[0].getBoundingClientRect().width;
      this.#element.classList.toggle('show-pseudo-card-first', $cards.length > 1);
      this.#element.classList.toggle('show-pseudo-card-second', $cards.length > 2);
      this.#element.style.setProperty('--one-ux-stack--card-count', String($cards.length));
      this.#element.style.setProperty('--one-ux-stack--card-scaled-base-indentation', `${parseInt(cardBaseIndentation, 10) / stackWidth}`);
      for (let i = 0; i < $cards.length; i++) {
        const $card = $cards[i];
        $card.style.setProperty('--one-ux-stack--card-index', String(i));
        if (i > 0) {
          const cardHeight = $card.getBoundingClientRect().height;
          $card.style.setProperty('--one-ux-stack--card-height', `${cardHeight}px`);
        }
      }
    });
  }
}