export abstract class Action {
  completed: boolean;
  constructor() {
    this.completed = false;
  }
  abstract execute(): Promise<unknown> | void;
  applyCacheQuery(url: string) {
    if (window.__pdr_mfe_cache_query__) {
      return `${url}?${window.__pdr_mfe_cache_query__}`;
    }
    return url;
  }
}