type OverflowDirection = 'none' | 'left' | 'both' | 'right';
export const setOverflow = ($tabslistNav: HTMLElement) => {
  const direction = getOverflowDirection($tabslistNav.querySelector('[role="tablist"]') as HTMLElement);
  for (const className of $tabslistNav.className.split(' ').filter(x => x.startsWith('state-overflow-'))) {
    $tabslistNav.classList.remove(className);
  }
  $tabslistNav.classList.add(`state-overflow-${direction}`);
};
const getOverflowDirection = ($tablist: HTMLElement): OverflowDirection => {
  if (!$tablist || $tablist.scrollWidth <= $tablist.clientWidth) {
    return 'none';
  }
  const maxScrollLeft = $tablist.scrollWidth - $tablist.clientWidth;
  const scrollThreasholdBuffer = 2;
  if ($tablist.scrollLeft === 0) {
    return 'left';
  } else if (Math.ceil($tablist.scrollLeft) >= maxScrollLeft - scrollThreasholdBuffer) {
    return 'right';
  } else {
    return 'both';
  }
};