import { ReactiveController } from 'lit';
import { OneUxPopoutElement } from './OneUxPopoutElement.js';
import { getCursorPosition } from '../../utils/mouse-helper.js';
import { clamp } from '../../visualizations/common/math.js';
type DOMRectExtra = DOMRect & {
  originalLeft: number;
  originalTop: number;
  originalWidth: number;
  originalHeight: number;
};
export class PlacementController implements ReactiveController {
  private animateRequest = 0;
  private prevTop = -Infinity;
  private prevLeft = -Infinity;
  private referenceCursorOffsetX = -1;
  private referenceCursorOffsetY = -1;

  /* used to lock position to cursor at given time when reference set to "locked-cursor" */
  private lockedCursorX = -1;
  private lockedCursorY = -1;
  constructor(private $popout: OneUxPopoutElement) {
    this.$popout.addController(this);
  }
  hostConnected() {
    this.$popout.toggleAttribute('state-initial', true);
    this.$popout.setAttribute('popover', 'manual');
    this.$popout.showPopover();
    this.animate();
  }
  hostDisconnected() {
    cancelAnimationFrame(this.animateRequest as number);
    this.referenceCursorOffsetX = -1;
    this.referenceCursorOffsetY = -1;
    this.$popout.hidePopover();
  }
  private animate = () => {
    this.animateRequest = requestAnimationFrame(() => {
      const $popout = this.$popout;
      $popout.toggleAttribute('state-initial', false);
      let referenceRect = this.#getReferenceRect();
      if (referenceRect) {
        if (this.$popout.containToViewport) {
          referenceRect = this.#clipRectToViewport(referenceRect);
        }
        if ($popout.alignment === 'cursor') {
          if (this.referenceCursorOffsetX === -1 || this.referenceCursorOffsetY === -1) {
            const {
              cursorX,
              cursorY
            } = getCursorPosition();
            this.referenceCursorOffsetX = clamp(cursorX - referenceRect.left, 0, referenceRect.width);
            this.referenceCursorOffsetY = clamp(cursorY - referenceRect.top, 0, referenceRect.height);
          }
          if ($popout.direction === 'horizontal') {
            Object.assign(referenceRect, {
              originalTop: referenceRect.top,
              top: referenceRect.top + this.referenceCursorOffsetY,
              originalHeight: referenceRect.height,
              height: 0
            });
          } else {
            Object.assign(referenceRect, {
              originalLeft: referenceRect.left,
              left: referenceRect.left + this.referenceCursorOffsetX,
              originalWidth: referenceRect.width,
              width: 0
            });
          }
        }
        let {
          top,
          left
        } = this.getPositions(referenceRect);
        top = Math.floor(top);
        left = Math.floor(left);
        if (top !== this.prevTop && Math.abs(top - this.prevTop) > 1) {
          $popout.style.top = top + 'px';
          this.prevTop = top;
        }
        if (left !== this.prevLeft && Math.abs(left - this.prevLeft) > 1) {
          $popout.style.left = left + 'px';
          this.prevLeft = left;
        }
      }
      this.animate();
    });
  };
  private getPositions = (referenceRect: DOMRectExtra): Position => {
    const $popout = this.$popout;
    const popoutWidth = $popout.offsetWidth;
    const popoutHeight = $popout.offsetHeight;
    const viewportWidth = document.documentElement.clientWidth;
    const viewportHeight = document.documentElement.clientHeight;
    const checkOverflows = (top: number, left: number) => ({
      top: top < 0,
      bottom: top + popoutHeight > viewportHeight,
      left: left < 0,
      right: left + popoutWidth > viewportWidth
    });
    const isNotOverflowing = (overflows: {
      top: boolean;
      bottom: boolean;
      left: boolean;
      right: boolean;
    }) => {
      return !overflows.top && !overflows.bottom && !overflows.left && !overflows.right;
    };
    const getTopPosition = ({
      placement,
      alignment
    }: PositioningOptions): number => {
      let offset = 0;
      if ($popout.direction === 'vertical') {
        offset = placement === 'before' ? -$popout.offsetReference : $popout.offsetReference;
      } else if ($popout.direction === 'horizontal') {
        offset = alignment === 'end' ? -$popout.offsetAlignment : $popout.offsetAlignment;
      }
      if ($popout.direction === 'vertical') {
        if (placement === 'before') {
          return referenceRect.top - popoutHeight + offset;
        } else if (placement === 'center') {
          return referenceRect.top + referenceRect.height / 2 - popoutHeight / 2;
        } else {
          return referenceRect.bottom + offset;
        }
      } else {
        if (alignment === 'start') {
          return referenceRect.top + offset;
        } else if (alignment === 'center') {
          return referenceRect.top + referenceRect.height / 2 - popoutHeight / 2;
        } else {
          return referenceRect.bottom - popoutHeight + offset;
        }
      }
    };
    const getLeftPosition = ({
      placement,
      alignment
    }: PositioningOptions): number => {
      let offset = 0;
      if ($popout.direction === 'horizontal') {
        offset = placement === 'before' ? -$popout.offsetReference : $popout.offsetReference;
      } else if ($popout.direction === 'vertical') {
        offset = alignment === 'end' ? -$popout.offsetAlignment : $popout.offsetAlignment;
      }
      if ($popout.direction === 'horizontal') {
        if (placement === 'before') {
          return referenceRect.left - popoutWidth + offset;
        } else if (placement === 'center') {
          return referenceRect.left + referenceRect.width / 2 - popoutWidth / 2;
        } else {
          return referenceRect.right + offset;
        }
      } else {
        if (alignment === 'start') {
          return referenceRect.left + offset;
        } else if (alignment === 'center') {
          return referenceRect.left + referenceRect.width / 2 - popoutWidth / 2;
        } else {
          return referenceRect.right - popoutWidth + offset;
        }
      }
    };

    // Stage 1 attempt to position popout at configured alignment and placement.
    const positioning: PositioningOptions = {
      placement: $popout.placement,
      alignment: $popout.alignment === 'cursor' ? 'center' : $popout.alignment
    };
    let top = getTopPosition(positioning);
    let left = getLeftPosition(positioning);
    let overflows = checkOverflows(top, left);
    if (isNotOverflowing(overflows)) {
      return {
        top,
        left
      };
    }
    // Stage 1 over, element could not be placed without overflow.

    // Stage 2 depending on settings different things will happen
    if ($popout.alignment === 'cursor') {
      // Stage 2a, when aligning to the cursor attempt to flip and/or nudge the position so that the popout does not overflow.
      const space = 4;
      if ($popout.direction === 'horizontal') {
        if (overflows.top) {
          top = Math.max(top, Math.min(0, referenceRect.bottom)) + space;
        }
        if (overflows.bottom) {
          const bottom = Math.min(top + popoutHeight, Math.max(viewportHeight, referenceRect.originalTop));
          top = bottom - popoutHeight - space;
        }
        if ($popout.placement === 'after' && overflows.right) {
          positioning.placement = 'before';
        }
        if ($popout.placement === 'before' && overflows.left) {
          positioning.placement = 'after';
        }
        left = getLeftPosition(positioning);
      } else {
        if (overflows.left) {
          left = Math.max(left, Math.min(0, referenceRect.right)) + space;
        }
        if (overflows.right) {
          const right = Math.min(left + popoutWidth, Math.max(viewportWidth, referenceRect.originalLeft));
          left = right - popoutWidth - space;
        }
        if ($popout.placement === 'after' && overflows.bottom) {
          positioning.placement = 'before';
        }
        if ($popout.placement === 'before' && overflows.top) {
          positioning.placement = 'after';
        }
        top = getTopPosition(positioning);
      }
      overflows = checkOverflows(top, left);
      if (isNotOverflowing(overflows)) {
        return {
          top,
          left
        };
      }
    } else {
      // Stage 2b, for other alignments attempt to flip the popout.
      if ($popout.direction === 'horizontal') {
        if (($popout.alignment === 'end' || $popout.alignment === 'center') && overflows.top) {
          positioning.alignment = 'start';
        }
        if (($popout.alignment === 'start' || $popout.alignment === 'center') && overflows.bottom) {
          positioning.alignment = 'end';
        }
        if ($popout.placement === 'after' && overflows.right) {
          positioning.placement = 'before';
        }
        if ($popout.placement === 'before' && overflows.left) {
          positioning.placement = 'after';
        }
      } else {
        if (($popout.alignment === 'end' || $popout.alignment === 'center') && overflows.left) {
          positioning.alignment = 'start';
        }
        if (($popout.alignment === 'start' || $popout.alignment === 'center') && overflows.right) {
          positioning.alignment = 'end';
        }
        if ($popout.placement === 'after' && overflows.bottom) {
          positioning.placement = 'before';
        }
        if ($popout.placement === 'before' && overflows.top) {
          positioning.placement = 'after';
        }
      }
      top = getTopPosition(positioning);
      left = getLeftPosition(positioning);
      overflows = checkOverflows(top, left);
      if (isNotOverflowing(overflows)) {
        return {
          top,
          left
        };
      }
    }
    // Stage 2 over, could not nudge (cursor alignment) and/or flip without overflow.

    // Stage 3, attempt to center on top of reference element if allowed.
    if (!$popout.preventOverlap) {
      if ($popout.direction === 'horizontal' && (overflows.left || overflows.right) || $popout.direction === 'vertical' && (overflows.top || overflows.bottom)) {
        positioning.placement = 'center';
        top = getTopPosition(positioning);
        left = getLeftPosition(positioning);
      }
    }

    // Regardless of overflow status we always return the result of the last attempt.
    // This means that overflow is still possible in the end.
    return {
      top,
      left
    };
  };
  #getReferenceRect(): DOMRectExtra | null {
    if (this.$popout.reference === 'locked-cursor') {
      if (this.lockedCursorX === -1 || this.lockedCursorY === -1) {
        const {
          cursorX,
          cursorY
        } = getCursorPosition();
        this.lockedCursorX = cursorX;
        this.lockedCursorY = cursorY;
      }
      return this.#createDOMRectExtra(0, 0, this.lockedCursorY, this.lockedCursorX, this.lockedCursorY, this.lockedCursorX);
    } else {
      this.lockedCursorX = -1;
      this.lockedCursorY = -1;
    }
    const $referenceElement = this.#getReferenceElement();
    if (!$referenceElement) {
      return null;
    }
    return $referenceElement.getBoundingClientRect().toJSON() as DOMRectExtra;
  }
  #getReferenceElement(): HTMLElement | null {
    const $popout = this.$popout;
    let $referenceElement: HTMLElement | null;
    if ($popout.reference instanceof Element) {
      $referenceElement = $popout.reference as HTMLElement;
    } else if ($popout.reference === 'previous') {
      $referenceElement = $popout.previousElementSibling as HTMLElement;
    } else {
      let depth = $popout.referenceDepth;
      $referenceElement = $popout;
      while (depth > 0) {
        $referenceElement = $referenceElement.parentElement || ($referenceElement.getRootNode() as ShadowRoot).host as HTMLElement;
        --depth;
      }
    }
    return $referenceElement;
  }
  #clipRectToViewport(rect: DOMRectExtra): DOMRectExtra {
    const left = Math.max(0, Math.min(rect.left, window.innerWidth));
    const right = Math.max(0, Math.min(rect.right, window.innerWidth));
    const top = Math.max(0, Math.min(rect.top, window.innerHeight));
    const bottom = Math.max(0, Math.min(rect.bottom, window.innerHeight));
    const width = right - left;
    const height = bottom - top;
    return this.#createDOMRectExtra(width, height, top, right, bottom, left, rect.originalWidth, rect.originalHeight, rect.originalLeft, rect.originalTop);
  }
  #createDOMRectExtra(width: number, height: number, top: number, right: number, bottom: number, left: number, originalWidth = 0, originalHeight = 0, originalLeft = 0, originalTop = 0): DOMRectExtra {
    return {
      originalWidth,
      originalHeight,
      originalLeft,
      originalTop,
      width,
      height,
      x: left,
      y: top,
      top,
      right,
      bottom,
      left
    } as DOMRectExtra;
  }
}
type PositioningOptions = {
  placement: 'before' | 'after' | 'center';
  alignment: 'start' | 'center' | 'end';
};
type Position = {
  top: number;
  left: number;
};