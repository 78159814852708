import { PDRModule } from '../PDRModule.js';
import { toastType, Toast } from './Toast.js';
export class ToastModule extends PDRModule {
  async confirmation(title: string, description?: string, duration?: number | boolean, copy?: boolean) {
    return this.#show('confirmation', title, description, duration, copy);
  }
  async info(title: string, description?: string, duration?: number | boolean, copy?: boolean) {
    return this.#show('info', title, description, duration, copy);
  }
  async warning(title: string, description?: string, duration?: number | boolean, copy?: boolean) {
    return this.#show('warning', title, description, duration, copy);
  }
  async #show(type: toastType, title: string, description?: string, duration?: number | boolean, copy?: boolean) {
    const toast = new Toast(type, title, description, duration, copy);
    return toast.show();
  }
}