export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
export const copy = (obj: any) => JSON.parse(JSON.stringify(obj));
export const stringify = (obj: any) => JSON.stringify(obj);
export const keyCode = {
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  PageDown: 'PageDown',
  PageUp: 'PageUp',
  Home: 'Home',
  End: 'End',
  Tab: 'Tab',
  Space: 'Space',
  Enter: 'Enter',
  NumpadEnter: 'NumpadEnter',
  Backspace: 'Backspace',
  Escape: 'Escape',
  Delete: 'Delete',
  // modifiers
  Shift: 'Shift',
  Control: 'Control',
  Alt: 'Alt'
} as const;
export const modifiedKeyCode = (...keyCodes: (keyof typeof keyCode)[]) => keyCodes.join('+');