import { createContext } from '@lit/context';
import { OneUxIconToken } from '../generated/design-tokens.js';
export type updatePreviewOptions = {
  interactive?: boolean;
  icon?: {
    set: keyof OneUxIconToken;
    icon: OneUxIconToken[keyof OneUxIconToken];
  };
};
export interface IDropdownContext {
  updatePreview(preview: unknown, options?: updatePreviewOptions): void;
  placeholder: string;
  disabled: boolean;
  readonly: boolean;
}
const contextKey = Symbol('one-ux-dropdown-context');
export const defaultDropdownContext: IDropdownContext = {
  updatePreview(_preview: unknown, _options?: updatePreviewOptions) {},
  placeholder: '',
  disabled: false,
  readonly: false
};
export const dropdownContext = createContext<IDropdownContext>(contextKey);