export class HideTooltipEvent extends Event {
  static get eventName() {
    return 'internal-one-ux-tooltip-hide';
  }
  constructor() {
    super(HideTooltipEvent.eventName, {
      bubbles: true,
      cancelable: false,
      composed: true
    });
  }
}