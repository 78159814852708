import { css } from 'lit';
export const style = css`
  :host {
    width: 219px;
    min-width: 74px;

    transition-property: width, min-width;
    transition-duration: var(--one-ux-duration--400);
    transition-timing-function: var(--one-ux-easing--default);
  }

  :host([state-collapsed]) {
    width: 32px !important;
    min-width: 0 !important;
  }

  .one-ux-element--root {
    --border-color: var(--one-ux-palette--brand-neutral-300);
    --border-width: var(--one-ux-border-width--500);

    display: flex;
    align-items: flex-start;
    gap: var(--one-ux-spacing--small);
    padding: 6px;
    overflow: hidden;
    position: relative;

    appearance: none;
    outline: none;
    background: var(--one-ux-palette--gray-100);
    color: var(--one-ux-palette--brand-neutral-400);
    border: none;
    border-radius: var(--one-ux-radius--300);
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    cursor: text;

    transition-property: background, border, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      border: var(--border-width) solid var(--border-color);
      transition: inherit;
      pointer-events: none;
    }

    ::placeholder {
      color: var(--one-ux-palette--brand-neutral-400);
    }

    &:hover {
      color: var(--one-ux-palette--brand-neutral-600);
      --border-color: var(--one-ux-palette--brand-neutral-400);
    }

    &:focus-within {
      color: var(--one-ux-palette--brand-neutral-600);
      --border-color: var(--one-ux-palette--focus-400);
    }

    &:not(.empty) {
      color: var(--one-ux-palette--brand-neutral-600);
    }

    :host([state-collapsed]) & {
      cursor: pointer;

      &:not(:hover, :focus-within) {
        color: var(--one-ux-palette--brand-neutral-600);
      }
    }

    :host([implicit]) & {
      --border-color: transparent;
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.05);

      &:focus-within {
        --border-color: var(--one-ux-palette--focus-400);
      }
    }

    :host([implicit][state-collapsed]) &:not(:hover) {
      background: none;
    }

    :host([disabled]) & {
      --border-color: var(--one-ux-palette--gray-300) !important;
      background: var(--one-ux-palette--gray-150) !important;
      color: var(--one-ux-palette--gray-400) !important;
      cursor: default !important;

      ::placeholder {
        color: var(--one-ux-palette--gray-400) !important;
      }
    }

    :host([disabled][implicit]) & {
      --border-color: transparent !important;
      background: none !important;
    }

    :host([state-keyboard-focus]:not([disabled])) &:focus-within {
      --border-color: var(--one-ux-palette--keyboard-focus-400) !important;
      background: var(--one-ux-palette--keyboard-focus-200) !important;
      color: var(--one-ux-palette--keyboard-focus-700) !important;
    }
  }

  input {
    all: unset;
    text-align: left;
    line-height: 18px;
    height: 20px;
    box-sizing: border-box;
    flex: 1 1 auto;
    width: 0;
  }

  input,
  .clear {
    transition-property: opacity;
    transition-duration: var(--one-ux-duration--400);
    transition-timing-function: var(--one-ux-easing--default);
    opacity: 1;

    :host([state-collapsed]) & {
      visibility: hidden;
      opacity: 0;
    }
  }

  .indicator {
    --indicator-indentation: var(--one-ux-spacing--small);
    --indicator-gap: var(--one-ux-spacing--smaller);
    --indicator-background: var(--one-ux-palette--brand-neutral-300);

    --busy-duration: 1.5s; /* TODO: Missing token for duration */
    --busy-easing: var(--one-ux-easing--default);

    --done-duration: 1s; /* TODO: Missing token for duration */
    --done-easing: var(--one-ux-easing--default);
    --done-opacity-end: 0;
    --done-color: var(--one-ux-palette--mint-400);

    position: absolute;
    pointer-events: none;
    background-color: inherit;
    opacity: 0;
    transition-property: opacity, background;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);

    border: solid transparent;
    border-inline-width: var(--indicator-gap);
    border-block-width: 0;
    inset-inline: var(--indicator-indentation);
    block-size: var(--border-width);
    inset-block-end: 0;

    &:before {
      content: '';
      display: block;
      block-size: 100%;
      background: var(--indicator-background);
      border-radius: var(--one-ux-radius--100);
      transition-duration: inherit;
      transition-timing-function: inherit;
    }

    .one-ux-element--root:not(:focus-within) &.passive,
    &.busy {
      opacity: 1;
    }

    .one-ux-element--root:hover & {
      --indicator-background: var(--one-ux-palette--brand-neutral-400);
    }

    &.busy {
      --indicator-background: var(--one-ux-palette--focus-400);

      animation:
        var(--busy-duration) linear infinite busy-opacity,
        calc(2 * var(--busy-duration)) var(--busy-easing) infinite busy-edges;

      .one-ux-element--root:focus-within & {
        --indicator-background: var(--one-ux-palette--brand-neutral-500);
      }
    }

    &.done {
      animation: var(--done-duration) var(--done-easing) done;
    }
  }

  @keyframes busy-opacity {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes busy-edges {
    0% {
      inset-inline-start: var(--indicator-indentation);
      inset-inline-end: calc(100% - var(--indicator-indentation));
      border-inline-width: var(--indicator-gap);
    }
    8% {
      inset-inline-start: var(--indicator-indentation);
    }
    25% {
      border-inline-width: var(--one-ux-spacing--small);
    }
    35% {
      inset-inline-end: var(--indicator-indentation);
    }
    50% {
      inset-inline-start: calc(100% - var(--indicator-indentation));
      inset-inline-end: var(--indicator-indentation);
      border-inline-width: var(--indicator-gap);
    }
    58% {
      inset-inline-end: var(--indicator-indentation);
    }
    75% {
      border-inline-width: var(--one-ux-spacing--small);
    }
    85% {
      inset-inline-start: var(--indicator-indentation);
    }
    100% {
      inset-inline-start: var(--indicator-indentation);
      inset-inline-end: calc(100% - var(--indicator-indentation));
      border-inline-width: var(--indicator-gap);
    }
  }

  @keyframes done {
    0% {
      --indicator-background: var(--done-color);
      inset-inline: calc(50% - var(--indicator-indentation));
      opacity: 0;
      border-inline-width: var(--one-ux-spacing--large);
    }

    50% {
      --indicator-background: var(--done-color);
      inset-inline: var(--indicator-indentation);
      opacity: 1;
    }

    90% {
      opacity: 1;
    }

    100% {
      opacity: var(--done-opacity-end);
      border-inline-width: var(--indicator-gap);
    }
  }
`;