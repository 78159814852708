import { css } from 'lit';
export const style = css`
  .one-ux-element--root {
    outline: none;
    display: flex;
    flex-direction: column;
  }

  .tabs {
    --one-ux-tabs--height: 28px;
    --one-ux-tabs--border-width: 3px;
    --one-ux-tabs--border-color: transparent;
    --one-ux-tabs--text-color: var(--one-ux-palette--brand-neutral-500);
    --one-ux-tabs--font: var(--one-ux-font--body-variant-300);
    --one-ux-tabs--letter-spacing: var(--one-ux-letter-spacing--body-variant-300);

    --one-ux-tabs--mask-distance: var(--one-ux-spacing--700);
    --one-ux-tabs--mask-left: linear-gradient(
      to right,
      currentcolor calc(100% - var(--one-ux-tabs--mask-distance)),
      transparent 100%
    );
    --one-ux-tabs--mask-both: linear-gradient(
      to right,
      transparent 0%,
      currentcolor var(--one-ux-tabs--mask-distance),
      currentcolor calc(100% - var(--one-ux-tabs--mask-distance)),
      transparent 100%
    );
    --one-ux-tabs--mask-right: linear-gradient(
      to right,
      transparent 0%,
      currentcolor var(--one-ux-tabs--mask-distance)
    );
  }

  :host([explicit]) .tabs,
  .is-subtabs {
    background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
  }

  :host([weight='low']) .tabs,
  :host([weight='normal']) .tabs.is-subtabs {
    --one-ux-tabs--height: 24px;
    --one-ux-tabs--border-width: 2px;
    --one-ux-tabs--font: var(--one-ux-font--body-200);
    --one-ux-tabs--letter-spacing: var(--one-ux-letter-spacing--body-200);
  }

  :host([weight='high']) .tabs.is-subtabs {
    --one-ux-tabs--height: 28px;
    --one-ux-tabs--border-width: 3px;
    --one-ux-tabs--font: var(--one-ux-font--body-variant-300);
    --one-ux-tabs--letter-spacing: var(--one-ux-letter-spacing--body-variant-300);
  }

  :host([weight='high']) .tabs {
    --one-ux-tabs--height: 32px;
    --one-ux-tabs--border-width: 4px;
    --one-ux-tabs--font: var(--one-ux-font--heading-300);
    --one-ux-tabs--letter-spacing: var(--one-ux-letter-spacing--heading-300);
  }

  .tabs--navigation {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    user-select: none;
  }

  .tabs--navigation-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--one-ux-tabs--height);
    height: var(--one-ux-tabs--height);
    border-radius: var(--one-ux-radius--300);
    cursor: pointer;
    position: relative;
  }

  .tabs--navigation-button:hover {
    background-color: var(--one-ux-palette--brand-neutral-200);
  }

  .tabs--list {
    display: flex;
    flex-flow: row nowrap;
    overflow: auto;
    flex: 1;
    gap: 0 var(--one-ux-spacing--300);
    scrollbar-width: none;
  }

  .tabs--list::-webkit-scrollbar {
    display: none;
  }

  .state-overflow-none .tabs--navigation-button {
    display: none !important;
  }

  .state-overflow-left .tabs--navigation-button.left {
    visibility: hidden;
  }

  .state-overflow-left .tabs--list,
  .state-overflow-both.state-scroll-left .tabs--list {
    mask-image: var(--one-ux-tabs--mask-left);
    -webkit-mask-image: var(--one-ux-tabs--mask-left);
  }

  .state-overflow-both .tabs--list {
    mask-image: var(--one-ux-tabs--mask-both);
    -webkit-mask-image: var(--one-ux-tabs--mask-both);
  }

  .state-overflow-right .tabs--navigation-button.right {
    visibility: hidden;
  }

  .state-overflow-right .tabs--list,
  .state-overflow-both.state-scroll-right .tabs--list {
    mask-image: var(--one-ux-tabs--mask-right);
    -webkit-mask-image: var(--one-ux-tabs--mask-right);
  }

  :host([placement='center']) .state-overflow-none .tabs--list {
    justify-content: center;
  }

  :host([placement='end']) .state-overflow-none .tabs--list .tabs--button:first-child {
    margin-left: auto;
  }

  :host([placement='flex']) .tabs--button {
    flex: 1;
    overflow: hidden;
  }

  .tabs--button {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    font: var(--one-ux-tabs--font);
    letter-spacing: var(--one-ux-tabs--letter-spacing);
    color: var(--one-ux-tabs--text-color);
    text-align: center;
    white-space: nowrap;

    padding-left: var(--one-ux-spacing--300);
    padding-right: var(--one-ux-spacing--300);
    height: var(--one-ux-tabs--height);

    cursor: pointer;
    position: relative;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  .tabs--button-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 0 calc(var(--one-ux-tabs--border-width) / 2) 0;
  }

  [one-ux-element='one-ux-pill'].is-empty {
    position: absolute;
    top: 0;
    right: 0;
  }

  .tabs--button::after {
    content: '';
    display: block;
    height: var(--one-ux-tabs--border-width);
    background: var(--one-ux-tabs--border-color);
    border-top-left-radius: var(--one-ux-radius--200);
    border-top-right-radius: var(--one-ux-radius--200);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  .tabs--button [one-ux-element='one-ux-icon'] {
    vertical-align: calc((var(--one-ux-tabs--border-width) + 1px) * -1);
    margin-right: var(--one-ux-spacing--100);
  }

  .tabs--button.is-only-icon [one-ux-element='one-ux-icon'] {
    margin-right: 0;
  }

  .tabs--button[aria-disabled] {
    --one-ux-tabs--text-color: var(--one-ux-palette--gray-400) !important;
    cursor: default;
  }

  .tabs--button:not([aria-disabled], [aria-selected='true']):hover,
  .tabs--button:not([aria-disabled], [aria-selected='true'])#active-tab-item {
    --one-ux-tabs--text-color: var(--one-ux-palette--brand-neutral-500);
    --one-ux-tabs--border-color: var(--one-ux-palette--brand-neutral-300);
  }

  .tabs--button:not([aria-disabled], [aria-selected='true']):active {
    --one-ux-tabs--text-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-tabs--border-color: var(--one-ux-palette--brand-neutral-200);
  }

  .tabs--button[aria-selected='true'] {
    --one-ux-tabs--text-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-tabs--border-color: var(--one-ux-palette--brand-core-400);
  }

  :host([weight='low']) .tabs .tabs--button[aria-selected='true'] {
    --one-ux-tabs--border-color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([weight='normal']) .is-subtabs .tabs--button[aria-selected='true'] {
    --one-ux-tabs--border-color: var(--one-ux-palette--brand-neutral-600);
  }

  .tabs--content {
    flex: 1 1 auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .tabs--content div[role='tabpanel'] {
    flex: 1 1 auto;
    min-height: 0;
  }

  .tabs--content:not(.has-content) div[role='tabpanel'] {
    display: none;
  }

  .tabs--content.has-content div[role='tabpanel'] {
    display: block;
    outline: none;
  }

  .tabs--content .is-subtabs {
    padding-top: var(--one-ux-spacing--300);
  }

  /* KEYBOARD-FOCUS */
  :host([state-keyboard-focus]) [aria-activedescendant]:focus .tabs {
    background-color: var(--one-ux-palette--keyboard-focus-300) !important;
  }

  :host([state-keyboard-focus]) [aria-activedescendant]:focus #active-tab-item {
    background-color: var(--one-ux-palette--keyboard-focus-400) !important;
  }

  :host([state-keyboard-focus]) [aria-activedescendant]:focus .tabs--button {
    --one-ux-tabs--border-color: transparent !important;
  }

  :host([state-keyboard-focus]) [aria-activedescendant]:focus .tabs--button[aria-selected='true'] {
    --one-ux-tabs--border-color: var(--one-ux-palette--keyboard-focus-700) !important;
  }

  :host([state-keyboard-focus]) [aria-activedescendant]:focus .tabs--button:not([aria-disabled]) {
    --one-ux-tabs--text-color: var(--one-ux-palette--keyboard-focus-700) !important;
  }

  :host([state-keyboard-focus]) .tabs--content div[role='tabpanel']:focus {
    outline: 2px solid var(--one-ux-palette--keyboard-focus-400) !important;
    outline-offset: -2px !important;
  }
`;