import { getCursorPosition } from './mouse-helper.js';
const getAncestorPath = ($element: Element) => {
  let result: Element[] = [];
  const composedPathEvent = 'internal-one-ux-get-composed-path';
  $element.addEventListener(composedPathEvent, (event: Event) => {
    result = event.composedPath() as Element[];
  }, {
    once: true
  });
  $element.dispatchEvent(new Event(composedPathEvent));
  return result.slice(0, -1);
};
export const startUpdatingAncestorScrollbarsByCursorPosition = ($element: Element) => {
  const ancestorPath = getAncestorPath($element);
  const edgeThreshold = 50;
  const scrollAcceleration = 0.2;
  let stopped = false;
  requestAnimationFrame(function updateScrollbars() {
    const {
      cursorX,
      cursorY
    } = getCursorPosition();
    const scrollbarUpdates = [] as (() => void)[];
    const scrollbarElements = ancestorPath.filter(x => (x.clientHeight < x.scrollHeight || x.clientWidth < x.scrollWidth) && (x === document.scrollingElement || /auto|scrollbar/.test(window.getComputedStyle(x).overflow)));
    scrollbarElements.forEach((scrollbar: Element) => {
      const scrollbarRect = scrollbar === document.scrollingElement ? {
        top: 0,
        bottom: window.innerHeight,
        left: 0,
        right: window.innerWidth
      } : scrollbar.getBoundingClientRect();
      const amountBeyondHorizontal = Math.min(cursorX - (scrollbarRect.left + edgeThreshold), 0) + Math.max(cursorX - (scrollbarRect.right - edgeThreshold), 0);
      const amountBeyondVertical = Math.min(cursorY - (scrollbarRect.top + edgeThreshold), 0) + Math.max(cursorY - (scrollbarRect.bottom - edgeThreshold), 0);
      if (amountBeyondHorizontal !== 0) {
        scrollbarUpdates.push(() => scrollbar.scrollLeft += amountBeyondHorizontal * scrollAcceleration);
      }
      if (amountBeyondVertical !== 0) {
        scrollbarUpdates.push(() => scrollbar.scrollTop += amountBeyondVertical * scrollAcceleration);
      }
    });
    scrollbarUpdates.forEach(update => update());
    if (!stopped) {
      requestAnimationFrame(updateScrollbars);
    }
  });
  return {
    stopUpdatingAncestorScrollbars: () => {
      stopped = true;
    }
  };
};