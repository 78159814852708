export class BeforeResizeEvent extends Event {
  static get eventName() {
    return 'beforeresize';
  }
  public newColumnSpan: number;
  public newRowSpan: number;
  constructor(newColumnSpan: number, newRowSpan: number) {
    super(BeforeResizeEvent.eventName, {
      cancelable: true
    });
    this.newColumnSpan = newColumnSpan;
    this.newRowSpan = newRowSpan;
  }
}