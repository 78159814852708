import { InternalSubtab, InternalTab, InternalTabBase } from '../types.js';
export const getTabSiblings = (tabs: InternalTab[], tab: InternalTabBase) => tab.type === 'sub' ? (tab as InternalSubtab).parent.subtabs : tabs;
export const getActiveTab = (tabs: InternalTab[], activeTab: string) => {
  const tab = getTabById(tabs, activeTab);
  if (tab) {
    return tab;
  }
  const availableTabs = tabs.filter(x => !x.disabled);
  for (const tab of availableTabs) {
    for (const subtab of (tab.subtabs || []).filter(x => !x.disabled)) {
      if (subtab.isActive) {
        return subtab;
      }
    }
    if (tab.isActive) {
      return tab;
    }
  }
  return availableTabs[0]!;
};
export const getImplicitActiveTab = (tabs: InternalTab[], activeTab: string) => {
  const tab = getActiveTab(tabs, activeTab);
  if (tab.type === 'parent') {
    const parentTab = tab as InternalTab;
    const subtabs = parentTab.subtabs.filter(x => !x.disabled);
    if (subtabs.length) {
      return subtabs.find(x => x.isActive) || subtabs[0];
    }
  }
  return tab;
};
export const getPreviousTab = (tabs: InternalTabBase[], focusedTab: InternalTabBase) => {
  const selectableTabs = tabs.filter(x => !x.disabled);
  const index = selectableTabs.indexOf(focusedTab) - 1;
  return selectableTabs[index > -1 ? index : selectableTabs.length - 1];
};
export const getNextTab = (tabs: InternalTabBase[], focusedTab: InternalTabBase) => {
  const selectableTabs = tabs.filter(x => !x.disabled);
  const index = selectableTabs.indexOf(focusedTab) + 1;
  return selectableTabs[index % selectableTabs.length];
};
export const getFirstTab = (tabs: InternalTabBase[]) => tabs.filter(x => !x.disabled)[0];
export const getLastTab = (tabs: InternalTabBase[]) => {
  const availableTabs = tabs.filter(x => !x.disabled);
  return availableTabs[availableTabs.length - 1];
};
const getTabById = (tabs: InternalTab[], id: string) => {
  for (const tab of tabs) {
    if (tab.id === id) {
      return tab;
    }
    for (const subtab of tab.subtabs) {
      if (subtab.id === id) {
        return subtab;
      }
    }
  }
};