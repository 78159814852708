import { css } from 'lit';
export const style = css`
  input {
    appearance: none;
    all: unset;
    padding: 0 calc(var(--one-ux-spacing--300) - var(--one-ux-spacing--100));
    width: 100%;
  }

  input:not([disabled]):hover {
    cursor: text;
  }

  input:not(:placeholder-shown, :disabled) {
    color: var(--one-ux-palette--brand-neutral-600);
  }

  input:disabled {
    color: var(--one-ux-palette--gray-400); /* Needed for Safari */
  }

  input::placeholder {
    color: var(--one-ux-palette--brand-neutral-400);
  }

  input[type='password'] {
    letter-spacing: 2px;
    font-family: Tahoma, Verdana, sans-serif;
  }

  :host([format='number']) input {
    text-align: right;
    font: var(--one-ux-font--mono-600);
    letter-spacing: var(--one-ux-letter-spacing--mono-600);
  }

  :host([weight='low'][format='number']) input {
    font: var(--one-ux-font--mono-400);
    letter-spacing: var(--one-ux-letter-spacing--mono-400);
  }
`;