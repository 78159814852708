import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
import { log } from '../../pdr/log.js';
const _purposes = {
  default: 'No special meaning, just plain default component',
  main: 'Is intended to be part of the main flow of the user interactions and attention',
  caution: 'User should be thoughtful about their actions and information presented',
  critical: 'Actions and information is of outmost importance in order to continue',
  positive: 'Indicate that actions and information is of positive outcome for the user',
  notice: 'Should at least be noticed by the user, but it is not the main focus',
  muted: 'Toned down visual appearance without loss of weight or context',
  placeholder: 'Indicate that this area can be replaced by user action',
  promote: 'Material that should be promoted to the user',
  ai: 'Is related to any type of AI capability',
  branded: 'Is related to the Stratsys company and brand'
} as const;
export type purpose = keyof typeof _purposes;
export declare class IPurpose<TPurpose extends purpose> {
  purpose: TPurpose;
}
export const PurposeFactory = <TPurpose extends purpose, TDeprecatedDefault extends TPurpose>({
  purposes,
  deprecatedDefault
}: {
  purposes: TPurpose[];
  deprecatedDefault?: TDeprecatedDefault;
}) => {
  let globallyAvailableDefaultPurposeDeprecationWarnings = 5;
  return <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
    class PurposeClass extends SuperClass {
      #purpose: TPurpose = deprecatedDefault || purposes[0];
      #usesDefaultPurpose = true;
      constructor(...args: any[]) {
        super(...args);
        if (deprecatedDefault) {
          this.updateComplete.then(() => {
            if (this.#usesDefaultPurpose) {
              this.#logDefaultPurposeDeprecation();
            }
          });
        }
      }

      /**
       * Describes the intention of the component.
       */
      @property({
        type: String,
        reflect: true
      })
      public set purpose(purpose: TPurpose) {
        this.#usesDefaultPurpose = false;
        this.#purpose = purpose;
      }
      public get purpose() {
        return this.#purpose;
      }
      #logDefaultPurposeDeprecation() {
        if (globallyAvailableDefaultPurposeDeprecationWarnings) {
          globallyAvailableDefaultPurposeDeprecationWarnings--;
          const reason = `Default value of 'purpose' is deprecated and will change in upcoming major from '${deprecatedDefault}' to '${purposes[0]}'. Use attribute 'purpose="${deprecatedDefault}"' instead.`;
          const message = globallyAvailableDefaultPurposeDeprecationWarnings ? reason : `${reason} Suppressing further warnings.`;
          log.deprecation(message);
        }
      }
    }
    return PurposeClass as Constructor<IPurpose<TPurpose>> & TSuperClass;
  };
};