export type internalValidityChangedDetails = {
  valid: boolean;
  errors: string[];
  flags: ValidityStateFlags;
};
export class InternalValidityChangedEvent extends CustomEvent<internalValidityChangedDetails> {
  static get eventName() {
    return 'internal-one-ux-validity-changed';
  }
  constructor(detail: internalValidityChangedDetails) {
    super(InternalValidityChangedEvent.eventName, {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail
    });
  }
}