import { Ref } from 'lit/directives/ref.js';
import { keyCodes } from '../../utils.js';
import { NodeData, RootCallbacks } from './types.js';
export type KeyboardOptions = {
  $tree: Ref;
  activeNode: NodeData | null;
  tree: NodeData[];
  callbacks: RootCallbacks;
};
export class TreeKeyboardHandler {
  constructor(private options: KeyboardOptions) {}
  public handleKeydown = (event: KeyboardEvent) => {
    const handled = () => {
      event.stopPropagation();
      event.preventDefault();
    };
    switch (event.code) {
      case keyCodes.SPACE:
      case keyCodes.RETURN:
      case keyCodes.NUMPADRETURN:
        this.#selectItem();
        handled();
        break;
      case keyCodes.UP:
        this.#gotoPreviousItem();
        handled();
        break;
      case keyCodes.DOWN:
        this.#gotoNextItem();
        handled();
        break;
      case keyCodes.RIGHT:
        this.#expandOrEnter();
        handled();
        break;
      case keyCodes.LEFT:
        this.#foldOrExit();
        handled();
        break;
      case keyCodes.HOME:
        this.#gotoStart();
        handled();
        break;
      case keyCodes.END:
        this.#gotoEnd();
        handled();
        break;
      default:
        if (this.#isPrintable(event.key)) {
          this.#gotoFirstTextMatch(event.key);
          handled();
        }
        break;
    }
  };
  get _activeNode(): NodeData {
    return this.options.activeNode!;
  }
  get _tree() {
    return this.options.tree;
  }
  #isPrintable(str: string) {
    return str.length === 1 && str.match(/\S/);
  }
  #gotoPreviousItem(): void {
    const findPreviousNode = (nodes: NodeData[], path: number[], seen = [] as NodeData[]): NodeData | null => {
      function grandChild(node: NodeData): NodeData {
        if (node.expanded && node.children.length) {
          return grandChild(node.children[node.children.length - 1]);
        } else {
          return node;
        }
      }
      const i = path.shift()!;
      if (path.length) {
        return findPreviousNode(nodes[i].children, path, [...seen, nodes[i]]);
      } else if (nodes[i - 1] == undefined) {
        const parentNode = seen.pop();
        if (parentNode) {
          return parentNode;
        }
      } else if (nodes[i - 1] !== undefined) {
        const sibling = nodes[i - 1];
        return grandChild(sibling);
      }
      return null;
    };
    if (this._activeNode) {
      const prevNode = findPreviousNode(this._tree, [...this._activeNode!.path]);
      if (prevNode) {
        this.options.callbacks.onChangeActiveNode(prevNode);
      }
    }
  }
  #gotoNextItem(): void {
    const findNextNode = (nodes: NodeData[], path: number[], seen = [] as NodeData[]): NodeData | null => {
      const i = path.shift()!;
      if (path.length) {
        return findNextNode(nodes[i].children, path, [...seen, nodes[i + 1]]);
      } else if (nodes[i].expanded && nodes[i].children.length) {
        return nodes[i].children[0];
      } else if (nodes[i + 1] !== undefined) {
        return nodes[i + 1];
      } else {
        while (seen.length) {
          const parentNodeSibling = seen.pop();
          if (parentNodeSibling) {
            return parentNodeSibling;
          }
        }
      }
      return null;
    };
    if (this._activeNode) {
      const nextNode = findNextNode(this._tree, [...this._activeNode.path]);
      if (nextNode) {
        this.options.callbacks.onChangeActiveNode(nextNode);
      }
    }
  }
  #gotoStart(): void {
    this.options.callbacks.onChangeActiveNode(this._tree[0]);
  }
  #gotoEnd(): void {
    this.options.callbacks.onChangeActiveNode(this._tree[this._tree.length - 1]);
  }
  #selectItem(): void {
    this.options.callbacks.onNodeToggleSelect(this._activeNode);
  }
  #foldOrExit(): void {
    if (this._activeNode.expanded) {
      this.options.callbacks.onNodeToggleExpand(this._activeNode);
    } else {
      const closestParent = (nodes: NodeData[], path: number[]): NodeData | null => {
        const i = path.shift();
        if (i !== undefined) {
          if (path.length) {
            return closestParent(nodes[i].children, path);
          } else {
            return nodes[i];
          }
        }
        return null;
      };
      const parent = closestParent(this._tree, this._activeNode.path.slice(0, -1));
      if (parent) {
        this.options.callbacks.onChangeActiveNode(parent);
      }
    }
  }
  #expandOrEnter(): void {
    if (!this._activeNode.expanded) {
      this.options.callbacks.onNodeToggleExpand(this._activeNode);
    } else {
      if (this._activeNode.children.length) {
        this.options.callbacks.onChangeActiveNode(this._activeNode.children[0]);
      }
    }
  }
  #gotoFirstTextMatch(str: string) {
    const firstLetterTest = new RegExp('^' + str, 'i');
    const matches = (item: NodeData): boolean => firstLetterTest.test(item.text);
    const findNode = (nodes: NodeData[]): NodeData | null => {
      const match = nodes.find(matches);
      if (match) {
        return match;
      } else {
        for (let i = 0, stopAt = nodes.length; i < stopAt; i++) {
          const match = findNode(nodes[i].children);
          if (match) {
            return match;
          }
        }
      }
      return null;
    };
    const match = findNode(this._tree);
    if (match) {
      this.options.callbacks.onChangeActiveNode(match);
    }
  }
}