import type { OneUxGadgetElement } from './OneUxGadgetElement.js';
import { keyCodes } from '../../utils.js';
export function handleKeyboard(this: OneUxGadgetElement, event: KeyboardEvent) {
  if (!this._hasDashboardParent) {
    return;
  }
  const handled = () => {
    event.preventDefault();
    event.stopPropagation();
  };
  const currentIndex = Array.from(this.parentElement!.children).indexOf(this);
  switch (event.code) {
    case keyCodes.LEFT:
      {
        if (event.ctrlKey && this.resizable) {
          this._resize(this.columnSpan - 1, this.rowSpan);
          handled();
        }
        if (event.shiftKey && this.movable) {
          this._move(currentIndex - 1);
          handled();
        }
        break;
      }
    case keyCodes.RIGHT:
      {
        if (event.ctrlKey && this.resizable) {
          this._resize(this.columnSpan + 1, this.rowSpan);
          handled();
        }
        if (event.shiftKey && this.movable) {
          this._move(currentIndex + 1);
          handled();
        }
        break;
      }
    case keyCodes.UP:
      {
        if (event.ctrlKey && this.resizable) {
          this._resize(this.columnSpan, this.rowSpan - 1);
          return handled();
        }
        if (event.shiftKey && this.movable) {
          this._move(currentIndex - 1);
          handled();
        }
        break;
      }
    case keyCodes.DOWN:
      {
        if (event.ctrlKey && this.resizable) {
          this._resize(this.columnSpan, this.rowSpan + 1);
          return handled();
        }
        if (event.shiftKey && this.movable) {
          this._move(currentIndex + 1);
          handled();
        }
        break;
      }
  }
}