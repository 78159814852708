import { css, unsafeCSS } from 'lit';
import { cardBaseIndentation } from './constants.js';
export const style = css`
  .context-one-ux-card {
    --one-ux-stack--card-gap: var(--one-ux-spacing--200);
    --one-ux-stack--card-transition-time: var(--one-ux-duration--300);
    --one-ux-stack--card-margin-transition-timing: cubic-bezier(0, 0, 0.3, 1);
    --one-ux-stack--card-scale-transition-time: var(--one-ux-duration--300);

    --one-ux-stack--pseudo-card-base-indentation: ${unsafeCSS(cardBaseIndentation)};
    --one-ux-stack--pseudo-card-background: var(--one-ux-palette--gray-100);
    --one-ux-stack--pseudo-card-border-color: var(--one-ux-palette--brand-neutral-300);
    --one-ux-stack--pseudo-card-border-width: var(--one-ux-border-width--400);
    --one-ux-stack--pseudo-card-height: 0px;

    display: flex;
    flex-flow: column;

    gap: var(--one-ux-stack--card-gap);
    transition: gap var(--one-ux-stack--card-transition-time) var(--one-ux-easing--default);

    ::slotted([one-ux-element='one-ux-card']) {
      position: relative;
      margin: 0 auto;
      width: 100%;
      z-index: calc(var(--one-ux-stack--card-count) - 1 - var(--one-ux-stack--card-index));
    }

    ::slotted([one-ux-element='one-ux-card']:not(:first-child)) {
      margin-top: calc(-1 * (var(--one-ux-stack--card-height)));
    }

    :host([open]) & {
      --one-ux-stack--card-scale-transition-time: 0ms;
      --one-ux-stack--card-transition-time: var(--one-ux-duration--500);
      --one-ux-stack--card-margin-transition-timing: cubic-bezier(0, 0, 0.3, 1.2);

      ::slotted([one-ux-element='one-ux-card']:not(:first-child)) {
        margin-top: 0;
      }
    }

    :host(:not([open])) & {
      --one-ux-stack--card-gap: 0px;

      ::slotted([one-ux-element='one-ux-card']:not(:first-child)) {
        clip-path: inset(var(--one-ux-stack--card-clip-distance, 0) 0 0 0);
        transform: scaleX(
          calc(1 - var(--one-ux-stack--card-scaled-base-indentation) * min(2, var(--one-ux-stack--card-index)))
        );
        pointer-events: none;
      }
    }

    :host(.one-ux-stack-transition) & {
      ::slotted([one-ux-element='one-ux-card']:not(:first-child)) {
        transition:
          margin-top var(--one-ux-stack--card-transition-time) var(--one-ux-stack--card-margin-transition-timing),
          transform var(--one-ux-stack--card-scale-transition-time) var(--one-ux-easing--default);
        clip-path: inset(var(--one-ux-stack--card-clip-distance, 0) 0 0 0);
      }
    }

    ::slotted([one-ux-element='one-ux-card']:first-child:hover) {
      --one-ux-stack--pseudo-card-border-color: var(--one-ux-palette--focus-400) !important;
      --one-ux-stack--pseudo-card-border-width: var(--one-ux-border-width--500);
      --one-ux-stack--pseudo-card-height: 4px;
    }

    ::slotted([one-ux-element='one-ux-card']:first-child)::before,
    ::slotted([one-ux-element='one-ux-card']:first-child)::after {
      content: '';
      display: none;
      position: absolute;
      z-index: -1;
      top: calc(100% - var(--one-ux-stack--pseudo-card-border-width) + 0.5px);
      opacity: 0;
      height: var(--one-ux-stack--pseudo-card-height);

      border-top: none;
      border-style: solid;
      border-width: var(--one-ux-border-width--400);

      border-bottom-left-radius: var(--one-ux-radius--400);
      border-bottom-right-radius: var(--one-ux-radius--400);
      border-width: var(--one-ux-stack--pseudo-card-border-width);
      pointer-events: none;
    }

    :host(.show-pseudo-card-first) & ::slotted([one-ux-element='one-ux-card']:first-child)::after {
      display: block;
    }

    :host(.show-pseudo-card-second) & ::slotted([one-ux-element='one-ux-card']:first-child)::before {
      display: block;
    }

    :host(:not([open], .one-ux-stack-transition)) & {
      --one-ux-stack--pseudo-card-height: 3px;
      --one-ux-stack--card-clip-distance: 100% !important;

      ::slotted([one-ux-element='one-ux-card']:first-child)::before,
      ::slotted([one-ux-element='one-ux-card']:first-child)::after {
        opacity: 1;
        transition:
          height var(--one-ux-duration--300) var(--one-ux-easing--default),
          opacity var(--one-ux-duration--300) var(--one-ux-easing--default);
      }
    }

    ::slotted([one-ux-element='one-ux-card']:first-child)::after {
      height: var(--one-ux-stack--pseudo-card-height);
      left: var(--one-ux-stack--pseudo-card-base-indentation);
      right: var(--one-ux-stack--pseudo-card-base-indentation);
      background: color-mix(in srgb, var(--one-ux-stack--pseudo-card-background) 50%, transparent);
      border-color: color-mix(in srgb, var(--one-ux-stack--pseudo-card-border-color) 50%, transparent);
    }

    ::slotted([one-ux-element='one-ux-card']:first-child)::before {
      height: calc(2 * var(--one-ux-stack--pseudo-card-height));
      left: calc(2 * var(--one-ux-stack--pseudo-card-base-indentation));
      right: calc(2 * var(--one-ux-stack--pseudo-card-base-indentation));
      background: color-mix(in srgb, var(--one-ux-stack--pseudo-card-background) 25%, transparent);
      border-color: color-mix(in srgb, var(--one-ux-stack--pseudo-card-border-color) 25%, transparent);
    }
  }
`;