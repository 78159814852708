import { createContext } from '@lit/context';
export type avatarState = 'none' | 'removed' | 'anonymized' | 'impersonated' | 'unknown';
export interface IAvatarContext {
  getAvatarUrl(userId: string, preferredSize: number): Promise<string>;
  getFullName(userId: string): Promise<string>;
  getState(userId: string): Promise<avatarState>;
}
const contextKey = Symbol('one-ux-avatar-context');
export const defaultAvatarContext = {
  getAvatarUrl(_userId: string, _preferredSize: number): Promise<string> {
    return Promise.reject();
  },
  getFullName(_userId: string): Promise<string> {
    return Promise.reject();
  },
  getState(_userId: string): Promise<avatarState> {
    return Promise.reject();
  }
} satisfies IAvatarContext;
export const avatarContext = createContext<IAvatarContext>(contextKey);