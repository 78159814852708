import { provide } from '@lit/context';
import { html, nothing, PropertyValues } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { tabsContext, ITabsContext } from '../../contexts/TabsContext.js';
import { Implicit } from '../../mixins/Implicit.js';
import { FocusableFactory } from '../../mixins/Focusable.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { Weight } from '../../mixins/Weight.js';
import { OneUxElement } from '../../OneUxElement.js';
import { OneUxTabElement } from '../one-ux-tab/OneUxTabElement.js';
import { maskStart, maskStretch, style } from './style.js';
import { Label } from '../../mixins/Label.js';
import { SlotController } from '../../controllers/SlotController.js';
import { setOverflow } from './setOverflow.js';
import { getLanguage } from './language.js';
import { Indicator } from '../one-ux-tab/Indicator.js';
import { UpdateOnResizedController } from '../../controllers/UpdateOnResizedController.js';
import { UpdateOnMutationController } from '../../controllers/UpdateOnMutationController.js';
import { keyCodes, scrollElementIntoView } from '../../utils.js';
import { animationOptions, deselectTabpanelAnimation, selectTabpanelAnimation } from './animations.js';
import { validEdges, type direction, type internalTab, type validEdge } from './types.js';
import { BeforeTabEvent, TabEvent, AddEvent } from './events.js';
import { log } from '../../utils/log.js';
import { TABBABLE_TARGETS_SELECTOR } from '../../utils/focusable.js';
import { Deprecation } from '../../utils/Deprecation.js';
import { register as _registerElement } from "../one-ux-button/register-element.js";
import { register as _registerElement2 } from "../one-ux-icon/register-element.js";
import { register as _registerElement3 } from "../one-ux-tab/register-element.js";
_registerElement3("tab-1099139cc53ffa1af4f22eae0d284a99");
_registerElement2("icon-ae09675ec9456c9a685371d130572a88");
_registerElement("button-2c5be9b6b8fcd50985a0ab8b446876f9");
const Styled = StyledFactory(style);
const Focusable = FocusableFactory(false);
const BaseClass = Label(Weight(Implicit(Focusable(Styled(OneUxElement)))));
export class OneUxTabsPreviewElement extends BaseClass {
  static get elementType() {
    return 'one-ux-tabs-preview';
  }
  @property({
    type: String
  })
  public accessor selected = '';
  @property({
    type: Boolean,
    attribute: 'show-add'
  })
  public accessor showAdd = false;
  @property({
    type: String,
    attribute: 'show-add-label'
  })
  public accessor showAddLabel = '';
  @property({
    type: Array,
    attribute: 'collapse-edge'
  })
  public accessor collapseEdge: validEdge[] = [];

  /** @internal */
  @state()
  accessor _tabs: internalTab[] = [];

  /** @internal */
  @state()
  accessor _currentScrollDirection: direction | 'none' = 'none';

  /** @internal */
  @query('.tablist-nav')
  accessor _$tablistNav: HTMLDivElement | undefined = undefined;

  /** @internal */
  @query('[role="tablist"]')
  accessor _$tablist!: HTMLDivElement;

  /** @internal */
  @provide({
    context: tabsContext
  })
  _tabsContext: ITabsContext = {
    implicit: this.implicit,
    weight: this.weight,
    isTablistFocused: false,
    updateTab: this.#updateTab.bind(this),
    changeTab: this.#handleTabChange.bind(this),
    onTabBlur: this.#handleTabBlur.bind(this),
    isActive: this.#isActive.bind(this),
    isSelected: this.#isSelected.bind(this)
  };
  #updateOnMutationController: UpdateOnMutationController | undefined;
  constructor() {
    super();
    new UpdateOnResizedController(this);
    this.#updateOnMutationController = new UpdateOnMutationController(this);
  }
  #deprecation = new Deprecation(this, '<one-ux-tabs-preview> is deprecated in favour of <one-ux-tabs-next>', 'The <one-ux-tabs-preview> element is deprecated in favor of <one-ux-tabs-next> element.');
  protected willUpdate(changed: PropertyValues<this>): void {
    this.#deprecation.notify();
    if (changed.has('_tabs') && this._tabs.length) {
      const selectedTab = this._tabs.find(tab => !tab.$el.disabled && tab.name === this.selected) ?? this._tabs[0];
      this.#setSelected(selectedTab);
      this.#activeTabName = selectedTab.name;

      // TODO: This event dispatch should be removed once this element is no longer a preview
      this.dispatchEvent(new CustomEvent('__internaltab__', {
        detail: selectedTab.name
      }));
    }
    const hasContextChanged = changed.has('selected') || changed.has('implicit') && this._tabsContext.implicit !== this.implicit || changed.has('weight') && this._tabsContext.weight !== this.weight || changed.has('hasKeyboardFocus') && changed.get('hasKeyboardFocus') !== this.hasKeyboardFocus;
    if (hasContextChanged) {
      this.#updateContext({
        implicit: this.implicit,
        weight: this.weight,
        isTablistFocused: this.hasKeyboardFocus && this._tabs.some(tab => tab.$el === document.activeElement)
      });
    }
  }
  protected firstUpdated(): void {
    if (this.#hasFixedContent) {
      const $fixedContent = this.querySelector('[slot="fixed-content"]') as HTMLElement;
      this.#updateOnMutationController?.observe($fixedContent);
    }
  }
  protected updated(): void {
    if (this._$tablistNav) {
      setOverflow(this._$tablistNav);
    }
  }
  #id = crypto.randomUUID();
  protected guardedRender() {
    const {
      languageKey,
      languageSet
    } = getLanguage(this);
    const hasNestedTabs = (name: string) => Array.from(this.querySelectorAll(`:scope > [slot='${name}']`)).every($el => $el instanceof OneUxTabsPreviewElement);
    return html`<div class="one-ux-element--root" lang=${languageKey}>
      <div
        class=${classMap({
      header: true,
      'has-header-end-content': this.#slots.hasNamedSlot('header-end')
    })}
        role="group"
        aria-label=${languageSet.header}
      >
        <div class="tablist-nav">
          <button-2c5be9b6b8fcd50985a0ab8b446876f9
            aria-hidden="true"
            tabindex="-1"
            label=${languageSet.scrollLeft}
            class="nav-left"
            weight="low"
            implicit
            @click=${() => this.#scroll('left')}
          >
            <icon-ae09675ec9456c9a685371d130572a88 icon="toggle-left"></icon-ae09675ec9456c9a685371d130572a88>
          </button-2c5be9b6b8fcd50985a0ab8b446876f9>
          <div
            role="tablist"
            aria-label=${this.label}
            @scroll=${this.#handleTablistScroll}
            scroll-direction=${this._currentScrollDirection}
            @keydown=${this.#handleKeydown}
          >
            ${this.#selectedTab && Indicator({
      selected: true,
      width: this.#selectedTab.$el.getBoundingClientRect().width,
      implicit: this.implicit,
      purpose: this.#selectedTab.$el.purpose,
      isKeyboardFocused: this._tabsContext.isTablistFocused,
      weight: this.weight,
      posLeft: this.#selectedTab.$el.getBoundingClientRect().left - this._$tablist.getBoundingClientRect().left + this._$tablist.scrollLeft
    })}
            <slot @slotchange=${this.#handleDefaultSlotChange}></slot>
            ${this.showAdd ? html`<tab-1099139cc53ffa1af4f22eae0d284a99 id="add-tab" label=${this.showAddLabel || languageSet.add} .__isAddTab__=${true}>
                  <icon-ae09675ec9456c9a685371d130572a88 icon="add" slot="start"></icon-ae09675ec9456c9a685371d130572a88>
                </tab-1099139cc53ffa1af4f22eae0d284a99>` : nothing}
          </div>
          <button-2c5be9b6b8fcd50985a0ab8b446876f9
            aria-hidden="true"
            tabindex="-1"
            label=${languageSet.scrollRight}
            class="nav-right"
            weight="low"
            implicit
            @click=${() => this.#scroll('right')}
          >
            <icon-ae09675ec9456c9a685371d130572a88 icon="toggle-right"></icon-ae09675ec9456c9a685371d130572a88>
          </button-2c5be9b6b8fcd50985a0ab8b446876f9>
        </div>
        <slot name="header-end"></slot>
      </div>

      <div class="tabpanel-container" state-collapse-edge=${ifDefined(this.#getCollapseEdge())}>
        <div
          role="tabpanel"
          id=${`fixed-content-${this.#id}`}
          aria-labelledby=${this.#selectedTab?.tabRef}
          class=${classMap({
      'is-selected': this.#hasFixedContent,
      'has-nested-tabs': hasNestedTabs('fixed-content')
    })}
          style=${styleMap({
      display: this.#hasFixedContent ? 'block' : 'none'
    })}
        >
          <slot name="fixed-content"></slot>
        </div>

        ${this.#hasFixedContent ? nothing : this._tabs.map(tab => {
      return html`<div
                role="tabpanel"
                id=${tab.tabpanelRef}
                aria-labelledby=${tab.tabRef}
                tabindex=${this.#hasTabbableContent(tab) ? '-1' : '0'}
                class=${classMap({
        'is-selected': this.#isSelected(tab.$el),
        'has-nested-tabs': hasNestedTabs(tab.name)
      })}
              >
                <slot name=${tab.name}></slot>
              </div>`;
    })}
      </div>
    </div>`;
  }
  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['header-end', 'fixed-content']
  });
  #hasTabbableContent(tab: internalTab): boolean {
    const $slot = this.shadowRoot!.querySelector<HTMLSlotElement>(`#${tab.tabpanelRef} slot`);
    const hasTabbableContentPredicate = ($el: Element): boolean => $el.matches(TABBABLE_TARGETS_SELECTOR) || !!$el.querySelector(TABBABLE_TARGETS_SELECTOR);
    return $slot?.assignedElements().some(hasTabbableContentPredicate) ?? false;
  }
  #getCollapseEdge() {
    if (!this.collapseEdge?.length) {
      return undefined;
    }
    for (const edge of this.collapseEdge) {
      if (!(validEdges as unknown as string[]).includes(edge)) {
        log.warning(`Attribute "collapse-edge" can only be ${validEdges.join()}, ignoring provided value "${edge}".`);
      }
    }
    return this.collapseEdge.join(' ');
  }
  #scroll(direction: direction) {
    this._currentScrollDirection = direction;
    const $tabs = this._tabs.map(tab => tab.$el);
    if (this.showAdd) {
      const $addTab = this.shadowRoot!.querySelector<OneUxTabElement>('#add-tab')!;
      $tabs.push($addTab);
    }
    const tablistRect = this._$tablist.getBoundingClientRect();
    const getScrollLeft = ($tabs: OneUxTabElement[]) => {
      const $partlyHiddenTab = $tabs.reverse().find($tab => this.#isHiddenToLeft($tab));
      const scrollAmount = $partlyHiddenTab!.getBoundingClientRect().right - tablistRect.left - $partlyHiddenTab!.getBoundingClientRect().width - maskStart;
      return scrollAmount;
    };
    const getScrollRight = ($tabs: OneUxTabElement[]) => {
      const $partlyHiddenTab = $tabs.find($tab => this.#isHiddenToRight($tab));
      const scrollAmount = $partlyHiddenTab!.getBoundingClientRect().left - tablistRect.left - tablistRect.width + $partlyHiddenTab!.getBoundingClientRect().width + maskStart;
      return scrollAmount;
    };
    const scrollAmount = direction === 'left' ? getScrollLeft($tabs) : getScrollRight($tabs);
    if (!scrollAmount) return;
    this._$tablist.scrollBy({
      left: scrollAmount
    });
    this.#scrollByButton = true;
  }
  #isHiddenToLeft($tab: OneUxTabElement) {
    const leftEdge = $tab.getBoundingClientRect().left - this._$tablist.getBoundingClientRect().left;
    return leftEdge < 0;
  }
  #isHiddenToRight($tab: OneUxTabElement) {
    const tablistRect = this._$tablist.getBoundingClientRect();
    const rightEdge = $tab.getBoundingClientRect().right - tablistRect.left;
    return rightEdge > tablistRect.width;
  }
  #isPartlyHidden($tab: OneUxTabElement) {
    return this.#isHiddenToLeft($tab) || this.#isHiddenToRight($tab);
  }
  #scrollByButton = false;
  #scrollTimeoutId: ReturnType<typeof setTimeout> | undefined;
  #scrollOutOfViewTimeoutId: ReturnType<typeof setTimeout> | undefined;
  #handleTablistScroll() {
    if (this.#scrollByButton) {
      clearTimeout(this.#scrollTimeoutId);
    }
    this.#scrollTimeoutId = setTimeout(() => {
      if (!this.#scrollByButton) {
        this._currentScrollDirection = 'none';
      }
      this.#scrollByButton = false;
    }, 100);
    if (this._$tablistNav) {
      setOverflow(this._$tablistNav);
    }
    if (this.#scrollOutOfViewTimeoutId) {
      clearTimeout(this.#scrollOutOfViewTimeoutId);
    }
    const scrollIdleTimeout = 5000;
    this.#scrollOutOfViewTimeoutId = setTimeout(() => {
      if (!this.#isPartlyHidden(this.#selectedTab.$el)) {
        return;
      }
      this._currentScrollDirection = 'none';
      this.#scrollIntoView(this.#selectedTab.$el);
    }, scrollIdleTimeout);
  }
  #makeTab($el: OneUxTabElement, index: number): internalTab {
    const name = $el.name || `tab-${index + 1}`;
    const tabRef = `tab_${this.#id}_${name}`;
    const tabpanelRef = this.#hasFixedContent ? `fixed-content-${this.#id}` : `tabpanel_${this.#id}_${name}`;
    $el.name = name;
    return {
      index,
      $el,
      name,
      tabRef,
      tabpanelRef
    };
  }

  /** @internal */
  _updateDefaultSlot() {
    this.#handleDefaultSlotChange();
  }
  #handleDefaultSlotChange() {
    const $defaultSlot = this.shadowRoot!.querySelector<HTMLSlotElement>('slot:not([name])');
    if (!$defaultSlot) return;
    const $tabs = $defaultSlot.assignedElements({
      flatten: true
    }).filter<OneUxTabElement>(el => el instanceof OneUxTabElement);
    this._tabs = $tabs.map<internalTab>(($tab, index) => this.#makeTab($tab, index));
    this._tabs.forEach(tab => {
      tab.$el.provideTabPanelRefs(tab.tabRef, tab.tabpanelRef);
    });
  }
  #isSelected($tab: OneUxTabElement) {
    return this.selected === $tab.name;
  }
  #isActive($tab: OneUxTabElement) {
    return this.#activeTabName === $tab.name;
  }
  get #selectedTab() {
    return this._tabs.find(tab => tab.name === this.selected)!;
  }
  #updateTab($tab: OneUxTabElement) {
    const index = this._tabs.findIndex(tab => tab.$el === $tab);
    if (index !== -1) {
      this._tabs[index] = this.#makeTab($tab, index);
      this.requestUpdate();
    }
  }
  #previousTab: internalTab | undefined;
  #setSelected(tab: internalTab) {
    this.#previousTab = this.#selectedTab;
    this.selected = tab.name;
    this.#activeTabName = tab.name;
    this.#updateContext();
  }
  #activeTabName = '';
  #setActive(tab: internalTab) {
    this.#activeTabName = tab.name;
    tab.$el.focus();
    this.#updateContext();
  }
  async #handleTabChange($tab: OneUxTabElement) {
    const dispatchEvent = (event: BeforeTabEvent | TabEvent | AddEvent) => this.dispatchEvent(event);
    if ($tab.__isAddTab__) {
      dispatchEvent(new AddEvent());
      return;
    }
    const tab = this._tabs.find(tab => tab.$el === $tab);
    if (!tab || $tab === this.#selectedTab.$el) return;
    if (!dispatchEvent(new BeforeTabEvent(tab.name))) {
      return;
    }
    this.#setSelected(tab);
    this._currentScrollDirection = 'none';
    this.#scrollIntoView($tab);
    if (!this.#hasFixedContent) {
      await this.#animateTabChange();
    }
    dispatchEvent(new TabEvent(tab.name));
  }
  async #animateTabChange() {
    if (!this.#previousTab || this.#previousTab === this.#selectedTab) return Promise.resolve();
    const $previousTabpanel = this.shadowRoot!.querySelector(`#${this.#previousTab.tabpanelRef}`) as HTMLElement;
    const $nextTabpanel = this.shadowRoot!.querySelector(`#${this.#selectedTab.tabpanelRef}`) as HTMLElement;
    const direction: number = Math.sign(this.#selectedTab.index - this.#previousTab.index);
    const selectedAnimation = $nextTabpanel.animate(selectTabpanelAnimation(direction), animationOptions);
    const deselectAnimation = $previousTabpanel.animate(deselectTabpanelAnimation(-direction), animationOptions);
    await Promise.all([deselectAnimation.finished, selectedAnimation.finished]);
  }
  #scrollIntoView($tab: OneUxTabElement) {
    scrollElementIntoView(this._$tablist, $tab, 'horizontal', maskStretch);
  }
  get #hasFixedContent() {
    return this.#slots.hasNamedSlot('fixed-content');
  }
  #handleTabBlur(event: FocusEvent) {
    if (!this._tabs.some(tab => tab.$el === event.relatedTarget)) {
      this.#activeTabName = this.selected;
      this.#updateContext();
    }
  }
  #updateContext(updatedContext: Partial<ITabsContext> = {}) {
    this._tabsContext = {
      ...this._tabsContext,
      ...updatedContext
    };
  }
  #handleKeydown(event: KeyboardEvent) {
    const handled = () => {
      event.preventDefault();
      event.stopPropagation();
    };
    const activeIndex = this._tabs.findIndex(tab => tab.name === this.#activeTabName);
    switch (event.code) {
      case keyCodes.LEFT:
        if (activeIndex === 0) return;
        this.#setActive(this._tabs[activeIndex - 1]);
        handled();
        break;
      case keyCodes.RIGHT:
        if (activeIndex === this._tabs.length - 1) return;
        this.#setActive(this._tabs[activeIndex + 1]);
        handled();
        break;
      case keyCodes.HOME:
        this.#setActive(this._tabs[0]);
        handled();
        break;
      case keyCodes.END:
        this.#setActive(this._tabs[this._tabs.length - 1]);
        handled();
        break;
    }
  }
}