export class InternalValidityConfigChangedEvent extends Event {
  static get eventName() {
    return 'internal-one-ux-validity-config-changed';
  }
  constructor(public shouldDisplayError: boolean) {
    super(InternalValidityConfigChangedEvent.eventName, {
      bubbles: true,
      cancelable: false,
      composed: true
    });
  }
}