import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
import { Optional } from '../types.js';
export declare class IPlaceholder {
  placeholder?: string;
}
export const Placeholder = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  class PlaceholderClass extends SuperClass {
    /**
     * Provides a hint or example text to what type of interaction is expected.
     */
    @property({
      type: String
    })
    public accessor placeholder: Optional<string>;
  }
  return PlaceholderClass as Constructor<IPlaceholder> & TSuperClass;
};