import { LanguageSets } from '../../utils/getLangCode.js';
export type LanguageSet = {
  avatar: string;
  unknownUser: string;
  removedUser: string;
  anonymizedUser: string;
  impersonatedUser: string;
  loading: string;
};
export const lang: LanguageSets<LanguageSet> = {
  en: {
    avatar: 'avatar',
    unknownUser: 'Unknown user',
    removedUser: 'Removed user',
    anonymizedUser: 'Anonymized user',
    impersonatedUser: 'Impersonated user',
    loading: 'Loading'
  },
  sv: {
    avatar: 'avatar',
    unknownUser: 'Okänd användare',
    removedUser: 'Borttagen användare',
    anonymizedUser: 'Anonymiserad användare',
    impersonatedUser: 'Imiterad användare',
    loading: 'Laddar'
  },
  nb: {
    avatar: 'avatar',
    unknownUser: 'Ukjent bruker',
    removedUser: 'Fjernet bruker',
    anonymizedUser: 'Anonymisert bruker',
    impersonatedUser: 'Imitert bruker',
    loading: 'Laster'
  },
  fi: {
    avatar: 'hahmo',
    unknownUser: 'Tuntematon käyttäjä',
    removedUser: 'Poistettu käyttäjä',
    anonymizedUser: 'Anonyymi käyttäjä',
    impersonatedUser: 'Jäljitelty käyttäjä',
    loading: 'Ladataan'
  },
  da: {
    avatar: 'avatar',
    unknownUser: 'Ukendt bruger',
    removedUser: 'Fjernet bruger',
    anonymizedUser: 'Anonymiseret bruger',
    impersonatedUser: 'Imiteret bruger',
    loading: 'Indlæser'
  }
};