import { html, PropertyValues } from 'lit';
import { property, state } from 'lit/decorators.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { FocusableFactory } from '../../mixins/Focusable.js';
import { SlotController } from '../../controllers/SlotController.js';
import { consume, provide } from '@lit/context';
import { ITabpanelContext, tabpanelContext } from './TabPanelContext.js';
import { classMap } from 'lit/directives/class-map.js';
import { getDefaultMutexReadContext, mutexReadContext } from '../../contexts/MutexContext.js';
import { OneUxTabNextElement } from '../one-ux-tab-next/OneUxTabNextElement.js';
import { TABBABLE_TARGETS_SELECTOR } from '../../utils/focusable.js';
import { animationOptions, tabpanelAnimation } from './animations.js';
import { BeforeEnterEvent, BeforeLeaveEvent, EnterEvent, LeaveEvent } from './events.js';
import { defaultTabsNextContext, tabsNextContext } from '../one-ux-tabs-next/TabsNextContext.js';
const Styled = StyledFactory(style);
const Focusable = FocusableFactory(false);
const BaseClass = Focusable(Styled(OneUxElement));
export class OneUxTabpanelNextElement extends BaseClass {
  static get elementType() {
    return 'one-ux-tabpanel-next';
  }
  @provide({
    context: tabpanelContext
  })
  private _tabpanelContext: ITabpanelContext = {
    registerTabs: () => {
      this._hasTabsContent = true;
    },
    unregisterTabs: () => {
      this._hasTabsContent = false;
    }
  };
  @property({
    type: String,
    reflect: true
  })
  public accessor id: string = this.#generateId();

  /** @internal */
  @state()
  @consume({
    context: mutexReadContext<OneUxTabNextElement>(),
    subscribe: true
  })
  _mutexContext = getDefaultMutexReadContext<OneUxTabNextElement>();

  /** @internal */
  @state()
  @consume({
    context: tabsNextContext,
    subscribe: true
  })
  _tabsContext = defaultTabsNextContext;

  /** @internal */
  @state()
  accessor _hasTabsContent = false;

  /** @internal */
  @state()
  accessor _active: OneUxTabNextElement | null = null;
  #slots = new SlotController(this as OneUxElement, {
    defaultSlot: true
  });
  constructor() {
    super();
    this.addEventListener('focus', (event: FocusEvent) => {
      const $relatedTarget = event.relatedTarget as HTMLElement | null;
      if (this.contains($relatedTarget)) {
        this._mutexContext.current?.focus();
        return;
      }
      if (this.hasKeyboardFocus && this.#slots.hasDefaultSlotFocusableContent()) {
        const $tabbableChildren = Array.from(this.querySelectorAll<HTMLElement>(TABBABLE_TARGETS_SELECTOR));
        const direction = $relatedTarget && this.compareDocumentPosition($relatedTarget);
        const $target = direction === Node.DOCUMENT_POSITION_PRECEDING ? $tabbableChildren[0] : $tabbableChildren.at(-1);
        $target?.focus();
      }
    });
  }
  connectedCallback(): void {
    super.connectedCallback();
    this.role = 'tabpanel';
    this.tabIndex = 0;
  }
  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('_mutexContext')) {
      this._active = this._mutexContext.current;
    }
    if (changed.has('id')) {
      if (this.id == null || this.id === '') {
        this.id = this.#generateId();
      }
    }
  }
  protected render() {
    return html`<div
      class=${classMap({
      'one-ux-element--root': true,
      'has-tabs-content': this._hasTabsContent
    })}
    >
      <slot></slot>
    </div>`;
  }
  protected firstUpdated(_changed: PropertyValues<this>): void {
    this._mutexContext.notify($previousActiveTab => {
      this._active = this._mutexContext.current;
      if ($previousActiveTab && $previousActiveTab !== this._active) {
        const isEntering = this._active?.name === this.slot;
        const isLeaving = $previousActiveTab.name === this.slot;
        if (isEntering) {
          this.#enter($previousActiveTab);
        }
        if (isLeaving) {
          this.#leave($previousActiveTab);
        }
      }
    });
  }
  protected updated(changed: PropertyValues): void {
    if (changed.has('_active') || changed.has('_tabsContext') && this._tabsContext.hasFixedContent !== changed.get('_tabsContext').hasFixedContent) {
      const activeName = this._active?.name || '';
      const isActive = this._tabsContext.hasFixedContent || activeName === this.slot;
      this.toggleAttribute('state-active', isActive);
    }
  }
  #generateId() {
    return `tabpanel-${crypto.randomUUID()}`;
  }
  async #animate($previousActiveTab: OneUxTabNextElement, transition: 'enter' | 'leave') {
    if (!this._active) return;
    const previousTabPosition = $previousActiveTab.compareDocumentPosition(this._active);
    const direction = previousTabPosition === Node.DOCUMENT_POSITION_PRECEDING ? -1 : 1;
    await this.animate(tabpanelAnimation(transition, direction), animationOptions).finished;
  }
  async #enter($previousActiveTab: OneUxTabNextElement) {
    this.dispatchEvent(new BeforeEnterEvent());
    await new Promise(requestAnimationFrame);
    await this.#animate($previousActiveTab, 'enter');
    this.dispatchEvent(new EnterEvent());
  }
  async #leave($previousActiveTab: OneUxTabNextElement) {
    this.dispatchEvent(new BeforeLeaveEvent());
    await new Promise(requestAnimationFrame);
    await this.#animate($previousActiveTab, 'leave');
    this.dispatchEvent(new LeaveEvent());
  }
}