import { OneUxElement } from '../../OneUxElement.js';
import { html, PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';
import { ContextProvider } from '@lit/context';
import { ITreeContext, treeContext } from '../../contexts/tree/ITreeContext.js';
import { Optional } from '../../types.js';
import { avatarContext, IAvatarContext } from '../../contexts/AvatarContext.js';
import { log } from '../../utils/log.js';
const keyMap = {
  tree: treeContext,
  avatar: avatarContext
};
type contextType = keyof typeof keyMap;
type contextMap = {
  tree: ITreeContext;
  avatar: IAvatarContext;
};
let globallyAvailableKeyDeprecationWarnings = 5;
const BaseClass = OneUxElement;
export class OneUxContextProviderElement<TContextType extends contextType | unknown = unknown> extends BaseClass {
  static get elementType() {
    return 'one-ux-context-provider';
  }
  @property({
    attribute: false
  })
  public set key(key: TContextType) {
    this.#logKeyDeprecation();
    this.type = key;
  }
  public get key() {
    return this.type;
  }
  @property({
    type: String
  })
  public accessor type!: TContextType;
  @property({
    attribute: false
  })
  public accessor context: Optional<TContextType extends contextType ? contextMap[TContextType] : unknown>;
  private provider?: ContextProvider<{
    __context__: unknown;
  }>;
  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('type')) {
      this.#setupProvider();
      if (!this.context) {
        this.context = this.#tryGetDefaultContext();
      }
    }
    if (changed.has('context')) {
      this.#setupProvider();
      if (this.provider && this.context) {
        this.provider.setValue(this.context, true);
      }
    }
  }
  protected render() {
    return html`
      <div class="one-ux-element--root">
        <slot></slot>
      </div>
    `;
  }
  #setupProvider() {
    const key = keyMap[this.key as contextType];
    if (!this.provider && this.context && key) {
      this.provider = new ContextProvider<{
        __context__: unknown;
      }>(this, key, this.context);
    }
  }
  #tryGetDefaultContext() {
    switch (this.type as contextType) {
      case 'avatar':
        // TODO: If possible make typing work without casting
        return PDR.avatar.defaultAvatarContext as typeof this.context;
      default:
        return undefined;
    }
  }
  #logKeyDeprecation() {
    if (globallyAvailableKeyDeprecationWarnings) {
      globallyAvailableKeyDeprecationWarnings--;
      const reason = 'Usage of "key" is deprecated in favor of "type" property.';
      const message = globallyAvailableKeyDeprecationWarnings ? reason : `${reason} Suppressing further warnings.`;
      log.deprecation(message);
    }
  }
}