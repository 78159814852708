import { LanguageSets, getLangCode } from '../../utils/getLangCode.js';
export type LanguageSet = {
  close: string;
  minimize: string;
  expand: string;
};
const languages: LanguageSets<LanguageSet> = {
  en: {
    close: 'Close',
    minimize: 'Minimize',
    expand: 'Expand'
  },
  sv: {
    close: 'Stäng',
    minimize: 'Minimera',
    expand: 'Expandera'
  },
  nb: {
    close: 'Lukk',
    minimize: 'Minimer',
    expand: 'Utvid'
  },
  fi: {
    close: 'Sulje',
    minimize: 'Pienennä',
    expand: 'Laajenna'
  },
  da: {
    close: 'Luk',
    minimize: 'Minimer',
    expand: 'Udvid'
  }
};
export const getLanguage = (element: Element): {
  translations: LanguageSet;
  lang?: string;
} => {
  const closestLang = getLangCode(element);
  const translations = languages[closestLang];
  return {
    translations,
    lang: closestLang
  };
};