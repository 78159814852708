import { PDRModule } from '../PDRModule.js';
import { Feedback, FeedbackQueue, icon } from './FeedbackQueue.js';
export class FeedbackModule extends PDRModule {
  #feedbackQueue = new FeedbackQueue();
  show(options: {
    message: string;
    icon?: icon;
    duration?: number | boolean;
  }) {
    const feedback = new Feedback(options.message, options.icon, options.duration);
    this.#feedbackQueue.add(feedback);
  }
}