import { LanguageSets, getLangCode } from '../../utils/getLangCode.js';
export type LanguageSet = {
  scrollLeft: string;
  scrollRight: string;
  add: string;
  header: string;
};
const languages: LanguageSets<LanguageSet> = {
  en: {
    scrollLeft: 'Scroll left',
    scrollRight: 'Scroll right',
    add: 'Add',
    header: 'Tab header'
  },
  sv: {
    scrollLeft: 'Rulla åt vänster',
    scrollRight: 'Rulla åt höger',
    add: 'Lägg till',
    header: 'Tabbhuvud'
  },
  nb: {
    scrollLeft: 'Rull til venstre',
    scrollRight: 'Rull til høyre',
    add: 'Legg til',
    header: 'Faneoverskrift'
  },
  fi: {
    scrollLeft: 'Vieritä vasemmalle',
    scrollRight: 'Vieritä oikealle',
    add: 'Lisää',
    header: 'Välilehden otsikko'
  },
  da: {
    scrollLeft: 'Rul til venstre',
    scrollRight: 'Rul til højre',
    add: 'Tilføj',
    header: 'Fanehoved'
  }
};
export const getLanguage = (element: Element) => {
  const key = getLangCode(element);
  return {
    languageKey: key,
    languageSet: languages[key]
  };
};