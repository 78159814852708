import { LanguageSets, getLangCode } from '../../utils/getLangCode.js';
export type LanguageSet = {
  tabsLevelOne: string;
  tabsLevelTwo: string;
  tabIncludingSubtabs: string;
  subtab: string;
};
const languages: LanguageSets<LanguageSet> = {
  en: {
    tabsLevelOne: 'tab control level 1',
    tabsLevelTwo: 'tab control level 2',
    tabIncludingSubtabs: 'tab with subtabs',
    subtab: 'subtab'
  },
  sv: {
    tabsLevelOne: 'flikar nivå 1',
    tabsLevelTwo: 'flikar nivå 2',
    tabIncludingSubtabs: 'flik med underliggande flikar',
    subtab: 'underliggande flik'
  },
  nb: {
    tabsLevelOne: 'faner nivå 1',
    tabsLevelTwo: 'faner nivå 2',
    tabIncludingSubtabs: 'fane med underliggende faner',
    subtab: 'underliggende fane'
  },
  fi: {
    tabsLevelOne: 'faner nivå 1',
    tabsLevelTwo: 'faner nivå 2',
    tabIncludingSubtabs: 'fane med underliggende faner',
    subtab: 'underliggende fane'
  },
  da: {
    tabsLevelOne: 'faner niveau 1',
    tabsLevelTwo: 'faner niveau 2',
    tabIncludingSubtabs: 'fane med underliggende faner',
    subtab: 'underliggende fane'
  }
};
export const getLanguage = (element: Element) => {
  const key = getLangCode(element);
  return {
    languageKey: key,
    languageSet: languages[key]
  };
};