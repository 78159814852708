import { html } from 'lit';
import type { OneUxCardElement } from '../OneUxCardElement.js';
import type { slotName, SlotTemplateResult } from '../types.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { toSlotTemplateResult } from '../toSlotTemplateResult.js';
import { classMap } from 'lit/directives/class-map.js';
export function SlotFragment(this: OneUxCardElement, slot: slotName): SlotTemplateResult {
  const hasSlot = this._hasSlot(slot);
  const template = html`<slot
    class=${classMap({
    'card-element': true,
    'has-slot': hasSlot
  })}
    name=${slot}
    tabindex=${ifDefined(this.busy ? -1 : undefined)}
  >
  </slot>`;
  return toSlotTemplateResult(template, hasSlot);
}