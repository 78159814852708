import { ElementPart, nothing } from 'lit';
import { directive, Directive, DirectiveResult, PartInfo, PartType } from 'lit/directive.js';
import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
export type ariaAttributes = 'role' | 'aria-activedescendant' | 'aria-atomic' | 'aria-autocomplete' | 'aria-busy' | 'aria-checked' | 'aria-colcount' | 'aria-colindex' | 'aria-colspan' | 'aria-controls' | 'aria-current' | 'aria-describedby' | 'aria-details' | 'aria-disabled' | 'aria-dropeffect' | 'aria-errormessage' | 'aria-expanded' | 'aria-flowto' | 'aria-grabbed' | 'aria-haspopup' | 'aria-hidden' | 'aria-invalid' | 'aria-keyshortcuts' | 'aria-label' | 'aria-labelledby' | 'aria-level' | 'aria-live' | 'aria-modal' | 'aria-multiline' | 'aria-multiselectable' | 'aria-orientation' | 'aria-owns' | 'aria-placeholder' | 'aria-posinset' | 'aria-pressed' | 'aria-readonly' | 'aria-relevant' | 'aria-required' | 'aria-roledescription' | 'aria-rowcount' | 'aria-rowindex' | 'aria-rowspan' | 'aria-selected' | 'aria-setsize' | 'aria-sort' | 'aria-valuemax' | 'aria-valuemin' | 'aria-valuenow' | 'aria-valuetext';
export type delegateAria = Partial<Record<ariaAttributes, string | null>>;
export declare interface IDelegateAria {
  delegateAria: delegateAria;
  _ariaTarget(): DirectiveResult<typeof DelegateAriaDirective>;
}
export const DelegateAria = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  class DelegateAriaClass extends SuperClass {
    /**
     * NOTE: Mostly useful for internal development.
     * Delegate certain ARIA attributes to internal parts.
     * Can be useful for example if you need to use this element as a building block for something else.
     * If a string value is provided the string will be set as the respective ARIA attribute.
     * If `null` or empty string is provided the attribute will be removed.
     */
    @property({
      attribute: 'delegate-aria',
      type: Object
    })
    public accessor delegateAria = {} as delegateAria;
    public _ariaTarget() {
      return ariaTarget(this.delegateAria || {});
    }
  }
  return DelegateAriaClass as Constructor<IDelegateAria> & TSuperClass;
};
class DelegateAriaDirective extends Directive {
  constructor(partInfo: PartInfo) {
    super(partInfo);
    if (partInfo.type !== PartType.ELEMENT) {
      throw new Error('The `ariaTarget` directive must be as an Element Part');
    }
  }
  render(_props: delegateAria): unknown {
    return nothing;
  }
  update(part: ElementPart, props: unknown[]): void {
    const attributes = props[0] as delegateAria;
    for (const [attributeName, attributeValue] of Object.entries(attributes)) {
      if (attributeValue) {
        part.element.setAttribute(attributeName, attributeValue);
      } else {
        part.element.removeAttribute(attributeName);
      }
    }
  }
}
const ariaTarget = directive(DelegateAriaDirective);