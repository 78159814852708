import { ChildRefs } from './OneUxScrollElement.js';
export class MouseWheelHandler {
  public handleY;
  public handleX;
  constructor(private refs: ChildRefs) {
    this.handleY = (event: WheelEvent) => this.handle(event, 'y');
    this.handleX = (event: WheelEvent) => this.handle(event, 'x');
  }
  private handle(event: WheelEvent, type: string) {
    event.preventDefault();
    const stepSize = 40;
    const isX = type === 'x';
    const $content = this.refs.$content.value!;
    if (!isX && !event.shiftKey) {
      $content.scrollTop += event.deltaY * stepSize;
    }
    if (isX && event.shiftKey) {
      $content.scrollLeft += event.deltaY * stepSize;
    }
  }
}