import { timingFunction } from '../../one-ux/utils/animation-utils.js';
import { enter, exit } from '../../one-ux/generated/json/easing/easing.json';
import type { OneUxFeedbackElement } from '../../one-ux/elements/one-ux-feedback/OneUxFeedbackElement.js';
const ANIMATION_TIME_ENTER = 500;
const ANIMATION_TIME_EXIT = 300;
export const animateShow = async ($feedbackWrapperElement: HTMLElement) => {
  const height = await getFeedbackHeight($feedbackWrapperElement);
  $feedbackWrapperElement.animate([{
    height: '0px',
    opacity: 0
  }, {
    height: `${height}px`,
    opacity: 1
  }], {
    duration: ANIMATION_TIME_ENTER,
    easing: timingFunction(enter)
  });
  return new Promise(resolve => setTimeout(resolve, ANIMATION_TIME_ENTER));
};
export const animateHide = async ($feedbackWrapperElement: HTMLElement) => {
  const height = await getFeedbackHeight($feedbackWrapperElement);
  $feedbackWrapperElement.animate([{
    height: `${height}px`,
    opacity: 1
  }, {
    height: '0px',
    opacity: 0
  }], {
    duration: ANIMATION_TIME_EXIT,
    easing: timingFunction(exit),
    fill: 'forwards'
  });
  return new Promise(resolve => setTimeout(resolve, ANIMATION_TIME_EXIT));
};
const getFeedbackHeight = async ($feedbackWrapperElement: HTMLElement) => {
  const $feedback = $feedbackWrapperElement.firstElementChild as OneUxFeedbackElement;
  await $feedback.updateComplete;
  return $feedback.clientHeight;
};