import { NumberedVersion } from './numbered/NumberedVersion.js';
import { LatestVersion } from './special/LatestVersion.js';
import { StableVersion } from './special/StableVersion.js';
import { PrereleaseVersion } from './numbered/PrereleaseVersion.js';
import { PatchVersion } from './numbered/wildcard/PatchVersion.js';
import { MinorVersion } from './numbered/wildcard/MinorVersion.js';
import { MajorVersion } from './numbered/wildcard/MajorVersion.js';
import { Version } from './Version.js';
export class Factory {
  create(version: Version | string) {
    if (version instanceof Version) {
      return version;
    }
    if (LatestVersion.matches(version)) {
      return new LatestVersion();
    }
    if (StableVersion.matches(version)) {
      return new StableVersion();
    }
    if (PrereleaseVersion.matches(version)) {
      return new PrereleaseVersion(version);
    }
    if (MajorVersion.matches(version)) {
      return new MajorVersion(version);
    }
    if (MinorVersion.matches(version)) {
      return new MinorVersion(version);
    }
    if (PatchVersion.matches(version)) {
      return new PatchVersion(version);
    }
    return new NumberedVersion(version);
  }
}
export default new Factory();