import { html } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { OneUxElement } from '../../OneUxElement.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { Implicit } from '../../mixins/Implicit.js';
import { Busy } from '../../mixins/Busy.js';
import { OpenClosedFactory } from '../../mixins/OpenClosed.js';
import { FocusableFactory } from '../../mixins/Focusable.js';
import { Label } from '../../mixins/Label.js';
import { Compact } from '../../mixins/Compact.js';
import { SlotController } from '../../controllers/SlotController.js';
import { getLangCode } from '../../utils/getLangCode.js';
import { keyCodes } from '../../utils.js';
import { style } from './style.js';
import { handleOpenCloseAction, handleOpenCloseBeforeAction } from './animations.js';
import { strings } from './language.js';
import { SelectEvent } from './events/SelectEvent.js';
import { TitleFragment } from './fragments/TitleFragment.js';
import { SlotFragment } from './fragments/SlotFragment.js';
import { SlotItemsEndFragment } from './fragments/SlotItemsEndFragment.js';
import { VerticalContainerFragment } from './fragments/VerticalContainerFragment.js';
import { HorizontalContainerFragment } from './fragments/HorizontalContainerFragment.js';
import { singleLineSlots, slots } from './constants.js';
import type { slotName } from './types.js';
const Styled = StyledFactory(style);
const Focusable = FocusableFactory(false);
const {
  Open
} = OpenClosedFactory({
  type: 'open',
  async action() {
    await handleOpenCloseAction(this);
  },
  beforeAction() {
    handleOpenCloseBeforeAction(this);
  }
});
const BaseClass = Open(Busy(Compact(Implicit(Focusable(Label(Styled(OneUxElement)))))));
export class OneUxCardElement extends BaseClass {
  static get elementType() {
    return 'one-ux-card';
  }
  @property({
    type: Boolean
  })
  public accessor highlight = false;
  @property({
    type: Boolean
  })
  public accessor static = false;
  @query('.one-ux-element--root')
  private accessor _rootElement!: HTMLElement;
  protected guardedRender() {
    const lang = getLangCode(this);
    const Slot = SlotFragment.bind(this);
    const Title = TitleFragment.bind(this);
    const SlotItemsEnd = SlotItemsEndFragment.bind(this);
    const HorizontalContainer = HorizontalContainerFragment.bind(this);
    const VerticalContainer = VerticalContainerFragment.bind(this);
    return html`<div
      class=${classMap({
      'one-ux-element--root': true,
      'is-single-line': this.#isSingleLine,
      'is-busy': this.busy,
      'is-highlight': this.highlight,
      'is-static': this.static
    })}
      role="group"
      aria-roledescription=${strings[lang].card}
      aria-label=${this.label}
      tabindex=${this.static || this.busy ? -1 : 0}
      @keydown=${this.#handleKeyDown}
      @mousedown=${this.#handleMouseDown}
    >
      <div id="main-content" class="main-content">
        ${Slot('card-start')}
        ${VerticalContainer([HorizontalContainer([Slot('context'), Slot('context-end')]), HorizontalContainer([Slot('title-start'), Title(), Slot('title-end')]), Slot('description'), HorizontalContainer([Slot('items'), SlotItemsEnd()]), Slot('footer')])}
        ${Slot('card-end')}
      </div>
      <div
        class=${classMap({
      'expandable-content-parent': true,
      expanded: this.open
    })}
      >
        <div class="expandable-content">
          <slot name="expandable-content"></slot>
        </div>
      </div>
    </div>`;
  }
  protected _hasSlot(slot: slotName) {
    return this.#slots.hasNamedSlot(slot);
  }
  #slots: SlotController = new SlotController(this, {
    defaultSlot: false,
    slots: Array.from(slots)
  });
  get #isSingleLine() {
    return !slots.some(slot => !singleLineSlots.includes(slot) && this.#slots.hasNamedSlot(slot));
  }
  #handleMouseDown() {
    requestAnimationFrame(() => {
      const hasClickedInteractiveChild = this.shadowRoot!.activeElement !== this._rootElement;
      if (hasClickedInteractiveChild) return;
      this.dispatchEvent(new SelectEvent());
    });
  }
  #handleKeyDown(e: KeyboardEvent) {
    if (e.target !== this._rootElement) return;
    switch (e.code) {
      case keyCodes.RETURN:
      case keyCodes.NUMPADRETURN:
      case keyCodes.SPACE:
        {
          e.preventDefault();
          this.dispatchEvent(new SelectEvent());
        }
        break;
    }
  }
}