import { OneUxSliderElement } from './OneUxSliderElement.js';
import { getLangCode, langCode, LanguageSets } from '../../utils/getLangCode.js';
export type language = {
  locale: langCode;
  translations: translationSet;
};
export type translationSet = {
  start: string;
  end: string;
};
export const languages: LanguageSets<translationSet> = {
  en: {
    start: 'Start',
    end: 'End'
  },
  sv: {
    start: 'Start',
    end: 'Slut'
  },
  nb: {
    start: 'Start',
    end: 'Slutt'
  },
  fi: {
    start: 'Alku',
    end: 'Loppu'
  },
  da: {
    start: 'Start',
    end: 'Slut'
  }
};
export const getLanguage = (element: OneUxSliderElement): language => {
  const locale = getLangCode(element);
  const translations = languages[locale];
  return {
    locale,
    translations
  };
};