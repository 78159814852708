import { type hexColor } from './types.js';
const DEFAULT_ALPHA = 1;
const SHORT_HEX_WITH_ALPHA_LENGTH = 4;
export function getGrayscale(hexColor: hexColor) {
  const {
    r,
    g,
    b,
    alpha
  } = toRGBA(hexColor);

  // RGB to grayscale conversion according to NTCS https://www.w3.org/TR/AERT/#color-contrast
  const grayscale = 0.299 * r + 0.587 * g + 0.114 * b;
  const alphaAdjustedGrayscale = grayscale * alpha + (1 - alpha);
  return alphaAdjustedGrayscale;
}
function toRGBA(hexColor: hexColor) {
  const normalizedHexColor = normalizeHexColor(hexColor);
  const r = parseInt(normalizedHexColor.substring(0, 2), 16) / 255;
  const g = parseInt(normalizedHexColor.substring(2, 4), 16) / 255;
  const b = parseInt(normalizedHexColor.substring(4, 6), 16) / 255;
  const a = parseInt(normalizedHexColor.substring(6, 8), 16) / 255;
  const alpha = Number.isNaN(a) ? DEFAULT_ALPHA : a;
  return {
    r,
    g,
    b,
    alpha
  };
}
function normalizeHexColor(hexColor: hexColor) {
  const hexValues = hexColor.substring(1);
  if (hexValues.length > SHORT_HEX_WITH_ALPHA_LENGTH) {
    return hexValues;
  }
  return hexValues.replace(/./g, '$&$&');
}