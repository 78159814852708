import { html } from 'lit';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { provide } from '@lit/context';
import { dashboardContext, IDashboardContext } from '../../contexts/DashboardContext.js';
import { styleMap } from 'lit/directives/style-map.js';
import { property } from 'lit/decorators.js';
import { getLanguage } from './lang.js';
import spacings from '../../generated/json/spacing/spacing.json';
import { Label } from '../../mixins/Label.js';
const Styled = StyledFactory(style);
const BaseClass = Label(Styled(OneUxElement));
export class OneUxDashboardElement extends BaseClass {
  static get elementType() {
    return 'one-ux-dashboard';
  }
  @property({
    type: Number
  })
  public accessor columns = 1;
  @property({
    type: Number,
    attribute: 'row-height'
  })
  public accessor rowHeight = 454;
  @provide({
    context: dashboardContext
  })
  private _dashboardContext: IDashboardContext = {
    columns: this.columns,
    gap: parseInt(spacings[500], 10),
    rowHeight: this.rowHeight
  };
  constructor() {
    super();
    this.width = 'max';
  }
  protected willUpdate(): void {
    this._dashboardContext = {
      ...this._dashboardContext,
      columns: this.columns,
      rowHeight: this.rowHeight
    };
  }
  protected guardedRender() {
    const lang = getLanguage(this);
    return html`<div
      class="one-ux-element--root"
      role="list"
      aria-roledescription=${lang.translations.dashboard}
      aria-label=${this.label}
      style=${styleMap({
      'grid-template-columns': `repeat(${this._dashboardContext.columns}, minmax(0, 1fr))`,
      'grid-auto-rows': `${this.rowHeight}px`,
      gap: `${this._dashboardContext.gap}px`
    })}
    >
      <slot></slot>
    </div>`;
  }
}