import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
import { InternalValueChangedEvent } from '../events/internal/InternalValueChangedEvent.js';
import { InternalValidityVisibleEvent } from '../events/internal/InternalValidityVisibleEvent.js';
import { InternalElementStateChangedEvent } from '../events/internal/InternalElementStateChangedEvent.js';
export interface IValue<TValue> {
  value: TValue;
  get empty(): boolean;
  initialValue: TValue;
  internalValue: TValue;
  _applyUserValue(value: TValue): void;
}
export type valueFactoryOptions<TValue, TExtraProperties = void> = ThisType<OneUxElement & IValue<TValue> & TExtraProperties> & Partial<{
  defaultValue(): TValue;
  type: typeof String | typeof Boolean | typeof Number | typeof Array | typeof Object;
  reflect: boolean;
  converter(value: string | null): TValue;
  getter(): TValue;
  setter(value: TValue): void;
  isEmpty(value: TValue): boolean;
}>;
export const ValueFactory = <TValue, TExtraProperties = void>(options: valueFactoryOptions<TValue, TExtraProperties>) => {
  const hasType = Object.hasOwn(options, 'type');
  const hasConverter = Object.hasOwn(options, 'converter');
  if (hasType && hasConverter) {
    throw new Error('The options type and converter are mutually exclusive');
  }
  return <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
    class ValueClass extends SuperClass {
      static get __one_ux_mixin_value__() {
        return true as const;
      }
      #allowInitial = true;
      constructor(...args: any[]) {
        super(...args);
        if (typeof options.defaultValue !== 'undefined') {
          this.value = options.defaultValue();
        }
        this.updateComplete.then(() => {
          this.#allowInitial = false;
          this.dispatchEvent(new InternalElementStateChangedEvent({
            property: 'empty',
            value: this.empty
          }));
        });
      }
      internalValue!: TValue;
      /*
       * Holds the current value
       */
      @property({
        type: options.type,
        reflect: options.reflect,
        converter: options.converter
      })
      public set value(value: TValue) {
        const shouldSetInitialValue = typeof this.initialValue === 'undefined' || !this.hasUpdated;
        if (this.#allowInitial && shouldSetInitialValue) {
          this.initialValue = value;
        }
        this.internalValue = value;
        if (options.setter) {
          options.setter.call(this, value);
        }
        this.updateComplete.then(() => {
          this.dispatchEvent(new InternalValueChangedEvent());
          this.dispatchEvent(new InternalElementStateChangedEvent({
            property: 'empty',
            value: this.empty
          }));
        });
      }
      public get value(): TValue {
        if (options.getter) {
          return options.getter.call(this);
        }
        return this.internalValue;
      }
      initialValue?: TValue;
      public _applyUserValue(value: TValue) {
        this.value = value;
        this.dispatchEvent(new InternalValidityVisibleEvent());
      }
      get empty() {
        return options.isEmpty ? options.isEmpty(this.value) : !this.value;
      }
    }
    return ValueClass as Constructor<IValue<TValue>> & TSuperClass;
  };
};