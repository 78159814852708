import { LitElement, css, html } from 'lit';
import { property } from 'lit/decorators.js';
import { provide } from '@lit/context';
import { isBooleanAttribute, isIgnoredAttributeForContextAndAdapter } from '../../utils/attributes.js';
import { defaultAvatarContext, avatarContext } from '../../contexts/AvatarContext.js';
import { defaultLabelContext, labelContext } from '../../contexts/LabelContext.js';
import { defaultPopoutContext, popoutContext } from '../../contexts/PopoutContext.js';
import { defaultDropdownContext, dropdownContext } from '../../contexts/DropdownContext.js';
import { defaultTabsContext, tabsContext } from '../../contexts/TabsContext.js';
import { defaultTreeContext, treeContext } from '../../contexts/tree/ITreeContext.js';
import { InternalElementStateChangedEvent } from '../../events/internal/InternalElementStateChangedEvent.js';
import { InternalValidityChangedEvent } from '../../events/internal/InternalValidityChangedEvent.js';
import { InternalValidityConfigChangedEvent } from '../../events/internal/InternalValidityConfigChangedEvent.js';
import { InternalValidityResetEvent } from '../../events/internal/InternalValidityResetEvent.js';
import { InternalValidityVisibleEvent } from '../../events/internal/InternalValidityVisibleEvent.js';
import { InternalValueChangedEvent } from '../../events/internal/InternalValueChangedEvent.js';
function notifyInternalStateChange(target: OneUxAdapterElement, name: string, value: unknown) {
  if (isIgnoredAttributeForContextAndAdapter(name) || target.disableNotify) {
    return;
  }
  target.dispatchEvent(new InternalElementStateChangedEvent({
    property: name,
    value: isBooleanAttribute(name) ? value != null : value
  }));
}
const observer = new MutationObserver(mutations => {
  for (const mutation of mutations) {
    const {
      attributeName,
      target
    } = mutation;
    const name = attributeName as string;
    const $adapter = target as OneUxAdapterElement;
    notifyInternalStateChange($adapter, name, $adapter.getAttribute(name));
  }
});

/**
 * An adapter component that can be used in place of other components when doing custom implementations.
 */
export class OneUxAdapterElement extends LitElement {
  static get elementType() {
    return 'one-ux-adapter';
  }
  @provide({
    context: avatarContext
  })
  private _blockAvatarContext = defaultAvatarContext;
  @provide({
    context: labelContext
  })
  private _blockLabelContext = defaultLabelContext;
  @provide({
    context: popoutContext
  })
  private _blockPopoutContext = defaultPopoutContext;
  @provide({
    context: dropdownContext
  })
  private _blockDropdownContext = defaultDropdownContext;
  @provide({
    context: tabsContext
  })
  private _blockTabsContext = defaultTabsContext;
  @provide({
    context: treeContext
  })
  private _blockTreeContext = defaultTreeContext;

  /*
   * Ensure adapter never emits any internal events. This is mostly useful for development of OneUX elements that manages its own internal events and uses OneUX elements internally in its rendering.
   */
  @property({
    type: Boolean,
    attribute: 'disable-notify'
  })
  public accessor disableNotify = false;
  constructor() {
    super();
    const abortEvent = (e: Event) => {
      if (e.target == this) {
        return;
      }
      e.preventDefault();
      e.stopImmediatePropagation();
    };
    this.addEventListener(InternalElementStateChangedEvent.eventName, abortEvent);
    this.addEventListener(InternalValidityChangedEvent.eventName, abortEvent);
    this.addEventListener(InternalValidityConfigChangedEvent.eventName, abortEvent);
    this.addEventListener(InternalValidityResetEvent.eventName, abortEvent);
    this.addEventListener(InternalValidityVisibleEvent.eventName, abortEvent);
    this.addEventListener(InternalValueChangedEvent.eventName, abortEvent);
    observer.observe(this, {
      attributes: true
    });
  }
  firstUpdated() {
    for (const {
      name,
      value
    } of this.attributes) {
      notifyInternalStateChange(this, name, value);
    }
  }
  render() {
    return html`<slot></slot>`;
  }
  static styles = css`
    :host {
      display: contents !important;
    }
  `;
}