const poolLimit = 256;
const messageChannelPool = [] as MessageChannel[];
export class FunctionUtils {
  debounce<TArgs extends Array<unknown>>(callback: (...args: TArgs) => void, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout>;
    const abort = () => clearTimeout(timeoutId);
    const _invoke = (immediate: boolean, args: TArgs) => {
      abort();
      if (immediate) {
        callback(...args);
      } else {
        timeoutId = setTimeout(() => callback(...args), delay);
      }
    };
    function invoke(...args: TArgs): void {
      _invoke(false, args);
    }
    invoke.immediate = (...args: TArgs): void => {
      _invoke(true, args);
    };
    invoke.abort = abort;
    return invoke;
  }
  defer(callback: (...args: unknown[]) => void, delay?: number) {
    if (typeof delay === 'number') {
      setTimeout(callback, delay);
    } else {
      const channel = messageChannelPool.length ? messageChannelPool.pop() as MessageChannel : new MessageChannel();
      channel.port1.onmessage = function () {
        try {
          callback();
        } finally {
          channel.port1.onmessage = null;
          if (messageChannelPool.length <= poolLimit) {
            messageChannelPool.push(channel);
          }
        }
      };
      channel.port2.postMessage('');
    }
  }
}