import { timingFunction } from '../../utils/animation-utils.js';
import oneUxEasing from '../../generated/json/easing/easing.json';
import oneUxDuration from '../../generated/json/duration/duration.json';
export const animationOptions = {
  duration: oneUxDuration[500],
  easing: timingFunction(oneUxEasing.enter)
};
export const selectTabpanelAnimation = (direction: number) => [{
  opacity: 0,
  visibility: 'hidden',
  'pointer-events': 'none',
  transform: `translateX(${direction * 100}%)`
}, {
  opacity: 1,
  visibility: 'visible',
  transform: 'translateX(0)'
}];
export const deselectTabpanelAnimation = (direction: number) => [{
  opacity: 1,
  visibility: 'visible',
  transform: 'translateX(0)',
  'z-index': 1
}, {
  opacity: 0,
  visibility: 'hidden',
  'pointer-events': 'none',
  transform: `translateX(${direction * 100}%)`
}];