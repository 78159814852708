import { css } from 'lit';
export const style = css`
  .one-ux-element--root {
    --one-ux-card-element--internal-border-width: min(
      var(--one-ux-card-element--border-width, var(--one-ux-border-width--400)),
      var(--one-ux-border-width--500)
    );
    --one-ux-card-element--internal-active-border-color: var(
      --one-ux-card-element--border-color,
      var(--one-ux-palette--focus-400)
    );
    --one-ux-card-element--internal-active-border-width: var(--one-ux-border-width--500);

    display: inline-flex;
    flex-direction: column;
    width: min-content;
    height: min-content;

    transition-property: background, outline-color, box-shadow;
    transition-duration: var(--one-ux-duration--300);
    transition-timing-function: var(--one-ux-easing--default);

    padding: var(--one-ux-spacing--400);
    background: var(--one-ux-card-element--background, var(--one-ux-palette--gray-100));
    border-radius: var(--one-ux-radius--400);
    outline-style: var(--one-ux-card-element--border-style, solid);
    outline-width: var(--one-ux-card-element--internal-border-width);
    outline-color: var(--one-ux-card-element--border-color, var(--one-ux-palette--brand-neutral-300));
    outline-offset: calc(-1 * var(--one-ux-card-element--internal-border-width));
    box-shadow: none;
    cursor: pointer;

    :host([implicit]) & {
      --one-ux-card-element--internal-border-width: var(--one-ux-border-width--500);
      background: transparent;
      outline-color: transparent;
    }

    :host([state-keyboard-focus]) &:focus {
      outline: var(--one-ux-border-width--500) solid var(--one-ux-palette--keyboard-focus-400) !important;
      background: var(--one-ux-palette--keyboard-focus-200) !important;
    }

    :host([compact]) & {
      padding: var(--one-ux-spacing--200) var(--one-ux-spacing--300);
      border-radius: var(--one-ux-radius--300);

      &.is-single-line {
        .card-title {
          overflow: hidden;
          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    &.is-highlight {
      --one-ux-card-element--internal-border-width: var(--one-ux-card-element--internal-active-border-width) !important;
      background: var(
        --one-ux-card-element--active-background,
        color-mix(
          in srgb,
          var(--one-ux-card-element--internal-active-border-color) 12%,
          var(--one-ux-palette--gray-100)
        )
      ) !important;
      outline-color: var(--one-ux-card-element--internal-active-border-color) !important;
    }

    &.is-static {
      cursor: default;
    }

    &:not(.is-highlight, .is-static):hover {
      --one-ux-card-element--internal-border-width: var(--one-ux-card-element--internal-active-border-width);
      box-shadow: var(--one-ux-card-element--hover-shadow, var(--one-ux-shadow--drop-100));
      outline-color: var(--one-ux-card-element--internal-active-border-color);
      background: var(--one-ux-card-element--active-background, var(--one-ux-palette--gray-100));
    }

    &.is-busy {
      position: relative;
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background-image: linear-gradient(
          -110deg,
          transparent 30%,
          rgba(var(--one-ux-rgb--gray-100), 0.7) 50%,
          transparent 70%
        );
        background-size: 200% 100%;
        background-position: center center;
        background-repeat: repeat-x;
        animation: move-gradient 2000ms infinite linear;
      }

      .card-toggle-button,
      .card-title {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          inset: -1px;
          border-radius: var(--one-ux-radius--300);
          background: var(--one-ux-palette--brand-neutral-150);
        }
      }

      slot {
        &::slotted(*) {
          position: relative;
        }

        &::slotted(*)::after {
          content: '';
          position: absolute;
          inset: -1px;
          border-radius: var(--one-ux-radius--300);
          background: var(--one-ux-palette--brand-neutral-150);
        }
      }
    }
  }

  .main-content {
    display: flex;
    gap: var(--one-ux-spacing--300);
  }

  .expandable-content-parent {
    overflow: hidden;
    visibility: hidden;
    height: 0;

    &.expanded {
      height: auto;
      visibility: visible;
    }

    .expandable-content {
      width: auto;
      padding-top: var(--one-ux-spacing--300);
    }
  }

  .card-element {
    display: flex;
    gap: var(--one-ux-spacing--300);

    &:not(.has-slot, .card-title) {
      display: none;
    }

    &.vertical-container {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    &.horizontal-container {
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &[name='context'] {
      flex: 1;
    }

    &[name='context'],
    &[name='context-end'] {
      font: var(--one-ux-font--label-400);
      letter-spacing: var(--one-ux-letter-spacing--label-400);
      color: var(--one-ux-palette--brand-neutral-400);

      &::slotted([one-ux-element='one-ux-icon']) {
        font-size: var(--one-ux-size--icon-100);
        color: var(--one-ux-palette--brand-neutral-600);
      }
    }

    &[name='title-start'] {
      white-space: nowrap;
      gap: var(--one-ux-spacing--200);

      &::slotted([one-ux-element='one-ux-icon']) {
        font-size: var(--one-ux-size--icon-100);
      }
    }

    &.card-title {
      font: var(--one-ux-font--heading-200);
      letter-spacing: var(--one-ux-letter-spacing--heading-200);
      color: var(--one-ux-palette--brand-neutral-600);
      margin-right: auto;
    }

    &[name='title-end'] {
      align-self: start;
      white-space: nowrap;
      gap: var(--one-ux-spacing--200);
    }

    &[name='description'] {
      width: 100%;
      font: var(--one-ux-font--body-300);
      letter-spacing: var(--one-ux-letter-spacing--body-300);
      color: var(--one-ux-palette--brand-neutral-500);
    }

    &[name='items'] {
      white-space: nowrap;
      flex: 1;
    }

    &[name='items-end'] {
      white-space: nowrap;
    }

    &[name='footer'] {
      width: 100%;
    }

    &.items-end-parent {
      gap: var(--one-ux-spacing--300);
      align-items: center;
      margin-left: auto;
    }
  }

  @keyframes move-gradient {
    0% {
      background-position: 200% 0%;
    }

    100% {
      background-position: 0% 0%;
    }
  }
`;