import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
export declare class IExplicit {
  explicit: boolean;
}
export const Explicit = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  // TODO: remove any
  if ((SuperClass as any).__one_ux_mixin_implicit__) {
    throw new Error(`Can't apply Explicit mixin if Implicit mixin is already applied. `);
  }
  class ExplicitClass extends SuperClass {
    static get __one_ux_mixin_explicit__() {
      return true as const;
    }

    /**
     * Describes the visual relationship of the component to its surroundings.
     * When a component is set to be `explicit` the functionality of the component should be apparent regardless of its surroundings.
     *
     * Adding this flag can drastically change the visual impact of the component.
     */
    @property({
      type: Boolean,
      reflect: true
    })
    public accessor explicit = false;
  }
  return ExplicitClass as Constructor<IExplicit> & TSuperClass;
};