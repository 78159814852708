import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { Focusable } from '../../mixins/Focusable.js';
import { Implicit } from '../../mixins/Implicit.js';
import { Compact } from '../../mixins/Compact.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { OneUxElement } from '../../OneUxElement.js';
import { IValue, ValueFactory } from '../../mixins/Value.js';
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../../mixins/Validated.js';
import { InternalValueChangedEvent } from '../../events/internal/InternalValueChangedEvent.js';
import { FormAssociated } from '../../mixins/FormAssociated.js';
import { Required, type IRequired } from '../../mixins/Required.js';
import { Label } from '../../mixins/Label.js';
import { FieldSetProps } from '../one-ux-field-set/FieldSetProps.js';
import { Errors } from '../../mixins/Errors.js';
import { Disabled } from '../../mixins/Disabled.js';
import { register as _registerElement } from "../one-ux-field-set/register-element.js";
import { register as _registerElement2 } from "../one-ux-radio/register-element.js";
import { register as _registerElement3 } from "../one-ux-label/register-element.js";
_registerElement3("label-f69b02bedb96d13bfa9c88782ad9b02c");
_registerElement2("radio-0e2b27501c74dd790411082e02f95b51");
_registerElement("field-set-610cef700f19e4ec74e645129e27d865");
type RadioOption = {
  value: unknown;
  text: string;
  disabled: boolean;
};
type valueType = unknown;
const Value = ValueFactory<valueType>({
  type: Object,
  // TODO: Should be removed in next major
  reflect: true
});
const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult;
    }
    const {
      fieldYouHaveToMakeChoice
    } = getFormValidationLanguage(this);
    const hasValue: boolean = !!this.value;
    return {
      valid: hasValue,
      flags: {
        valueMissing: !hasValue
      },
      errors: [fieldYouHaveToMakeChoice]
    };
  }
});
const Purpose = PurposeFactory({
  purposes: ['default', 'main', 'caution', 'notice']
});
const BaseClass = Errors(Label(Disabled(Required(FieldSetProps(FormAssociated(Validated(Value(Focusable(Compact(Implicit(Purpose(OneUxElement))))))))))));

/**
 * A group of radio buttons, where exactly one radio is selected.
 */
export class OneUxRadioGroupElement extends BaseClass {
  static get elementType() {
    return 'one-ux-radio-group';
  }

  /*
   * The list of options for the radio group.
   * * text: Displayed text for option
   * * value: When listening on the option event it will be passed as argument
   * * disabled: If the option is disabled
   */
  @property({
    type: Array
  })
  public accessor options: RadioOption[] = [];

  /*
   * Placement of the radios against their texts.
   */
  @property({
    attribute: 'radio-position',
    type: String
  })
  public accessor radioPosition = 'before-text' as 'before-text' | 'after-text';
  render() {
    return html`
      <field-set-610cef700f19e4ec74e645129e27d865
        class="one-ux-element--root"
        .delegateAria=${{
      role: 'radiogroup',
      'aria-orientation': 'vertical'
    }}
        .disabled=${this.disabled}
        .label=${this.label}
        .implicit=${this.implicit}
        .compact=${this.compact}
        .columns=${this.columns}
        .errors=${this.errors}
        .required=${this.required}
        tabindex="-1"
        @focus=${this.#setFocusToCurrentTabbableRadio}
      >
        ${this.options.map((option, index) => html`
            <label-f69b02bedb96d13bfa9c88782ad9b02c label=${option.text}>
              <radio-0e2b27501c74dd790411082e02f95b51
                tabindex=${this.value == option.value ? 0 : !this.value && index == 0 ? 0 : -1}
                .disabled=${this.disabled || option.disabled}
                .checked=${this.value == option.value}
                .implicit=${this.implicit}
                .purpose=${this.purpose}
                slot=${this.radioPosition === 'before-text' ? 'start' : 'end'}
                pdr-test-hook="one-ux-radio-group-item-${String(option.value)}"
                @change=${() => this.#handleSelect(option.value)}
                @focus=${({
      relatedTarget
    }: FocusEvent) => {
      this.#handleFocus(option, relatedTarget);
    }}
              ></radio-0e2b27501c74dd790411082e02f95b51>
            </label-f69b02bedb96d13bfa9c88782ad9b02c>
          `)}
      </field-set-610cef700f19e4ec74e645129e27d865>
    `;
  }
  #select(value: unknown) {
    this._applyUserValue(value);
    this.dispatchEvent(new InternalValueChangedEvent());
  }
  #handleSelect(value: unknown) {
    this.#select(value);
    this.dispatchEvent(new Event('input'));
    this.dispatchEvent(new Event('change'));
  }
  #handleFocus(option: RadioOption, related: EventTarget | null) {
    if (!option.disabled && related && this.shadowRoot!.contains(related as Node)) {
      this.#select(option.value);
      this.dispatchEvent(new Event('input'));
      this.dispatchEvent(new Event('change'));
    }
  }
  #setFocusToCurrentTabbableRadio() {
    this.shadowRoot!.querySelector<HTMLElement>('[one-ux-element="one-ux-radio"][tabindex="0"]')?.focus();
  }
}