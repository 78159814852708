import type { OneUxGadgetElement } from './OneUxGadgetElement.js';
import { getCursorPosition } from '../../utils/mouse-helper.js';
import { startUpdatingAncestorScrollbarsByCursorPosition } from '../../utils/update-ancestor-scrollbars-by-cursor-position.js';
export function handleMouseMove(this: OneUxGadgetElement) {
  if (!this._hasDashboardParent || !this.movable) {
    return;
  }

  // Delay in order to see which element actually received focus.
  // This will distinguish between dragging the header and clicking something interactive in the header.
  requestAnimationFrame(() => {
    if (document.activeElement !== this) {
      return;
    }
    const startingRect = this.getBoundingClientRect();
    const startingMouse = getCursorPosition();
    lockDashboardOrder(this.parentElement!);
    this._draggingState = {
      width: startingRect.width,
      height: startingRect.height,
      top: startingRect.top,
      left: startingRect.left
    };
    const moving = () => {
      const currentMouse = getCursorPosition();
      const deltaMouseX = currentMouse.cursorX - startingMouse.cursorX;
      const deltaMouseY = currentMouse.cursorY - startingMouse.cursorY;
      const $hoveredSibling = document.elementsFromPoint(currentMouse.cursorX, currentMouse.cursorY).find(x => !this.contains(x) && this.closest('[one-ux-element="one-ux-dashboard"]') === this.parentElement)?.closest<HTMLElement>('[one-ux-element="one-ux-dashboard"] > *');
      if ($hoveredSibling) {
        const siblingRect = $hoveredSibling.getBoundingClientRect();
        const siblingHorizontalCenter = siblingRect.left + siblingRect.width / 2;
        const shouldPlaceBeforeSibling = currentMouse.cursorX <= siblingHorizontalCenter;
        const siblingOrder = parseInt($hoveredSibling.style.order, 10);
        const newOrder = shouldPlaceBeforeSibling ? siblingOrder - 1 : siblingOrder + 1;
        this.style.setProperty('order', String(newOrder), 'important');
      }
      this._draggingState = {
        ...this._draggingState!,
        top: startingRect.top + deltaMouseY,
        left: startingRect.left + deltaMouseX
      };
    };
    document.addEventListener('mousemove', moving, {
      capture: true
    });
    document.addEventListener('scroll', moving, {
      capture: true
    });
    const {
      stopUpdatingAncestorScrollbars
    } = startUpdatingAncestorScrollbarsByCursorPosition(this);
    document.addEventListener('mouseup', () => {
      const currentOrder = parseInt(this.style.order, 10);
      const insertionIndex = Math.ceil(currentOrder / 10);
      const currentIndex = Array.from(this.parentElement!.children).indexOf(this);
      const newIndex = currentIndex < insertionIndex ? insertionIndex - 1 : insertionIndex;
      clearDashboardOrder(this.parentElement!);
      this._draggingState = null;
      document.removeEventListener('mousemove', moving, {
        capture: true
      });
      document.removeEventListener('scroll', moving, {
        capture: true
      });
      stopUpdatingAncestorScrollbars();
      this._move(newIndex);
    }, {
      capture: true,
      once: true
    });
  });
}
function lockDashboardOrder($dashboard: HTMLElement) {
  Array.from($dashboard.children).forEach(($child: any, index) => {
    $child.style.setProperty('order', index * 10, 'important');
  });
}
function clearDashboardOrder($dashboard: HTMLElement) {
  Array.from($dashboard.children).forEach(($child: any) => {
    $child.style.removeProperty('order');
  });
}