export class SelectEvent extends Event {
  static get eventName() {
    return 'select';
  }
  constructor() {
    super(SelectEvent.eventName, {
      bubbles: false,
      cancelable: true,
      composed: false
    });
  }
}