import { log } from '../../utils/log.js';
import { DividerData, GroupData, InputItem, OptionData, InputOption, InputGroup, InputDivider, ItemData } from './types.js';
let instanceIdGenerator = 0;
export function cloneItem(input: InputItem, parent: GroupData | null): OptionData | DividerData | GroupData {
  if (input.type == 'divider') {
    const dividerInput = input as InputDivider;
    return createDivider(dividerInput, parent);
  }
  if (input.type == 'group') {
    const groupInput = input as InputGroup;
    return createGroup(groupInput, parent);
  }
  const optionInput = input as InputOption;
  checkAction(optionInput);
  return createOption(optionInput, parent);
}
function checkAction(data: InputOption) {
  if (data.action && typeof data.action !== 'function') {
    log.warning({
      title: 'Provided action is not a function, see details for omitted context:',
      details: data
    });
  }
}
function createOption(data: InputOption, parent: GroupData | null): OptionData {
  return {
    type: 'option',
    instanceId: instanceIdGenerator++,
    parent: parent ?? undefined,
    text: data.text || '',
    value: data.value,
    icon: data.icon ? {
      ...data.icon
    } : undefined,
    disabled: !!data.disabled,
    tooltip: data.tooltip || undefined,
    action: typeof data.action == 'function' ? data.action : undefined,
    url: data.url,
    urlTarget: data.urlTarget
  };
}
function createGroup(data: InputGroup, parent: GroupData | null): GroupData {
  const group: GroupData = {
    type: 'group',
    instanceId: instanceIdGenerator++,
    parent: parent ?? undefined,
    text: data.text || '',
    icon: data.icon ? {
      ...data.icon
    } : undefined,
    expanded: false,
    children: []
  };
  group.children = (data.children || []).map(child => cloneItem(child, group));
  return group;
}
function createDivider(_data: InputDivider, parent: GroupData | null): DividerData {
  return {
    type: 'divider',
    instanceId: instanceIdGenerator++,
    parent: parent ?? undefined
  };
}
export const availableItemCriteria = (item: ItemData) => item.type === 'group' || item.type === 'option' && !(item as OptionData).disabled;
export const findIndexBasedOnType = (items: ItemData[], item: ItemData) => items.filter(x => x.type === item.type).findIndex(x => x === item);