import { html, nothing, TemplateResult } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import type { weight } from '../../mixins/Weight.js';
import { purpose } from '../../mixins/Purpose.js';
type indicatorOptions = {
  implicit: boolean;
  weight: weight;
  purpose: Extract<purpose, 'default' | 'ai'>;
  isKeyboardFocused: boolean;
  selected?: boolean;
  width?: number;
  posLeft?: number;
  posBottom?: number;
};
export function Indicator({
  implicit,
  weight,
  purpose,
  isKeyboardFocused,
  selected = false,
  width,
  posLeft = 0
}: indicatorOptions): TemplateResult | typeof nothing {
  const getColor = () => {
    if (purpose === 'ai') {
      return {
        'background-color': 'var(--one-ux-palette--ai-core-400)',
        'background-image': 'linear-gradient(to right, var(--one-ux-palette--ai-core-400) 0%, var(--one-ux-palette--ai-accent-400) 100%)'
      };
    }
    if (isKeyboardFocused) {
      return {
        'background-color': 'var(--one-ux-palette--keyboard-focus-700)'
      };
    }
    return {
      'background-color': weight === 'low' ? 'var(--one-ux-palette--brand-neutral-400)' : 'var(--one-ux-palette--brand-core-400)'
    };
  };
  const indicatorMargin = implicit ? selected ? '0px' : `var(--one-ux-spacing--200)` : `var(--one-ux-spacing--300)`;
  const indicatorWidth = `calc(${width ? `${width}px` : `100%`} - ${indicatorMargin} * 2)`;
  const height = weight === 'low' ? 2 : 4;
  const borderRadius = implicit ? 'var(--one-ux-radius--300)' : 'var(--one-ux-radius--300) var(--one-ux-radius--300) 0 0';
  return html`<div
    style=${styleMap({
    ...getColor(),
    'border-radius': borderRadius,
    position: 'absolute',
    bottom: selected || implicit ? '0' : '-1px',
    left: `${posLeft}px`,
    width: indicatorWidth,
    height: `${height}px`,
    margin: `0 ${indicatorMargin}`,
    transition: 'var(--one-ux-duration--500)',
    'transition-timing': 'var(--one-ux-easing--enter)',
    'pointer-events': 'none',
    'z-index': 1
  })}
  ></div>`;
}