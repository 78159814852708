import { html, nothing } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { type optionData } from '../types.js';
import type { OneUxColorPickerElement } from '../OneUxColorPickerElement.js';
export function ColorChip(this: OneUxColorPickerElement, option: optionData) {
  const size = 16;
  const borderWidth = 1;
  const borderColor = option.isPale || option.isKeyword ? 'var(--one-ux-palette--brand-neutral-300)' : 'transparent';
  const border = `${borderWidth}px ${option.isKeyword ? 'dashed' : 'solid'} ${borderColor}`;
  return html`<div
    class="color-chip"
    role="img"
    aria-label=${option.label || option.hexColor}
    style=${styleMap({
    width: `${size}px`,
    height: `${size}px`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border,
    backgroundColor: option.hexColor,
    color: 'var(--one-ux-palette--brand-neutral-300)',
    borderRadius: 'var(--one-ux-radius--200)',
    font: 'var(--one-ux-font--mono-600)'
  })}
  >
    ${option.value === 'automatic' ? 'A' : nothing}
  </div>`;
}