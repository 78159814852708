import { css } from 'lit';
export const style = css`
  :host {
    --one-ux-tab--explicit-selected-background-color: var(--one-ux-palette--gray-100);
  }

  :host([state-implicit]) {
    flex-basis: 100%;
  }

  .one-ux-element--root {
  }

  :host(:not(:is(:hover, :focus-within))) .errors-popout {
    visibility: hidden;
    pointer-events: none;
  }

  button[role='tab'] {
    appearance: none;
    all: unset;
    background: transparent;
    color: var(--one-ux-palette--brand-neutral-500);
    font: var(--one-ux-font--heading-200);
    border: var(--one-ux-border-width--100) solid transparent;
    border-left: var(--one-ux-border-width--100) solid var(--one-ux-palette--brand-neutral-200);
    border-right: var(--one-ux-border-width--100) solid var(--one-ux-palette--brand-neutral-200);
    padding: calc(var(--one-ux-spacing--400) / 2) var(--one-ux-spacing--500) var(--one-ux-spacing--300);
    height: 32px;
    box-sizing: border-box;
    border-radius: var(--one-ux-radius--400) var(--one-ux-radius--400) 0 0;
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-grow: 1;
    gap: var(--one-ux-spacing--100);
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: nowrap;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);

    &[aria-selected='true'],
    &:hover {
      color: var(--one-ux-palette--brand-core-500);
    }

    &[aria-selected='true'] {
      background: var(--one-ux-tab--explicit-selected-background-color);
      box-shadow: var(--one-ux-shadow--drop-200);
      border: 1px solid transparent;
    }

    &[aria-disabled='true'] {
      color: var(--one-ux-palette--gray-400);

      cursor: default;

      &:active {
        background: transparent;
      }
    }

    &:active {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);

      &[aria-selected='true'] {
        background: var(--one-ux-tab--explicit-selected-background-color);
      }
    }

    .adornments {
      width: 0;
      height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--one-ux-spacing--100);
      position: absolute;
      top: 3px;
      right: 3px;
      z-index: 1;
    }

    /**
     * Implicit
     */
    &.is-implicit {
      background: transparent;
      box-shadow: none;
      border: none;
      border-radius: 0;

      &[aria-selected='true'] {
        color: var(--one-ux-palette--brand-core-500);
      }

      &[aria-disabled='true'] {
        color: var(--one-ux-palette--gray-400);

        &:active {
          border-radius: 0;
          background: transparent;
        }
      }

      &:active {
        background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);
        border-radius: var(--one-ux-radius--400);

        :host([state-keyboard-focus]) & {
          border-radius: 0;
        }

        &[aria-selected='true'] {
          background: transparent;
        }
      }
    }

    /**
     * Weights
     */
    &.is-weight-high {
      padding: 11px var(--one-ux-spacing--600);
      height: 40px;
    }

    &.is-weight-low {
      font: var(--one-ux-font--heading-100);
      padding: calc(var(--one-ux-spacing--400) / 2) var(--one-ux-spacing--400);
      height: 28px;

      &[aria-selected='true'],
      &:hover:not([aria-disabled='true']) {
        color: var(--one-ux-palette--brand-neutral-600);
      }
    }

    /**
     * Purposes
     */
    :host([purpose='ai']) & {
      .label {
        background: var(--one-ux-palette--ai-core-400);
        background: linear-gradient(
          to bottom,
          var(--one-ux-palette--ai-core-400) 0%,
          var(--one-ux-palette--ai-accent-400) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &.is-add-tab {
      color: var(--one-ux-palette--brand-neutral-500);
      border: var(--one-ux-border-width--100) dashed var(--one-ux-palette--brand-neutral-200);
      border-bottom: none;
      border-radius: var(--one-ux-radius--400) var(--one-ux-radius--400) 0 0;

      &.is-implicit {
        border-color: var(--one-ux-palette--brand-neutral-200);
      }

      &:hover {
        border-color: var(--one-ux-palette--brand-neutral-300);
        background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
        color: var(--one-ux-palette--brand-neutral-600);
      }

      &:is(:active, [aria-pressed='true']) {
        background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.24);
        color: var(--one-ux-palette--brand-neutral-600);
      }
    }

    /**
     * Keyboard focus
     */
    :host([state-keyboard-focus]) &:focus {
      background-color: var(--one-ux-palette--keyboard-focus-400) !important;
      color: var(--one-ux-palette--keyboard-focus-700) !important;
    }

    &.is-tablist-focused {
      background-color: var(--one-ux-palette--keyboard-focus-300) !important;
      color: var(--one-ux-palette--keyboard-focus-700) !important;
    }

    /**
     * Slotted
     */
    ::slotted([one-ux-element='one-ux-icon']) {
      font-size: var(--one-ux-size--icon-200);
    }

    .is-weight-low ::slotted([one-ux-element='one-ux-icon']) {
      font-size: var(--one-ux-size--icon-100);
    }
  }
`;