import { html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { styleMap } from 'lit/directives/style-map.js';
import { live } from 'lit/directives/live.js';
import { Focusable } from '../../mixins/Focusable.js';
import { Compact } from '../../mixins/Compact.js';
import { Disabled } from '../../mixins/Disabled.js';
import { FormAssociatedFactory } from '../../mixins/FormAssociated.js';
import { Label } from '../../mixins/Label.js';
import { Implicit } from '../../mixins/Implicit.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { ValueFactory } from '../../mixins/Value.js';
import { OneUxElement } from '../../OneUxElement.js';
import { log } from '../../utils/log.js';
import { Axis } from './fragments/Axis.js';
import { getLanguage } from './lang.js';
import { style } from './style.js';
import { step } from './types.js';
import { keyCodes } from '../../utils.js';
import { classMap } from 'lit/directives/class-map.js';
import { register as _registerElement } from "../one-ux-tooltip/register-element.js";
_registerElement("tooltip-f00ec3ff0498bd67b7073c8325ead1cf");
const Styled = StyledFactory(style);
const Purpose = PurposeFactory({
  purposes: ['default', 'main', 'caution', 'notice']
});
const BaseClass = Compact(Disabled(Focusable(Implicit(Label(Purpose(Styled(OneUxElement)))))));
type valueType = unknown[];
const Value = ValueFactory<valueType>({
  type: Array,
  // TODO: Should be removed in next major
  reflect: true
});
const FormAssociated = FormAssociatedFactory<valueType>();

/**
 * A slider supporting single or multiple thumbs, for selecting a single value or a range.
 */
export class OneUxSliderElement extends FormAssociated(Value(BaseClass)) {
  static get elementType() {
    return 'one-ux-slider';
  }

  /*
   * Toggles on a second thumb to select a range as value.
   */
  @property({
    type: Boolean,
    reflect: true
  })
  public accessor range = false;

  /*
   * The list of steps for the slider.
   * * text: Displayed text for option
   * * value: When listening on the input event it will be passed as argument
   * * tick: toggles display as tick on the axis
   */
  @property({
    type: Array
  })
  public accessor steps: step[] = [];
  #startIndex = 0;
  #endIndex = 0;
  #refs = {
    $fields: createRef(),
    $start: createRef<HTMLInputElement>(),
    $end: createRef<HTMLInputElement>()
  };
  #valueObject: step[] = [];
  protected willUpdate(): void {
    if (!this.steps?.length) {
      return;
    }
    const isDefinedValue = this.value?.length === (this.range ? 2 : 1);
    if (isDefinedValue) {
      // detect values outside possible value range
      if (this.#getStepValueIndex(this.value[0]) === -1) {
        this.value[0] = this.steps[this.#minIndex].value;
      }
      if (this.range) {
        if (this.#getStepValueIndex(this.value[1]) === -1) {
          this.value[1] = this.steps[this.#maxIndex].value;
        }
      }
    } else {
      this.value = this.range ? [this.steps[this.#minIndex].value, this.steps[this.#maxIndex].value] : [this.steps[this.#minIndex].value];
    }
    this.#startIndex = this.#stepIndex('start');
    this.#endIndex = this.#stepIndex('end');
    this.#valueObject = this.range ? [this.steps[this.#startIndex], this.steps[this.#endIndex]] : [this.steps[this.#startIndex]];
  }
  protected guardedRender() {
    if (!this.steps?.length) {
      log.error({
        title: '<one-ux-slider> Missing steps, not rendering.',
        details: this
      });
      return;
    }
    const language = getLanguage(this);
    const valueText = this.range ? this.#valueObject.map(({
      text
    }) => text).join(' – ') : this.#valueObject[0].text;
    const middlePoint = (this.#startIndex + (this.#endIndex - this.#startIndex) / 2) / (this.steps.length - 1);
    const endPoint = this.#endIndex / (this.steps.length - 1);
    return html`<div
      class="one-ux-element--root"
      role=${ifDefined(this.range ? 'group' : undefined)}
      aria-label=${ifDefined(this.range ? this.label : undefined)}
      style=${styleMap({
      '--progress-start-index': this.range ? this.#startIndex : this.#minIndex,
      '--progress-end-index': this.range ? this.#endIndex : this.#startIndex,
      '--min-index': this.#minIndex,
      '--max-index': this.#maxIndex,
      '--middle-point': middlePoint,
      '--end-point': endPoint
    })}
    >
      ${this.compact ? html`<tooltip-f00ec3ff0498bd67b7073c8325ead1cf placement="above" id="tooltip"> ${this.label}: ${valueText} </tooltip-f00ec3ff0498bd67b7073c8325ead1cf>` : html`
            <div class="summary">
              ${this.label}:
              <span> ${valueText} </span>
            </div>
          `}
      <div ${ref(this.#refs.$fields)} class="fields" one-ux-tooltip=${ifDefined(this.compact ? '#tooltip' : undefined)}>
        <input
          ${ref(this.#refs.$start)}
          class=${classMap({
      start: true,
      'prevent-dead-handle': this.#startIndex == this.steps.length - 1
    })}
          type="range"
          ?disabled=${this.disabled}
          min=${this.#minIndex}
          max=${this.#maxIndex}
          .value=${live(String(this.#startIndex))}
          aria-label=${this.range ? language.translations.start : this.label}
          aria-valuemax=${ifDefined(this.range ? String(this.steps[this.#endIndex].value) : undefined)}
          aria-valuenow=${String(this.#valueObject[0].value)}
          aria-valuetext=${this.#valueObject[0].text}
          @input=${this.#handleInput}
          @change=${this.#handleChange}
          @mousedown=${this.#handleMouseDown}
          @mouseup=${this.#handleMouseUp}
          @keydown=${(e: KeyboardEvent) => this.#handleKeydown(e)}
        />
        ${this.range ? html`<input
              ${ref(this.#refs.$end)}
              class="end"
              type="range"
              ?disabled=${this.disabled}
              min=${this.#minIndex}
              max=${this.#maxIndex}
              .value=${live(String(this.#endIndex))}
              aria-label=${language.translations.end}
              aria-valuemin=${String(this.steps[this.#startIndex].value)}
              aria-valuenow=${String(this.#valueObject[1].value)}
              aria-valuetext=${this.#valueObject[1].text}
              @input=${this.#handleInput}
              @change=${this.#handleChange}
              @mousedown=${this.#handleMouseDown}
              @mouseup=${this.#handleMouseUp}
              @keydown=${(e: KeyboardEvent) => this.#handleKeydown(e)}
            />` : nothing}
      </div>
      ${Axis(this.steps)}
      ${this.implicit ? nothing : html`<div class="ruler">
            <div class="min">${this.steps[this.#minIndex].text}</div>
            <div class="max">${this.steps[this.#maxIndex].text}</div>
          </div> `}
    </div>`;
  }
  #handleInput(event: InputEvent) {
    event.stopPropagation();
    const $slider = event.target as HTMLInputElement;
    const isStart = event.target === this.#refs.$start.value;
    const newValue = this.steps[Number($slider.value)].value;
    if (this.range) {
      if (this.#hasExceededSibling($slider)) {
        event.preventDefault();
        $slider.value = (isStart ? this.#startIndex : this.#endIndex).toString();
        return;
      }
      const [start, end] = this.value!;
      if (isStart) {
        this.value = [newValue, end];
      } else {
        this.value = [start, newValue];
      }
    } else {
      this.value = [newValue];
    }
    this.dispatchEvent(new Event('input'));
  }
  #handleChange(event: InputEvent) {
    event.stopPropagation();
    this.dispatchEvent(new Event('change'));
  }
  #handleMouseDown() {
    this.#refs.$fields.value!.classList.add('active');
  }
  #handleMouseUp() {
    this.#refs.$fields.value!.classList.remove('active');
  }
  #hasExceededSibling($target: HTMLInputElement) {
    const $slider = $target as HTMLInputElement;
    const currentIndex = Number($slider.value);
    if ($target === this.#refs.$start.value) {
      return currentIndex > this.#endIndex;
    } else {
      return currentIndex < this.#startIndex;
    }
  }
  get #minIndex() {
    return 0;
  }
  get #maxIndex() {
    return this.steps.length - 1;
  }
  #stepIndex(type: 'start' | 'end') {
    const index = type === 'end' ? 1 : 0;
    const stepIndex = this.#getStepValueIndex(this.value[index]);
    if (stepIndex === -1) {
      return type === 'start' ? 0 : this.steps.length - 1;
    }
    return stepIndex;
  }
  #handleKeydown(event: KeyboardEvent) {
    if (!this.range) {
      return;
    }
    const handled = () => {
      event.stopPropagation();
      event.preventDefault();
    };
    switch (event.code) {
      case keyCodes.HOME:
        {
          handled();
          const [start, end] = this.value!;
          if (event.target === this.#refs.$start.value) {
            this.value = [this.steps[this.#minIndex].value, end];
          } else {
            this.value = [start, start];
          }
        }
        break;
      case keyCodes.END:
        {
          handled();
          const [start, end] = this.value!;
          if (event.target === this.#refs.$start.value) {
            this.value = [end, end];
          } else {
            this.value = [start, this.steps[this.#maxIndex].value];
          }
        }
        break;
    }
  }
  #getStepValueIndex(value: unknown) {
    return this.steps.findIndex(x => x.value === value);
  }
}