export class MoveEvent extends Event {
  static get eventName() {
    return 'move';
  }
  public oldIndex: number;
  public newIndex: number;
  public insertionIndex: number;
  constructor(oldIndex: number, newIndex: number) {
    super(MoveEvent.eventName);
    this.oldIndex = oldIndex;
    this.newIndex = newIndex;
    this.insertionIndex = oldIndex < newIndex ? newIndex + 1 : newIndex;
  }
}