import { html } from 'lit';
import { register as _registerElement } from "../../../one-ux/elements/one-ux-dialog/register-element.js";
import { register as _registerElement2 } from "../../../one-ux/elements/one-ux-text/register-element.js";
import { register as _registerElement3 } from "../../../one-ux/elements/one-ux-button/register-element.js";
import { register as _registerElement4 } from "../../../one-ux/elements/one-ux-icon/register-element.js";
_registerElement4("icon-280208d3e9e69108739a4f745aa0bfba");
_registerElement3("button-b794cf22c048eba91f31df64c0a3f4ae");
_registerElement2("text-6e152e1c12333abe01484cb95c7d2852");
_registerElement("dialog-aa80cc59971fd089468c02f5e4069423");
export interface IPromptModalData {
  lang: 'sv' | 'en';
  type: 'alert' | 'confirm';
  header: string;
  content: string | HTMLElement;
  ok: string;
  okIcon: string;
  cancel?: string;
  cancelIcon?: string;
  open: boolean;
  danger?: boolean;
}
export function PromptModal(options: IPromptModalData & {
  onClose: (result: boolean) => void;
}) {
  const confirm = options.type === 'confirm';
  return html`<dialog-aa80cc59971fd089468c02f5e4069423
    id="__PDR_prompt_modal__"
    style="top: 20%; width: 95%; max-width: 480px; max-height: 60%;"
    @close=${() => options.onClose(false)}
  >
    <text-6e152e1c12333abe01484cb95c7d2852 slot="header" lang="${options.lang}">${options.header || html`&nbsp;`}</text-6e152e1c12333abe01484cb95c7d2852>
    <div slot="content">
      ${options.content instanceof HTMLElement ? options.content : html`<p style="white-space: pre-wrap">${options.content}</p>`}
    </div>
    ${!confirm ? null : html`<button-b794cf22c048eba91f31df64c0a3f4ae
          slot="footer-end"
          lang=${options.lang}
          label=${options.cancel!}
          @click=${() => {
    options.onClose(false);
  }}
        >
          ${ButtonContent(options.cancel!, options.cancelIcon)}
        </button-b794cf22c048eba91f31df64c0a3f4ae>`}
    <button-b794cf22c048eba91f31df64c0a3f4ae
      slot="footer-end"
      lang="${options.lang}"
      purpose=${confirm && options.danger ? 'caution' : 'main'}
      label=${options.ok}
      @click=${() => {
    options.onClose(true);
  }}
    >
      ${ButtonContent(options.ok, options.okIcon)}
    </button-b794cf22c048eba91f31df64c0a3f4ae>
  </dialog-aa80cc59971fd089468c02f5e4069423>`;
}
function ButtonContent(text: string, icon?: string | {
  sheet: string;
  icon: string;
}) {
  if (!icon) {
    return text;
  }
  if (typeof icon === 'string') {
    icon = {
      sheet: 'default',
      icon: icon
    };
  }
  return html`<icon-280208d3e9e69108739a4f745aa0bfba set="${icon!.sheet as any}" icon="${icon!.icon as any}" slot="start"></icon-280208d3e9e69108739a4f745aa0bfba>${text}`;
}