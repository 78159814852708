import { PDRModule } from '../PDRModule.js';
import { DefaultAvatarContext } from './DefaultAvatarContext.js';
import { AvatarUrlContextCacheRepository } from './cache/AvatarUrlContextCacheRepository.js';
import { FullNameContextCacheRepository } from './cache/FullNameContextCacheRepository.js';
import { StateContextCacheRepository } from './cache/StateContextCacheRepository.js';
export class AvatarModule extends PDRModule {
  public defaultAvatarContext = new DefaultAvatarContext();
  clearCache() {
    new AvatarUrlContextCacheRepository().clear();
    new FullNameContextCacheRepository().clear();
    new StateContextCacheRepository().clear();
  }
}