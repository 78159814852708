import { html } from 'lit';
import type { OneUxGadgetElement } from '../OneUxGadgetElement.js';
import { classMap } from 'lit/directives/class-map.js';
import { handleMouseMove } from '../handleMouseMove.js';
import { register as _registerElement } from "../../one-ux-scroll/register-element.js";
_registerElement("scroll-2606d0a1eea965da7cffb38d1207b78b");
export function GadgetContent(this: OneUxGadgetElement) {
  return html`
    <div
      class="header"
      @mousedown=${handleMouseMove.bind(this)}
      @dragstart=${(event: DragEvent) => event.preventDefault()}
    >
      <div class="header-title">
        <slot name="header-start"></slot>
        <div class="header-title-text">${this.label}</div>
      </div>

      <slot name="header-end"></slot>
    </div>

    <div class="content">
      <slot name="custom-content">
        <scroll-2606d0a1eea965da7cffb38d1207b78b width="max" height="max">
          <div class="content-inner">
            <div
              class="content-padding"
              class=${classMap({
    'content-inner-padding': true,
    'has-footer-below': this._slots.hasNamedSlot('footer')
  })}
            >
              <slot></slot>
            </div>
          </div>
        </scroll-2606d0a1eea965da7cffb38d1207b78b>
      </slot>
    </div>

    <div class="footer" ?hidden=${!this._slots.hasNamedSlot('footer')}>
      <slot name="footer"></slot>
    </div>
  `;
}