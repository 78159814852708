import { css } from 'lit';
export const style = css`
  :host {
    --one-ux-tabpanel-next--padding: var(--one-ux-spacing--500);

    width: 100%;
  }

  :host(:focus) {
    outline: none;
  }

  :host([state-active]) {
    position: relative;
    opacity: 1;
    visibility: visible;
  }

  :host(:not([state-active])) {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .one-ux-element--root {
    padding: var(--one-ux-tabpanel-next--padding);

    &.has-tabs-content {
      padding: 0;
    }

    :host([state-keyboard-focus]:focus) & {
      outline: var(--one-ux-border-width--500) solid var(--one-ux-palette--keyboard-focus-400);
      outline-offset: calc((var(--one-ux-border-width--500) * -1) - var(--one-ux-spacing--100));
      border-radius: var(--one-ux-radius--500);
    }
  }
`;