import { Version } from '../versions/Version.js';
import { Load as LoadAction } from '../actions/Load.js';
import { Prefetch as PrefetchAction } from '../actions/Prefetch.js';
import versionFactory from '../versions/Factory.js';
import { Install as InstallAction } from '../actions/Install.js';
import { log } from '../log.js';
import { Implementation } from './Implementation.js';
import { NullVersion } from '../versions/NullVersion.js';
let baseUrl = '';
const AUTO_LOAD_THRESHOLD = 100;
export type MicrofrontendConfig = {
  tag: string;
  version: string;
} & Partial<{
  packageName: string;
  url: string;
  prefetch: boolean;
  load: boolean | number;
  required: boolean;
}>;
export class Microfrontend {
  config: MicrofrontendConfig;
  tag: string;
  version: Version;
  packageName: string;
  url: string;
  prefetchAction?: PrefetchAction;
  loadAction?: LoadAction;
  installAction?: InstallAction;
  static setBaseUrl(url: string) {
    baseUrl = url;
  }
  constructor(config: MicrofrontendConfig) {
    this.validate(config);
    this.config = config;
    if (typeof this.config.prefetch === 'undefined') {
      this.config.prefetch = false;
    }
    this.tag = config.tag;
    this.version = config.version ? versionFactory.create(config.version) : new NullVersion();
    this.packageName = config.packageName || config.tag;
    this.url = config.url || '';
  }
  patchConfig(mfe: Microfrontend) {
    const config = mfe.config;
    if (config.prefetch && !this.config.prefetch) {
      this.config.prefetch = config.prefetch;
    }
    if (config.load && !this.config.load) {
      this.config.load = config.load;
    }
  }
  validate(config: MicrofrontendConfig) {
    this.validationError(!config.tag, 'tag is a required property');
    this.validationError(!!(config.version && config.url), 'the version and url properties are mutually exclusive');
    this.validationError(!config.version && !config.url, 'either version or url is required');
    this.validationError(!!(!config.url && config.version && !Version.valid(config.version)), 'version string is not valid.');
  }
  validationError(failed: boolean, reason: string) {
    if (failed) {
      throw new Error(`Invalid microfrontend configuration: ${reason}!`);
    }
  }
  compatible(microfrontend: Microfrontend) {
    if (this.tag !== microfrontend.tag || this.packageName !== microfrontend.packageName) {
      return false;
    }
    if (this.url || microfrontend.url) {
      return this.url === microfrontend.url;
    }
    return this.version.fulfills(microfrontend.version);
  }
  getUrl() {
    if (this.url) {
      return this.url;
    }
    if (!baseUrl) {
      throw new Error('Base URL must be set before generating a microfrontend URL.');
    }
    return baseUrl.replace('{package}', this.packageName).replace('{version}', this.version.toString());
  }
  prefetchOrAutoLoad() {
    if (this.config.load && !this.loading) {
      const delay = typeof this.config.load === 'number' ? +this.config.load : undefined;
      window.PDR.utils.defer(() => window.PDR.mfe.load({
        ...this.config,
        required: false
      }), delay);
    }
    const isNotFastAutoLoad = !this.config.load || Number(this.config.load) >= AUTO_LOAD_THRESHOLD;
    const shouldPrefetch = this.config.prefetch && isNotFastAutoLoad && !this.loading && !this.prefetched;
    if (shouldPrefetch) {
      this.prefetch();
    }
  }
  prefetch() {
    if (this.prefetched || this.loading || this.installed) {
      return;
    }
    log.debug(`Prefetching ${this.tag}.`);
    this.prefetchAction = this.prefetchAction || new PrefetchAction(this.tag, this.getUrl());
    this.prefetchAction.execute();
  }
  async load() {
    if (this.installed || this.loading) {
      return;
    }
    log.debug(`Loading ${this.tag}`);
    this.loadAction = this.loadAction || new LoadAction(this.tag, this.getUrl());
    if (this.prefetchAction) {
      this.prefetchAction.abort();
    }
    await this.loadAction.execute();
  }
  install(implementation: Implementation) {
    if (this.installed) {
      return;
    }
    log.debug(`Installing ${this.tag}`);
    this.installAction = this.installAction || new InstallAction(implementation);
    return this.installAction.execute();
  }
  get installed() {
    return !!this.installAction && this.installAction.completed;
  }
  get implementation() {
    return this.installAction ? this.installAction.implementation : {
      version: ''
    };
  }
  get autoLoad() {
    return this.config.load || false;
  }
  get loading() {
    return !!this.installed || !!(this.loadAction && this.loadAction.started);
  }
  get loaded() {
    return !!this.installed || !!(this.loadAction && this.loadAction.completed);
  }
  get prefetched() {
    return !!this.installed || !!(this.prefetchAction && this.prefetchAction.started);
  }
  asObject() {
    const result = {
      tag: this.tag
    } as MicrofrontendConfig;
    if (typeof this.config.required === 'boolean') {
      result.required = this.config.required;
    }
    if (typeof this.config.load !== 'undefined') {
      result.load = this.config.load;
    }
    if (typeof this.config.prefetch === 'boolean') {
      result.prefetch = this.config.prefetch;
    }
    if (this.packageName !== this.tag) {
      result.packageName = this.packageName;
    }
    if (this.version) {
      result.version = this.version.toString();
    } else {
      result.url = this.url;
    }
    return result;
  }
  toString() {
    return JSON.stringify(this.asObject(), null, 2);
  }
}