import { timingFunction } from '../../utils/animation-utils.js';
import oneUxEasing from '../../generated/json/easing/easing.json';
export const openAnimation = (height: number) => [{
  height: 0,
  opacity: 0,
  easing: timingFunction(oneUxEasing.enter)
}, {
  height: height + 'px',
  opacity: 0,
  easing: timingFunction(oneUxEasing.default)
}, {
  height: height + 'px',
  opacity: 1
}];
export const closeAnimation = (height: number) => [{
  height: height + 'px',
  opacity: 1,
  visibility: 'visible',
  easing: timingFunction(oneUxEasing.default)
}, {
  height: height + 'px',
  opacity: 0,
  visibility: 'visible',
  easing: timingFunction(oneUxEasing.exit)
}, {
  height: 0,
  opacity: 0,
  visibility: 'hidden'
}];