import { LanguageSets } from '../../utils/getLangCode.js';
export type LanguageSet = {
  loading: string;
};
export const strings: LanguageSets<LanguageSet> = {
  en: {
    loading: 'Loading'
  },
  sv: {
    loading: 'Laddar'
  },
  nb: {
    loading: 'Laster'
  },
  fi: {
    loading: 'Ladataan'
  },
  da: {
    loading: 'Indlæser'
  }
};