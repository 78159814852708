import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { Focusable } from '../../mixins/Focusable.js';
import { Implicit } from '../../mixins/Implicit.js';
import { Compact } from '../../mixins/Compact.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { OneUxElement } from '../../OneUxElement.js';
import { IValue, ValueFactory } from '../../mixins/Value.js';
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../../mixins/Validated.js';
import { InternalValueChangedEvent } from '../../events/internal/InternalValueChangedEvent.js';
import { FormAssociated } from '../../mixins/FormAssociated.js';
import { Required, type IRequired } from '../../mixins/Required.js';
import { Label } from '../../mixins/Label.js';
import { FieldSetProps } from '../one-ux-field-set/FieldSetProps.js';
import { Errors } from '../../mixins/Errors.js';
import { Disabled } from '../../mixins/Disabled.js';
import { register as _registerElement } from "../one-ux-field-set/register-element.js";
import { register as _registerElement2 } from "../one-ux-label/register-element.js";
import { register as _registerElement3 } from "../one-ux-checkbox/register-element.js";
_registerElement3("checkbox-20d8f7366f24b9bff7a70b5445538efc");
_registerElement2("label-7e8a0aae97d84561ab5295d0d8d5e770");
_registerElement("field-set-dd6c9796ac175f318a429bad3bba550c");
type CheckboxOption = {
  value: unknown;
  text: string;
  disabled: boolean;
};
type valueType = unknown[];
const Value = ValueFactory<valueType>({
  type: Array,
  // TODO: Should be removed in next major
  reflect: true
});
const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult;
    }
    const {
      fieldYouHaveToMakeChoice
    } = getFormValidationLanguage(this);
    const hasValue = !!this.value?.length;
    return {
      valid: hasValue,
      flags: {
        valueMissing: !hasValue
      },
      errors: [fieldYouHaveToMakeChoice]
    };
  }
});
const Purpose = PurposeFactory({
  purposes: ['default', 'main', 'caution', 'notice']
});
const BaseClass = Errors(Label(Disabled(Required(FieldSetProps(FormAssociated(Validated(Value(Focusable(Compact(Implicit(Purpose(OneUxElement))))))))))));

/**
 * A group of check boxes, where several can be checked.
 */
export class OneUxCheckboxGroupElement extends BaseClass {
  static get elementType() {
    return 'one-ux-checkbox-group';
  }
  constructor() {
    super();
    this.value = [];
  }
  /*
   * The list of options for the checkbox group.
   * * text: Displayed text for option
   * * value: When listening on the option event it will be passed as argument
   * * disabled: If the option is disabled
   */
  @property({
    type: Array
  })
  public accessor options: CheckboxOption[] = [];

  /*
   * Placement of the checkboxes against their texts.
   */
  @property({
    attribute: 'checkbox-position',
    type: String
  })
  public accessor checkboxPosition = 'before-text' as 'before-text' | 'after-text';
  render() {
    return html`
      <field-set-dd6c9796ac175f318a429bad3bba550c
        class="one-ux-element--root"
        .delegateAria=${{
      role: 'listbox',
      'aria-orientation': 'vertical',
      'aria-multiselectable': true
    }}
        .disabled=${this.disabled}
        .implicit=${this.implicit}
        .compact=${this.compact}
        .label=${this.label}
        .columns=${this.columns}
        .errors=${this.errors}
        .required=${this.required}
      >
        ${this.options.map(({
      disabled,
      value,
      text
    }, index) => html`
            <label-7e8a0aae97d84561ab5295d0d8d5e770 label=${text}>
              <checkbox-20d8f7366f24b9bff7a70b5445538efc
                tabindex=${index == 0 && !this.hasKeyboardFocus ? 0 : -1}
                slot=${this.checkboxPosition === 'before-text' ? 'start' : 'end'}
                .disabled=${this.disabled || disabled}
                .checked=${Array.isArray(this.value) ? this.value.includes(value) : false}
                .delegateAria=${{
      role: 'option'
    }}
                .implicit=${this.implicit}
                .purpose=${this.purpose}
                pdr-test-hook="one-ux-checkbox-group-item-${String(value)}"
                @change=${() => this.#handleSelect(value)}
              >
              </checkbox-20d8f7366f24b9bff7a70b5445538efc>
            </label-7e8a0aae97d84561ab5295d0d8d5e770>
          `)}
      </field-set-dd6c9796ac175f318a429bad3bba550c>
    `;
  }
  #select(value: unknown) {
    if (this.value?.includes(value)) {
      this._applyUserValue(this.value.filter((v: unknown) => v !== value));
    } else {
      this._applyUserValue([value, ...(this.value || [])]);
    }
  }
  #handleSelect(value: unknown) {
    this.#select(value);
    this.dispatchEvent(new Event('input'));
    this.dispatchEvent(new Event('change'));
    this.dispatchEvent(new InternalValueChangedEvent());
  }
}