import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
export declare class IImplicit {
  implicit: boolean;
}
export const Implicit = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  // TODO: remove any
  if ((SuperClass as any).__one_ux_mixin_explicit__) {
    throw new Error(`Can't apply Implicit mixin if Explicit mixin is already applied. `);
  }
  class ImplicitClass extends SuperClass {
    static get __one_ux_mixin_implicit__() {
      return true as const;
    }

    /**
     * Describes the visual relationship of the component to its surroundings.
     * When a component is set to be `implicit` the functionality of the component is dependent on its context in the DOM.
     *
     * Adding this flag can drastically reduce the usability of the component.
     * If you set it you are required to make sure that the context is considering this component.
     */
    @property({
      type: Boolean,
      reflect: true
    })
    public accessor implicit = false;
  }
  return ImplicitClass as Constructor<IImplicit> & TSuperClass;
};