import { WildcardVersion } from './WildcardVersion.js';
import { MajorVersion } from './MajorVersion.js';
import { MinorVersion } from './MinorVersion.js';
import { Version } from '../../Version.js';
import { NumberedVersion } from '../NumberedVersion.js';
const wildcard = '~';
export class PatchVersion extends WildcardVersion {
  fulfills(version: Version): boolean {
    if (version instanceof MajorVersion) {
      if (this.major === version.major) {
        if (this.minor === version.minor) {
          return this.patch >= version.patch;
        }
        return this.minor > version.minor;
      }
      return this.major >= version.major;
    } else if (version instanceof MinorVersion) {
      if (this.major !== version.major) {
        return false;
      }
      if (this.minor === version.minor) {
        return this.patch >= version.patch;
      }
      return this.minor > version.minor;
    } else if (version instanceof PatchVersion) {
      return this.major === version.major && this.minor === version.minor && this.patch >= version.patch;
    }
    return false;
  }
  includes(version: Version) {
    if (version.type !== 'numbered') {
      return false;
    }
    const numberedVersion = version as NumberedVersion;
    return this.major === numberedVersion.major && this.minor === numberedVersion.minor && this.patch <= numberedVersion.patch;
  }
  static matches(versionString: string) {
    return versionString[0] === wildcard;
  }
}