import { PDRModule } from '../PDRModule.js';
import { getLanguage } from './lang.js';
import { render } from 'lit';
import { IPromptModalData, PromptModal } from './components/PromptModal.js';
import { CallbackQueue } from '../utils/CallbackQueue.js';
import type { OneUxDialogElement } from '../../one-ux/elements/one-ux-dialog/OneUxDialogElement.js';
type commonOptions = {
  header?: string;
  ok?: string;
  okIcon?: string;
};
type message = string | HTMLElement;
type alertOptions = commonOptions & {
  message: message;
};
type confirmOptions = commonOptions & {
  danger?: boolean;
  message?: message;
  cancel?: string;
  cancelIcon?: string;
};
export class PromptModule extends PDRModule {
  #prompts = new CallbackQueue();
  async alert(messageOrOptions: string | alertOptions) {
    const {
      translations: lang,
      lang: langCode
    } = await this.#getLang();
    const options = this.#normalizeOptions(messageOrOptions);
    return this.#requestModal({
      type: 'alert',
      lang: langCode,
      open: true,
      header: options.header || lang.alert.header,
      content: this.#getContent(options),
      ok: options.ok || lang.alert.ok,
      okIcon: options.okIcon || ''
    }) as Promise<void>;
  }
  async confirm(messageOrOptions?: string | confirmOptions) {
    const {
      translations: lang,
      lang: langCode
    } = await this.#getLang();
    const options = this.#normalizeOptions(messageOrOptions, true);
    return this.#requestModal({
      type: 'confirm',
      lang: langCode,
      open: true,
      header: options?.header || lang.confirm.header,
      content: this.#getContent(options, lang.confirm.message),
      ok: options?.ok || lang.confirm.ok,
      okIcon: options?.okIcon || '',
      cancel: options?.cancel || lang.confirm.cancel,
      cancelIcon: options?.cancelIcon || '',
      danger: options?.danger || false
    }) as Promise<boolean>;
  }
  async #getLang() {
    const {
      utils
    } = await this._started;
    return getLanguage(await utils.lang());
  }
  #emptyMessageError = 'Attempted to show an alert without a message.';
  #normalizeOptions<T extends alertOptions | confirmOptions>(options?: string | T, emptyOptionsAllowed = false): T {
    if (!options) {
      if (emptyOptionsAllowed) {
        return {} as T;
      }
      throw new Error(this.#emptyMessageError);
    }
    if (typeof options === 'string') {
      options = {
        message: options
      } as T;
    }
    return options;
  }
  #getContent(options?: string | {
    message?: string | HTMLElement;
  }, fallback?: string): string | HTMLElement {
    const fallbackOrFail = () => {
      if (fallback) {
        return fallback;
      }
      throw new Error(this.#emptyMessageError);
    };
    if (!options) {
      return fallbackOrFail();
    }
    if (typeof options === 'string') {
      return options;
    }
    if (!options.message) {
      return fallbackOrFail();
    }
    return options.message;
  }
  async #requestModal(modalData: IPromptModalData) {
    function renderModal(modalData: IPromptModalData, resolve: (value?: boolean) => void) {
      const rootId = '__PDR_prompt_root__';
      let $root = document.getElementById(rootId);
      if (!$root) {
        $root = document.createElement('div');
        $root.id = rootId;
        document.body.appendChild($root);
      }
      render(PromptModal({
        ...modalData,
        onClose: (result: boolean) => {
          const $dialog = $root!.querySelector('#__PDR_prompt_modal__') as OneUxDialogElement;
          $dialog.close();
          if (modalData.type === 'alert') {
            resolve();
          } else {
            resolve(result);
          }
        }
      }), $root);
      requestAnimationFrame(() => {
        const $dialog = $root!.querySelector('#__PDR_prompt_modal__') as OneUxDialogElement;
        $dialog.open();
      });
    }
    return this.#prompts.enqueue(() => {
      return new Promise<unknown>(resolve => {
        renderModal(modalData, resolve);
      });
    });
  }
}