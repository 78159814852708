export class CallbackQueue {
  #busy = false;
  #queue = [] as {
    resolve: (value: unknown) => void;
    reject: (error: unknown) => void;
    callback: () => Promise<unknown>;
  }[];
  enqueue(callback: () => Promise<unknown>): Promise<unknown> {
    return new Promise<unknown>((resolve, reject) => {
      this.#queue.push({
        resolve,
        reject,
        callback
      });
      this.#flush();
    });
  }
  async #flush() {
    if (this.#busy) {
      return;
    }
    this.#busy = true;
    while (this.#queue.length) {
      const {
        resolve,
        reject,
        callback
      } = this.#queue.shift()!;
      try {
        const result = await callback();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    }
    this.#busy = false;
  }
}