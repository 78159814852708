import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
import { PropertyValues } from 'lit';
import { InternalElementStateChangedEvent } from '../events/internal/InternalElementStateChangedEvent.js';
import type { IDisabled } from './Disabled.js';
export declare class IReadonly {
  readonly: boolean;
}
export const Readonly = <TSuperClass extends Constructor<OneUxElement & Partial<IDisabled>>,>(SuperClass: TSuperClass) => {
  // TODO: remove any
  if (!(SuperClass as any).__one_ux_mixin_focusable__) {
    throw new Error(`Can only apply Readonly mixin if Focusable mixin is already applied. `);
  }
  class ReadonlyClass extends SuperClass {
    #readonly = false;
    @property({
      type: Boolean,
      reflect: true
    })
    public set readonly(value) {
      this.#readonly = value;
    }
    public get readonly() {
      return this.#computeReadonly(!!this.disabled);
    }
    protected update(changed: PropertyValues<this & IDisabled>): void {
      if (changed.has('disabled') && !changed.has('readonly')) {
        const wasDisabled = changed.get('disabled');
        const oldValue = this.#computeReadonly(!!wasDisabled);
        this.requestUpdate('readonly', oldValue);
      }
      super.update(changed);
      if (changed.has('readonly')) {
        this.dispatchEvent(new InternalElementStateChangedEvent({
          property: 'readonly',
          value: this.readonly
        }));
      }
    }
    #computeReadonly(disabled: boolean) {
      return disabled ? false : this.#readonly;
    }
  }
  return ReadonlyClass as Constructor<IReadonly> & TSuperClass;
};