import type { OneUxGadgetElement } from './OneUxGadgetElement.js';
import { getLangCode, langCode, LanguageSets } from '../../utils/getLangCode.js';
export type language = {
  locale: langCode;
  translations: translationSet;
};
export type translationSet = {
  gadget: string;
};
export const languages: LanguageSets<translationSet> = {
  en: {
    gadget: 'Gadget'
  },
  sv: {
    gadget: 'Gadget'
  },
  nb: {
    gadget: 'Gadget'
  },
  fi: {
    gadget: 'Gadget'
  },
  da: {
    gadget: 'Gadget'
  }
};
export const getLanguage = (element: OneUxGadgetElement): language => {
  const locale = getLangCode(element);
  const translations = languages[locale];
  return {
    locale,
    translations
  };
};