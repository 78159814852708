import { LanguageSets, getLangCode } from '../../utils/getLangCode.js';
export type LanguageSet = {
  selected: string;
};
const languages: LanguageSets<LanguageSet> = {
  en: {
    selected: '$0 selected'
  },
  sv: {
    selected: '$0 valda'
  },
  nb: {
    selected: '$0 valgt'
  },
  fi: {
    selected: '$0 valittu'
  },
  da: {
    selected: '$0 valgt'
  }
};
export const getLanguage = (element: Element): {
  translations: LanguageSet;
  lang?: string;
} => {
  const closestLang = getLangCode(element);
  const translations = languages[closestLang];
  return {
    translations,
    lang: closestLang
  };
};