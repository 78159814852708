import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
export declare class IChecked {
  checked: boolean;
  initialChecked: boolean;
}
export const Checked = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  class CheckedClass extends SuperClass {
    static get __one_ux_mixin_checked__() {
      return true as const;
    }
    #allowInitial = true;
    constructor(...args: any[]) {
      super(...args);
      requestAnimationFrame(() => {
        this.#allowInitial = false;
      });
    }

    /**
     * The current checked state.
     */
    @property({
      type: Boolean
    })
    public set checked(value) {
      if (this.#allowInitial && typeof this.initialChecked === 'undefined') {
        this.initialChecked = value;
      }
      this.#checked = value;
    }
    public get checked() {
      return this.#checked;
    }
    initialChecked?: boolean;
    #checked = false;
  }
  return CheckedClass as Constructor<IChecked> & TSuperClass;
};