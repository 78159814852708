import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
export declare class ICompact {
  compact: boolean;
}
export const Compact = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  class CompactClass extends SuperClass {
    /**
     * Render the component in a compact mode.
     * This could make other properties on the component required.
     */
    @property({
      type: Boolean,
      reflect: true
    })
    public accessor compact = false;
  }
  return CompactClass as Constructor<ICompact> & TSuperClass;
};