import { PDRLibrary } from './PDRLibrary.js';
import { PDRConfig, PDRWebviewConfig } from './types.js';
const events = {
  started: [] as ((library: PDRLibrary) => void)[],
  configured: [] as ((config: PDRConfig | PDRWebviewConfig) => void)[]
};
type SettingsFactory<T> = (config: PDRConfig | PDRWebviewConfig) => T | void;
export class PDRModule<TSettings = undefined> {
  #settingsFactory;
  #resolveStarted: (library: PDRLibrary | PromiseLike<PDRLibrary>) => void;
  protected _started = new Promise<PDRLibrary>(resolve => {
    this.#resolveStarted = resolve;
  });
  #resolveConfiguration: (config: TSettings) => void;
  protected _configured = new Promise<TSettings>(resolve => {
    this.#resolveConfiguration = resolve;
  });
  protected _initialized = Promise.all([this._started, this._configured]);
  constructor(settingsFactory: SettingsFactory<TSettings> = () => {}) {
    events['started'].push(this.#onStarted.bind(this));
    events['configured'].push(this.#onConfigured.bind(this));
    this.#settingsFactory = settingsFactory;
  }
  static notify(event: 'started', data: PDRLibrary): void;
  static notify(event: 'configured', data: PDRConfig | PDRWebviewConfig): void;
  static notify(event: keyof typeof events, data: never) {
    if (!Object.prototype.hasOwnProperty.call(events, event)) {
      throw new Error(`Unknown PDR-event "${event}"`);
    }
    const callbacks = [...events[event]];
    for (let i = 0; i < callbacks.length; i++) {
      callbacks[i](data);
    }
  }
  #onStarted(library: PDRLibrary) {
    this.#resolveStarted(library);
  }
  #onConfigured(config: PDRConfig | PDRWebviewConfig) {
    const settings = this.#settingsFactory(config);
    if (settings) {
      this.#resolveConfiguration(settings);
    }
  }
}