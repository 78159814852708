export class BeforeEnterEvent extends Event {
  static get eventName() {
    return 'beforeenter';
  }
  constructor() {
    super(BeforeEnterEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    });
  }
}
export class EnterEvent extends Event {
  static get eventName() {
    return 'enter';
  }
  constructor() {
    super(EnterEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    });
  }
}
export class BeforeLeaveEvent extends Event {
  static get eventName() {
    return 'beforeleave';
  }
  constructor() {
    super(BeforeLeaveEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    });
  }
}
export class LeaveEvent extends Event {
  static get eventName() {
    return 'leave';
  }
  constructor() {
    super(LeaveEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    });
  }
}