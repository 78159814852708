import { ScrollDirection } from '../types.js';
const epsilon = 8;
type ScrollPosition = 'left' | 'center' | 'right';
export const scrollToLeft = ($target: HTMLElement) => {
  const $tabs = $target.closest('.tabs') as HTMLElement;
  const $tablist = $tabs.querySelector('[role="tablist"]') as HTMLElement;
  const scrollIndex = getTargetIndexLeft($tablist);
  setStateScroll($tabs, 'left');
  setScrolledTo($tabs, scrollIndex);
  const $tab = getTabElements($tabs)[scrollIndex];
  $tablist.scrollTo({
    left: getRelativeScrollLeftToTab($tab, 'left'),
    behavior: 'smooth'
  });
};
export const scrollToCurrent = ($target: HTMLElement) => {
  const $tabs = $target.closest('.tabs') as HTMLElement;
  const $tablist = $tabs.querySelector('[role="tablist"]') as HTMLElement;
  const scrollIndex = getTargetIndexCurrent($target.closest('[role="tab"]') as HTMLElement);
  setStateScroll($tabs, 'current');
  setScrolledTo($tabs, scrollIndex);
  const $tab = getTabElements($tabs)[scrollIndex];
  $tablist.scrollTo({
    left: getRelativeScrollLeftToTab($tab, 'center'),
    behavior: 'smooth'
  });
};
export const scrollToRight = ($target: HTMLElement) => {
  const $tabs = $target.closest('.tabs') as HTMLElement;
  const $tablist = $tabs.querySelector('[role="tablist"]') as HTMLElement;
  const scrollIndex = getTargetIndexRight($tablist);
  setStateScroll($tabs, 'right');
  setScrolledTo($tabs, scrollIndex);
  const $tab = getTabElements($tabs)[scrollIndex];
  $tablist.scrollTo({
    left: getRelativeScrollLeftToTab($tab, 'right'),
    behavior: 'smooth'
  });
};
const getCurrentScrollIndex = ($tablist: HTMLElement) => {
  const $scrolledTo = $tablist.querySelector('[scrolled-to]');
  const $tabElements = Array.from($tablist.querySelectorAll('[role="tab"]'));
  const scrollLeft = Math.ceil($tablist.scrollLeft);
  const maxScrollLeft = $tablist.scrollWidth - $tablist.clientWidth;
  let currentIndex = $scrolledTo ? Math.max(0, $tabElements.indexOf($scrolledTo)) : 0;
  if (scrollLeft === 0) {
    currentIndex = 0;
  } else if (scrollLeft === maxScrollLeft) {
    currentIndex = $tabElements.length - 1;
  }
  return currentIndex;
};
const getTargetIndexRight = ($tablist: HTMLElement) => {
  const currentIndex = getCurrentScrollIndex($tablist);
  const scrollLeft = Math.ceil($tablist.scrollLeft);
  const $tabElements = $tablist.children;
  for (let i = currentIndex + 1; i < $tabElements.length; i++) {
    if (getRelativeScrollLeftToTab($tabElements[i], 'right') > scrollLeft + epsilon) {
      return i;
    }
  }
  return currentIndex;
};
const getTargetIndexLeft = ($tablist: HTMLElement) => {
  const currentIndex = getCurrentScrollIndex($tablist);
  const scrollLeft = Math.ceil($tablist.scrollLeft);
  const $tabElements = $tablist.children;
  for (let i = currentIndex - 1; i >= 0; i--) {
    if (getRelativeScrollLeftToTab($tabElements[i], 'left') < scrollLeft - epsilon) {
      return i;
    }
  }
  return currentIndex;
};
const getTargetIndexCurrent = ($tab: HTMLElement) => Array.from($tab.parentNode?.children ?? []).indexOf($tab);
const getRelativeScrollLeftToTab = ($tab: Element, position: ScrollPosition): number => {
  const listRect = $tab.parentElement!.getBoundingClientRect();
  const tabRect = $tab.getBoundingClientRect();
  const scrollLeft = Math.ceil($tab.parentElement!.scrollLeft);
  switch (position) {
    case 'left':
      return Math.floor(scrollLeft + tabRect.left - listRect.left);
    case 'center':
      return getRelativeScrollLeftToTab($tab, 'left') - listRect.width / 2 + tabRect.width / 2;
    case 'right':
      return Math.ceil(scrollLeft + tabRect.right - listRect.left - listRect.width);
  }
};
const setScrolledTo = ($tabs: HTMLElement, scrollIndex: number) => {
  $tabs.querySelector('[scrolled-to]')?.removeAttribute('scrolled-to');
  getTabElements($tabs)[scrollIndex].toggleAttribute('scrolled-to', true);
};
const setStateScroll = ($tabs: HTMLElement, direction: ScrollDirection) => {
  for (const className of $tabs.className.split(' ').filter(x => x.startsWith('state-scroll-'))) {
    $tabs.classList.remove(className);
  }
  if (direction !== 'current') {
    $tabs.classList.add(`state-scroll-${direction}`);
  }
};
const getTabElements = ($tabs: HTMLElement) => Array.from($tabs.querySelectorAll('[role="tab"]'));