import { html, PropertyValues } from 'lit';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { property, state } from 'lit/decorators.js';
import { InternalElementStateChangedEvent } from '../../events/internal/InternalElementStateChangedEvent.js';
import { Focusable } from '../../mixins/Focusable.js';
import { register as _registerElement } from "../one-ux-widget-button/register-element.js";
import { register as _registerElement2 } from "../one-ux-icon/register-element.js";
_registerElement2("icon-dd75a506032da8f1f1e32c4b7d204908");
_registerElement("widget-button-5276f870e17288847846485f6014a752");
const Styled = StyledFactory(style);
const BaseClass = Focusable(Styled(OneUxElement));
export class OneUxCopyElement extends BaseClass {
  static get elementType() {
    return 'one-ux-copy';
  }
  @property({
    type: String
  })
  accessor value: string = '';
  @property({
    type: Boolean
  })
  accessor secret: boolean = false;

  /** @internal */
  @state()
  accessor _reveal!: boolean;
  constructor() {
    super();
    this.width = 'max';
  }

  // TODO: translations
  // TODO: visual indication or some feedback when copying via button
  protected render() {
    return html`
      <div class="one-ux-element--root">
        <span tabindex="0" @focus=${this.#select} @blur=${this.#deselect} @copy=${this.#copy} @click=${this.#reveal}>
          ${this.secret ? html`<span .hidden=${!this.secret || this._reveal}>${'\u2022'.repeat(this.value.length)}</span>` : null}
          <span .hidden=${this.secret && !this._reveal}>${this.value}</span>
        </span>
        <widget-button-5276f870e17288847846485f6014a752 one-ux-tooltip="Copy" icon="copy" @click=${this.#copy}></widget-button-5276f870e17288847846485f6014a752>
      </div>
    `;
  }
  protected async firstUpdated(_changedProperties: PropertyValues<this>) {
    this.dispatchEvent(new InternalElementStateChangedEvent({
      property: 'readonly',
      value: true
    }));
  }
  #reveal(event: MouseEvent) {
    this._reveal = true;
    this.#select(event);
  }
  #select(event: FocusEvent) {
    const $target = event.target as HTMLElement;
    const $source = $target.parentElement?.lastElementChild;
    if (!$source) return;
    const range = document.createRange();
    range.selectNodeContents($source as Node);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  }
  #deselect() {
    window.getSelection()?.removeAllRanges();
    this._reveal = false;
  }
  async #copy() {
    try {
      await navigator.clipboard.writeText(this.value);
    } catch {
      // Threw NotAllowedError – We weren't allowed to copy the text
    }
  }
}