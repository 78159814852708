import { ReactiveController } from 'lit';
import type { OneUxElement } from '../OneUxElement.js';
import { log } from '../utils/log.js';
import { TABBABLE_TARGETS_SELECTOR } from '../utils/focusable.js';
type slotConfig = {
  defaultSlot: boolean;
  slots: string[];
  allowDynamicSlots: boolean;
};
export class SlotController implements ReactiveController {
  #hostElement: OneUxElement;
  #defaultSlot: boolean;
  #namedSlots: string[];
  #allowDynamicSlots: boolean;
  constructor($host: OneUxElement, config?: Partial<slotConfig>) {
    this.#hostElement = $host;
    this.#hostElement.addController(this);
    this.#namedSlots = config?.slots ?? [];
    this.#defaultSlot = config?.defaultSlot ?? false;
    this.#allowDynamicSlots = config?.allowDynamicSlots ?? false;
  }
  getNamedSlotElements<TElement extends HTMLElement = HTMLElement>(slotName: string): TElement[] {
    if (!this.#namedSlots.includes(slotName) && !this.#allowDynamicSlots) {
      log.debug(`Slot "${slotName}" is not registered on SlotController for "${this.#hostElement.localName}"`);
      return [];
    }
    return Array.from(this.#hostElement.querySelectorAll<TElement>(`:scope > [slot=${slotName}]`));
  }
  getDefaultSlotElements<TElement extends HTMLElement = HTMLElement>(): TElement[] {
    if (!this.#defaultSlot) {
      log.debug(`Default slot is not registered on SlotController for "${this.#hostElement.localName}"`);
      return [];
    }
    return Array.from(this.#hostElement.querySelectorAll<TElement>(`:scope > :not([slot])`));
  }
  hasDefaultSlotTextContent() {
    const $nodes = Array.from<Node>(this.#hostElement.childNodes);
    return $nodes.some(this.#hasTextContent);
  }
  hasDefaultSlotFocusableContent() {
    const hasTabbableContentPredicate = ($el: Element): boolean => $el.matches(TABBABLE_TARGETS_SELECTOR) || !!$el.querySelector(TABBABLE_TARGETS_SELECTOR);
    return Array.from(this.#hostElement.querySelectorAll(':scope > :not([slot])')).some(hasTabbableContentPredicate) ?? false;
  }
  hasDefaultSlot(selector = '') {
    return this.#countDefaultSlot(selector) > 0;
  }
  hasSingleDefaultSlot(selector = '') {
    return this.#countDefaultSlot(selector) === 1;
  }
  hasNamedSlot(name: string, selector = '') {
    return this.#countNamedSlot(name, selector) > 0;
  }
  hasSingleNamedSlot(name: string, selector = '') {
    return this.#countNamedSlot(name, selector) === 1;
  }
  #hasTextContent($node: Node) {
    const isTextNode = $node.nodeType === document.TEXT_NODE;
    const hasTextContent = isTextNode && $node.textContent!.trim() !== '';
    return hasTextContent;
  }
  #countDefaultSlot(selector = '') {
    const $nodes = Array.from<Node>(selector ? this.#hostElement.children : this.#hostElement.childNodes);
    const matches = $nodes.filter($node => {
      if (this.#hasTextContent($node)) {
        return true;
      }
      const isElementNode = $node.nodeType === document.ELEMENT_NODE;
      if (isElementNode) {
        const $el = $node as HTMLElement;
        if (!$el.hasAttribute('slot')) {
          return selector ? $el.matches(selector) : true;
        }
      }
      return false;
    });
    return matches.length;
  }
  #countNamedSlot(slotName: string, selector = '') {
    return this.#hostElement.querySelectorAll(`:scope > ${selector}[slot="${slotName}"]`).length;
  }
  hostConnected() {
    this.#hostElement.shadowRoot!.addEventListener('slotchange', this.#handleSlotChange);
  }
  hostDisconnected() {
    this.#hostElement.shadowRoot!.removeEventListener('slotchange', this.#handleSlotChange);
  }
  #handleSlotChange = (event: Event) => {
    const $slot = event.target as HTMLSlotElement;
    const slotName = $slot.name;
    let shouldUpdate = false;
    if (!slotName) {
      shouldUpdate = this.#defaultSlot;
    } else {
      shouldUpdate = this.#namedSlots.includes(slotName) || this.#allowDynamicSlots;
    }
    if (shouldUpdate) {
      this.#hostElement.requestUpdate();
    }
  };
}