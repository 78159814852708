import { css } from 'lit';
export const style = css`
  :host {
    height: 454px;
    width: 440px;
    vertical-align: top;
  }

  .one-ux-element--root {
    position: relative;
  }

  :host([state-dashboard-context]) {
    width: auto !important;
    height: auto !important;
    grid-column: auto / span var(--one-ux-gadget-element--internal-column-span) !important;
    grid-row: auto / span var(--one-ux-gadget-element--internal-row-span) !important;
  }

  .placement-preview {
    box-sizing: border-box;
    border: var(--one-ux-border-width--500) dashed var(--one-ux-palette--brand-neutral-300);
    border-radius: var(--one-ux-radius--400);
    height: 100%;
    position: relative;
    pointer-events: none;
  }

  .gadget {
    height: 100%;
    background: var(--one-ux-palette--gray-100);
    border-radius: var(--one-ux-radius--400);
    box-shadow: var(--one-ux-shadow--drop-200);
    border: var(--one-ux-border-width--400) solid var(--one-ux-palette--brand-neutral-200);
    overflow: hidden;
    box-sizing: border-box;
    outline: none;

    display: flex;
    flex-direction: column;
    position: relative;

    &.dragging {
      position: fixed;
      backface-visibility: hidden;
      z-index: 1;

      box-shadow: var(--one-ux-shadow--drop-400);
      background: rgba(var(--one-ux-rgb--gray-100), 0.5);
      backdrop-filter: blur(12px);

      cursor: grabbing;
    }
  }

  .header {
    flex: 0 0 auto;
    background: rgba(var(--one-ux-rgb--brand-neutral-100), 0.7);
    padding: var(--one-ux-spacing--400) var(--one-ux-spacing--500);
    min-height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: var(--one-ux-spacing--400);
    box-shadow: inset 0px -4px 4px -4px rgba(var(--one-ux-rgb--brand-neutral-600), 0.2);
    user-select: none;
    overflow: hidden;

    :host([state-keyboard-focus]) .gadget:focus & {
      background: var(--one-ux-palette--keyboard-focus-200);
    }

    .gadget.dragging & {
      background: rgba(var(--one-ux-rgb--brand-neutral-100), 0.5);
    }
  }

  .header-title {
    flex: 1 1 auto;
    min-width: 0;

    display: flex;
    align-items: center;
    gap: var(--one-ux-spacing--300);
  }

  slot[name='header-start']::slotted([one-ux-element='one-ux-icon']) {
    font-size: var(--one-ux-size--icon-500);
    color: var(--one-ux-palette--brand-neutral-500);
  }

  .header-title-text {
    color: var(--one-ux-palette--brand-neutral-600);
    font: var(--one-ux-font--heading-100);
    letter-spacing: var(--one-ux-letter-spacing--heading-100);
    text-box: trim-both cap alphabetic;
  }

  slot[name='header-end']::slotted([one-ux-element='one-ux-icon']) {
    font-size: var(--one-ux-size--icon-300);
    color: var(--one-ux-palette--brand-neutral-500);
    margin: 0 var(--one-ux-spacing--100);
  }

  .content {
    flex: 1 1 auto;
    min-height: 0;

    color: var(--one-ux-palette--brand-neutral-600);
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);

    overflow: hidden;
  }

  .content-inner {
    display: flex;
    min-height: 100%;
  }

  .content-inner-padding {
    flex: 1 1 auto;
    min-width: 0;
    padding: var(--one-ux-spacing--500);

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      inset: 0 0 auto 0;
      padding-top: inherit;
      background-image: linear-gradient(to bottom, var(--one-ux-palette--gray-100) 50%, transparent);
    }

    &:after {
      inset: auto 0 0 0;
      padding-bottom: inherit;
      background-image: linear-gradient(to top, var(--one-ux-palette--gray-100) 50%, transparent);
    }

    &.has-footer-below {
      padding-bottom: var(--one-ux-spacing--200);
    }

    .gadget.dragging & {
      &:before,
      &:after {
        display: none;
      }
    }
  }

  .footer {
    flex: 0 0 auto;
    padding: var(--one-ux-spacing--200) var(--one-ux-spacing--500);
    font: var(--one-ux-font--body-200);
    letter-spacing: var(--one-ux-letter-spacing--body-200);
    color: var(--one-ux-palette--brand-neutral-300);

    overflow: hidden;
  }

  .resize-handle {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: var(--one-ux-size--icon-200);
    color: var(--one-ux-palette--brand-neutral-300);

    cursor: nwse-resize;
    user-select: none;
  }

  .skeleton-icon,
  .skeleton-title,
  .skeleton-content {
    border-radius: var(--one-ux-radius--300);
    animation: skeleton-pulse 1000ms var(--one-ux-easing--default) infinite alternate;
  }

  .skeleton-icon {
    flex: 0 0 auto;
    width: var(--one-ux-size--icon-500);
    height: var(--one-ux-size--icon-500);
    background: var(--one-ux-palette--brand-neutral-150);
  }

  .skeleton-title {
    width: 203px;
    height: var(--one-ux-line-height--heading-100);
    background: var(--one-ux-palette--brand-neutral-150);
  }

  .skeleton-content {
    height: 100%;
    background: var(--one-ux-palette--brand-neutral-100);
  }

  .extended-cursor-hit-area-for-dashboard {
    position: absolute;
  }

  :host([purpose='placeholder']) {
    .gadget {
      border: var(--one-ux-border-width--400) dashed var(--one-ux-palette--brand-neutral-300);
      background: none;
      box-shadow: none;
    }

    .header {
      background: none;
      box-shadow: none;
      border-bottom: var(--one-ux-border-width--400) dashed var(--one-ux-palette--brand-neutral-200);
    }

    slot[name='header-start']::slotted([one-ux-element='one-ux-icon']) {
      color: var(--one-ux-palette--brand-neutral-300);
    }

    .header-title-text {
      color: var(--one-ux-palette--brand-neutral-300);
    }

    slot[name='header-end']::slotted([one-ux-element='one-ux-icon']) {
      color: var(--one-ux-palette--brand-neutral-300);
    }

    .content {
      color: var(--one-ux-palette--brand-neutral-300);
    }

    .content-inner-padding {
      &:before,
      &:after {
        display: none;
      }
    }
  }

  :host([busy]) {
    .content {
      padding: var(--one-ux-spacing--500);
    }
  }

  @keyframes skeleton-pulse {
    to {
      opacity: 0.5;
    }
  }
`;