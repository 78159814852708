const ENSURED_AVAILABLE_LOCALSTORAGE_SPACE_IN_BYTES = 1000 * 1000 * 3; // 3MB
const MAX_KEY_SIZE_IN_BYTES = 1000 * 1000 * 1; // 1MB
const MAX_LOCALSTORAGE_SPACE_IN_BYTES = 1000 * 1000 * 5; // 5MB

export abstract class AvatarContextCacheRepository<T> {
  #cache: Record<string, T>;
  #storageKey: string;
  #expiredAfterMs: number;
  constructor(key: string, expiredAfterMs: number) {
    this.#storageKey = `pdr-default-avatar-context-${key}`;
    this.#expiredAfterMs = expiredAfterMs;
    this.#initializeCache();
  }
  public clear() {
    localStorage.removeItem(this.#storageKey);
  }
  protected getEntry(tenantId: string, userId: string): T | null {
    return this.#cache[`${tenantId}:${userId}`];
  }
  protected setEntry(tenantId: string, userId: string, entry: T) {
    this.#cache[`${tenantId}:${userId}`] = entry;
    this.#persist();
  }
  protected hasExpired = (item: {
    createdAt: string;
  }) => {
    const diff = new Date().getTime() - new Date(item.createdAt).getTime();
    return diff > this.#expiredAfterMs;
  };
  #persist() {
    if (this.#availableLocalStorageSpace() < ENSURED_AVAILABLE_LOCALSTORAGE_SPACE_IN_BYTES) {
      this.clear();
      return;
    }
    const value = JSON.stringify(this.#cache);
    const valueSizeInBytes = new TextEncoder().encode(value).length;
    if (valueSizeInBytes > MAX_KEY_SIZE_IN_BYTES) {
      this.clear();
      return;
    }
    try {
      localStorage.setItem(this.#storageKey, value);
    } catch {
      // localStorage is probably full.
      this.clear();
    }
  }
  #initializeCache() {
    if (this.#cache) {
      return;
    }
    const entry = localStorage.getItem(this.#storageKey);
    this.#cache = entry ? JSON.parse(entry) : {};
  }
  #availableLocalStorageSpace() {
    const currentLocalStorageSpace = new Blob(Object.values(localStorage)).size;
    return Math.max(0, MAX_LOCALSTORAGE_SPACE_IN_BYTES - currentLocalStorageSpace);
  }
}