import { InternalValueChangedEvent } from '../events/internal/InternalValueChangedEvent.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
import { InternalValidityChangedEvent } from '../events/internal/InternalValidityChangedEvent.js';
import { LanguageSets, getLangCode } from '../utils/getLangCode.js';
import { InternalValidityConfigChangedEvent } from '../events/internal/InternalValidityConfigChangedEvent.js';
import { InternalValidityResetEvent } from '../events/internal/InternalValidityResetEvent.js';
import { InternalValidityVisibleEvent } from '../events/internal/InternalValidityVisibleEvent.js';
type validatorResult = {
  valid: boolean;
  flags: ValidityStateFlags;
  errors: string[];
};
export interface IValidated {
  validate(): void;
  resetValidation(): void;
}
export type validatedFactoryOptions<TExtraProperties = void> = ThisType<OneUxElement & TExtraProperties> & {
  validator: () => validatorResult;
};
export const ValidatedFactory = <TExtraProperties = void,>(options?: validatedFactoryOptions<TExtraProperties>) => {
  const Validated = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
    class ValidatedClass extends SuperClass {
      constructor(...args: any[]) {
        super(...args);
        this.addEventListener(InternalValueChangedEvent.eventName, this.#handleValidationChange);
        this.addEventListener(InternalValidityConfigChangedEvent.eventName, this.#handleValidationChange);
      }
      #handleValidationChange = (e: Event) => {
        const composedPath = e.composedPath();
        if (composedPath.at(0) === this && options?.validator) {
          this.#validate();
        }
      };
      #validate() {
        const {
          valid,
          flags,
          errors
        } = options?.validator!.apply(this) as validatorResult;
        this.dispatchEvent(new InternalValidityChangedEvent({
          valid,
          errors,
          flags
        }));
      }
      validate() {
        this.#validate();
        this.dispatchEvent(new InternalValidityVisibleEvent());
      }
      resetValidation() {
        this.dispatchEvent(new InternalValidityResetEvent());
      }
    }
    return ValidatedClass as Constructor<IValidated> & TSuperClass;
  };
  return Validated;
};
export const validResult = Object.freeze({
  valid: true,
  get flags() {
    return {} as ValidityStateFlags;
  },
  get errors() {
    return [] as string[];
  }
}) satisfies validatorResult;
export type LanguageSet = {
  fieldIsRequired: string;
  fieldYouHaveToMakeChoice: string;
};
export const getFormValidationLanguage = (element: Element): LanguageSet => languageSets[getLangCode(element)];
const languageSets: LanguageSets<LanguageSet> = {
  en: {
    fieldIsRequired: 'This field is required',
    fieldYouHaveToMakeChoice: 'You have to make a choice'
  },
  sv: {
    fieldIsRequired: 'Detta fält är obligatoriskt',
    fieldYouHaveToMakeChoice: 'Du måste göra ett val'
  },
  nb: {
    fieldIsRequired: 'Dette feltet er obligatorisk',
    fieldYouHaveToMakeChoice: 'Du må ta et valg'
  },
  fi: {
    fieldIsRequired: 'Tämä kenttä pitää täyttää',
    fieldYouHaveToMakeChoice: 'Sinun on tehtävä valinta'
  },
  da: {
    fieldIsRequired: 'Dette felt er påkrævet',
    fieldYouHaveToMakeChoice: 'Du skal træffe et valg'
  }
};