import { html } from 'lit';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { Label } from '../../mixins/Label.js';
import { getLangCode } from '../../utils/getLangCode.js';
import { strings } from './language.js';
const Styled = StyledFactory(style);
const BaseClass = Label(Styled(OneUxElement));
export class OneUxBusyElement extends BaseClass {
  static get elementType() {
    return 'one-ux-busy';
  }
  protected render() {
    const lang = getLangCode(this);
    const label = this.label || strings[lang].loading;
    return html`<div class="one-ux-element--root">
      <progress aria-label=${label}></progress>
    </div>`;
  }
}