import { html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { Label } from '../../mixins/Label.js';
import { Disabled } from '../../mixins/Disabled.js';
import { Focusable } from '../../mixins/Focusable.js';
import { SlotController } from '../../controllers/SlotController.js';
import { consume } from '@lit/context';
import { classMap } from 'lit/directives/class-map.js';
import { defaultTabsContext, tabsContext } from '../../contexts/TabsContext.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { Optional } from '../../types.js';
import { Errors } from '../../mixins/Errors.js';
import { ErrorsPopout } from '../../fragments/ErrorsPopout.js';
import { Indicator } from './Indicator.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { Deprecation } from '../../utils/Deprecation.js';
import { register as _registerElement } from "../one-ux-icon/register-element.js";
import { register as _registerElement2 } from "../one-ux-pill/register-element.js";
_registerElement2("pill-c2e93425cca4756aa69343a5ebbc5d7d");
_registerElement("icon-93c0333638415107671faca11b499296");
const Styled = StyledFactory(style);
const Purpose = PurposeFactory({
  purposes: ['default', 'ai']
});
const BaseClass = Purpose(Errors(Disabled(Focusable(Label(Styled(OneUxElement))))));
export class OneUxTabElement extends BaseClass {
  static get elementType() {
    return 'one-ux-tab';
  }
  @consume({
    context: tabsContext,
    subscribe: true
  })
  _tabsContext = defaultTabsContext;
  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['start', 'end', 'adornment']
  });
  @property({
    type: String,
    attribute: 'disabled-reason'
  })
  public accessor disabledReason: Optional<string>;
  @property({
    type: String
  })
  public accessor name: Optional<string>;
  @state()
  private accessor _isHovered = false;
  @state()
  public accessor __isAddTab__ = false;
  public provideTabPanelRefs(tabId: string, tabpanelRefId: string) {
    const $tab = this.shadowRoot!.querySelector('[role="tab"]');
    $tab?.setAttribute('id', tabId);
    $tab?.setAttribute('aria-controls', tabpanelRefId);
  }
  public click() {
    if (this.disabled) return;
    const $tab = this.shadowRoot!.querySelector<HTMLButtonElement>('[role="tab"]');
    $tab?.click();
  }
  #deprecation = new Deprecation(this, '<one-ux-tab> is deprecated in favour of <one-ux-tab-next>', 'The element `<one-ux-tab>` is deprecated in favour of `<one-ux-tab-next>`.');
  protected willUpdate(): void {
    this.#deprecation.notify();
    this._tabsContext.updateTab(this);
    this.toggleAttribute('state-implicit', this._tabsContext.implicit);
  }
  guardedRender() {
    const tooltipText = this.#getTooltipText();
    return html`<button
        type="button"
        role="tab"
        class=${classMap({
      'one-ux-element--root': true,
      'is-implicit': this._tabsContext.implicit,
      'is-add-tab': this.__isAddTab__,
      [`is-weight-${this._tabsContext.weight}`]: true,
      'is-tablist-focused': this._tabsContext.isTablistFocused
    })}
        aria-label=${this.label}
        aria-disabled=${this.disabled}
        aria-selected=${this._tabsContext.isSelected(this)}
        tabindex=${this._tabsContext.isActive(this) || this.__isAddTab__ ? '0' : '-1'}
        one-ux-tooltip=${ifDefined(tooltipText)}
        ?one-ux-tooltip-custom-aria=${!!tooltipText}
        @mousedown=${() => this._isHovered = false}
        @click=${this.#handleClick}
        @blur=${this._tabsContext.onTabBlur}
        @mouseenter=${() => this._isHovered = true}
        @mouseleave=${() => this._isHovered = false}
      >
        <slot name="start"></slot>
        ${this.#aiIconFragment()}
        <slot></slot>
        ${this.#slots.hasDefaultSlot() ? nothing : html`<span class="label">${this.label}</span>`}
        <slot name="end"></slot>
        ${this.#pillFragment('end')}
        <div class="adornments">
          ${this.#pillFragment('adornment')}
          <slot name="adornment"></slot>
        </div>
        ${this.#indicatorFragment()}
      </button>
      ${ErrorsPopout({
      reference: 'previous',
      errors: this.disabled ? [] : this.errors,
      hidden: this.hideErrors
    })}`;
  }
  #indicatorFragment() {
    const isActionable = !this.disabled && !this._tabsContext.isSelected(this);
    const isKeyboardFocused = this._tabsContext.isTablistFocused && this._tabsContext.isActive(this);
    return isActionable && (this._isHovered || isKeyboardFocused) && !this.__isAddTab__ ? Indicator({
      implicit: this._tabsContext.implicit,
      weight: this._tabsContext.weight,
      purpose: this.purpose,
      isKeyboardFocused
    }) : nothing;
  }
  #aiIconFragment() {
    return !this.#slots.hasNamedSlot('start') && this.purpose === 'ai' ? html`<icon-93c0333638415107671faca11b499296 set="ai" icon="ai-powered" size="icon-100" purpose="ai"></icon-93c0333638415107671faca11b499296>` : nothing;
  }
  #pillFragment(placement: 'adornment' | 'end') {
    if (!this.errors.length) {
      return nothing;
    }
    if (!this._tabsContext.implicit && placement === 'adornment') {
      return html`<pill-c2e93425cca4756aa69343a5ebbc5d7d purpose="caution"></pill-c2e93425cca4756aa69343a5ebbc5d7d>`;
    }
    return this._tabsContext.implicit && placement === 'end' ? html`<pill-c2e93425cca4756aa69343a5ebbc5d7d purpose="caution" style="align-self: end"></pill-c2e93425cca4756aa69343a5ebbc5d7d>` : nothing;
  }
  #getTooltipText() {
    const disabledReason = this.disabledReason?.trim();
    const isDisabledReasonActive = this.disabled && disabledReason;
    const isLabelTooltipActive = this.#slots.hasSingleDefaultSlot('[one-ux-element="one-ux-icon"]');
    if (!isLabelTooltipActive && !isDisabledReasonActive) return undefined;
    if (isLabelTooltipActive && isDisabledReasonActive) {
      return `${this.label} (${disabledReason})`;
    }
    return isDisabledReasonActive ? disabledReason : this.label;
  }
  #handleClick() {
    if (this.disabled) return;
    this._tabsContext.changeTab(this);
  }
}