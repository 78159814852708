import { html } from 'lit';
import type { OneUxGadgetElement } from '../OneUxGadgetElement.js';
import { handleMouseMove } from '../handleMouseMove.js';
export function SkeletonContent(this: OneUxGadgetElement) {
  return html`
    <div
      class="header"
      @mousedown=${handleMouseMove.bind(this)}
      @dragstart=${(event: DragEvent) => event.preventDefault()}
    >
      <div class="header-title">
        <div class="skeleton-icon"></div>
        <div class="skeleton-title"></div>
      </div>

      <div class="skeleton-icon"></div>
      <div class="skeleton-icon"></div>
      <div class="skeleton-icon"></div>
    </div>

    <div class="content">
      <div class="skeleton-content"></div>
    </div>
  `;
}