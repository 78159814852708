import type { OneUxDashboardElement } from './OneUxDashboardElement.js';
import { getLangCode, langCode, LanguageSets } from '../../utils/getLangCode.js';
export type language = {
  locale: langCode;
  translations: translationSet;
};
export type translationSet = {
  dashboard: string;
};
export const languages: LanguageSets<translationSet> = {
  en: {
    dashboard: 'Dashboard'
  },
  sv: {
    dashboard: 'Dashboard'
  },
  nb: {
    dashboard: 'Dashboard'
  },
  fi: {
    dashboard: 'Dashboard'
  },
  da: {
    dashboard: 'Dashboard'
  }
};
export const getLanguage = (element: OneUxDashboardElement): language => {
  const locale = getLangCode(element);
  const translations = languages[locale];
  return {
    locale,
    translations
  };
};