import { css } from 'lit';
export const style = css`
  :host {
    max-width: 200px !important;
    vertical-align: middle;
  }

  .one-ux-element--root {
    --one-ux-badge--height: 20px;
    --one-ux-badge--vertical-spacing: var(--one-ux-spacing--smaller);
    --one-ux-badge--left-spacing: var(--one-ux-spacing--small);
    --one-ux-badge--right-spacing: var(--one-ux-spacing--small);
    --one-ux-badge--internal-spacing: var(--one-ux-spacing--small);
    --one-ux-badge--font: var(--one-ux-font--mono-400);
    --one-ux-badge--letter-spacing: var(--one-ux-letter-spacing--mono-400);
    --one-ux-badge--icon-size: var(--one-ux-size--icon-100);
    --one-ux-badge--line-height: 16px; /* Must be matched with respect to icon size in order to create even remaining space to distribute padding to text in order to align with icon. */

    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: var(--one-ux-badge--internal-spacing);
    box-sizing: border-box;

    border-radius: var(--one-ux-radius--200);
    min-width: var(--one-ux-badge--height);

    height: var(--one-ux-badge--height);
    padding: var(--one-ux-badge--vertical-spacing) var(--one-ux-badge--right-spacing)
      var(--one-ux-badge--vertical-spacing) var(--one-ux-badge--left-spacing);

    background: var(--one-ux-badge--background-color);
    color: var(--one-ux-badge--text-color);

    font: var(--one-ux-badge--font);
    font-weight: 700;
    letter-spacing: var(--one-ux-badge--letter-spacing);
    line-height: var(--one-ux-badge--line-height);
    cursor: default;

    &.multi-line {
      height: auto;
      min-height: var(--one-ux-badge--height);
    }

    &.regular-font {
      --one-ux-badge--font: var(--one-ux-font--label-variant-400);
      --one-ux-badge--letter-spacing: var(--one-ux-letter-spacing--label-variant-400);
    }
  }

  .one-ux-element--root::before {
    // Zero-width whitespace forces the base line to be the same regardless of icon
    content: attr(replicated-value) '\\200B';
    font: var(--one-ux-badge--font);
    font-weight: 700;
    letter-spacing: var(--one-ux-badge--letter-spacing);
  }

  .text {
    padding: calc((var(--one-ux-badge--icon-size) - var(--one-ux-badge--line-height)) / 2) 0;
  }

  .text-gradient .text {
    background: var(--one-ux-badge--text-color);
    background-clip: text;
    color: transparent;
  }

  .one-ux-element--root:not(.multi-line) .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .one-ux-element--root.multi-line .text {
    overflow-wrap: anywhere;
  }

  .icon-only {
    padding: var(--one-ux-badge--vertical-spacing);
    width: var(--one-ux-badge--height);
  }

  :host([weight='high']) .one-ux-element--root {
    --one-ux-badge--height: 24px;
    --one-ux-badge--vertical-spacing: 3px; /* Non-token based spacing in Figma design */
    --one-ux-badge--right-spacing: var(--one-ux-spacing--normal);
    --one-ux-badge--left-spacing: var(--one-ux-spacing--normal);
    --one-ux-badge--icon-size: var(--one-ux-size--icon-200);

    &.has-icon:not(.icon-only) {
      --one-ux-badge--left-spacing: var(--one-ux-spacing--small);
      --one-ux-badge--internal-spacing: var(--one-ux-spacing--small);
    }
  }

  :host([weight='low']) .one-ux-element--root {
    --one-ux-badge--height: 16px;
    --one-ux-badge--vertical-spacing: 0px;
    --one-ux-badge--right-spacing: var(--one-ux-spacing--small);
    --one-ux-badge--left-spacing: var(--one-ux-spacing--smaller);
    --one-ux-badge--font: var(--one-ux-font--mono-200);
    --one-ux-badge--letter-spacing: var(--one-ux-letter-spacing--mono-200);
    --one-ux-badge--line-height: 14px;
    --one-ux-badge--internal-spacing: var(--one-ux-spacing--smaller);

    &.regular-font {
      --one-ux-badge--font: var(--one-ux-font--label-variant-200);
      --one-ux-badge--letter-spacing: var(--one-ux-letter-spacing--label-variant-200);
    }

    &.has-icon:not(.icon-only) {
      --one-ux-badge--left-spacing: var(--one-ux-spacing--smaller);
      --one-ux-badge--internal-spacing: var(--one-ux-spacing--smaller);
    }
  }

  ::slotted([one-ux-element='one-ux-icon']),
  [one-ux-element='one-ux-icon'] {
    font-size: var(--one-ux-badge--icon-size) !important;
  }

  .one-ux-element--root:not(.default) ::slotted([one-ux-element='one-ux-icon']),
  .one-ux-element--root.default ::slotted([one-ux-element='one-ux-icon']:not([color])) {
    --one-ux-icon-element--color: var(--one-ux-badge--icon-color) !important;
  }
`;