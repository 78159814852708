import { css, unsafeCSS } from 'lit';
import checkboxEmpty from '../../assets/check-empty.svg?data';
import checkboxEmptyHover from '../../assets/check-empty-hover.svg?data';
import checkboxFull from '../../assets/check-full.svg?data';
import checkboxFullDisabled from '../../assets/check-full-disabled.svg?data';
import checkboxEmptyDisabled from '../../assets/check-empty-disabled.svg?data';
export const style = css`
  :host {
    vertical-align: middle;
    min-width: 215px;
    max-width: var(--one-ux-element-internal--popout-constraint-max-width, 100%);
    max-height: var(--one-ux-element-internal--popout-constraint-max-height, 'none');
    width: var(--one-ux-element-internal--popout-constraint-width, 'auto');
  }

  .one-ux-element--root {
    background: var(--one-ux-palette--gray-100);
    border: var(--one-ux-border-width--100) solid var(--one-ux-palette--brand-neutral-300);
    border-radius: var(--one-ux-radius--300);
  }

  :host(:not([explicit])) .one-ux-element--root {
    background: none;
    border: none;
    border-radius: 0;
  }

  :host([explicit]:focus-within) .one-ux-element--root {
    border-color: var(--one-ux-palette--focus-400);
  }

  :host([state-keyboard-focus][explicit]:focus-within) .one-ux-element--root {
    border-color: var(--one-ux-palette--keyboard-focus-400);
  }

  .list-box {
    outline: none;
    user-select: none;
  }

  .group {
    margin: var(--one-ux-spacing--normal) 0;
  }

  .group:first-child {
    margin-top: 0;
  }

  .group:last-child {
    margin-bottom: 0;
  }

  .option,
  .group-heading {
    box-sizing: border-box;
    color: var(--one-ux-palette--brand-neutral-600);
    min-height: 32px;
    display: flex;
    align-items: center;
    padding-left: var(--one-ux-spacing--400);
    padding-right: var(--one-ux-spacing--400);
    padding-top: 6px;
    padding-bottom: 6px;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  .option {
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    position: relative;
  }

  .group-heading {
    font: var(--one-ux-font--heading-200);
    letter-spacing: var(--one-ux-letter-spacing--heading-200);
  }

  .option:not([state-multiple], [disabled]):is([state-selected], :active)::before {
    content: '';
    position: absolute;
    left: 0;
    width: 4px;
    top: 0;
    bottom: 0;
  }

  .option:not([state-multiple])[state-selected]::before {
    background-color: var(--one-ux-palette--brand-core-300);
  }

  .option:not([state-multiple], [disabled]):active::before {
    background-color: var(--one-ux-palette--brand-core-350);
  }

  :host(:is(:hover, [state-keyboard-focus])) .option[state-active] {
    background-color: var(--one-ux-palette--brand-neutral-150);
  }

  .option:not([disabled]):active {
    background-color: var(--one-ux-palette--brand-neutral-200) !important;
  }

  .option[state-multiple] {
    background-repeat: no-repeat;
    /* The actual icon is 24px wide. */
    background-size: 24px;
    /* The icon should take up 36px have 12px of space to the right
       and a gap of 8px to the text on the left of the icon. */
    padding-right: 56px;
    /* To center the 24px wide icon in the 36px area
       it should have 6px of space on each side,
       and we have a 12px padding to the right. */
    background-position: right 18px center;
    background-image: url(${unsafeCSS(checkboxEmpty)});
  }

  .option[state-multiple][state-selected] {
    background-image: url(${unsafeCSS(checkboxFull)});
  }

  :host(:is(:hover, [state-keyboard-focus])) .option[state-multiple][state-active]:not([state-selected]) {
    background-image: url(${unsafeCSS(checkboxEmptyHover)});
  }

  .option[disabled] {
    color: var(--one-ux-palette--gray-400);
  }

  .option:not([disabled]) {
    cursor: pointer;
  }

  .option[disabled][state-active] {
    color: var(--one-ux-palette--gray-500);
  }

  .option[disabled][state-selected]::before {
    background-color: var(--one-ux-palette--brand-core-250);
  }

  .option[disabled][state-multiple][state-selected] {
    background-image: url(${unsafeCSS(checkboxFullDisabled)});
  }

  .option[disabled][state-multiple][state-active]:not([state-selected]) {
    background-image: url(${unsafeCSS(checkboxEmptyDisabled)});
  }
`;