import type { OneUxPaletteToken } from '../../generated/design-tokens.js';
import paletteKeys from '../../generated/json/palette/palette-keys.json';
import palette from '../../generated/json/palette/palette.json';
import { log } from '../../utils/log.js';
import { getGrayscale } from './getGrayscale.js';
import type { LanguageSet } from './language.js';
import type { color, optionData, colorKeyword, colorInput, groupInput, optionInput, hexColor, groupData } from './types.js';
import { colorKeywords } from './types.js';
const TRANSPARENT = '#0000';
const THRESHOLD_GRAYSCALE = 0.9;
const isGroupInput = (optionInput: optionInput): optionInput is groupInput => typeof optionInput === 'object' && 'heading' in optionInput;
const isColorObjectInput = (optionInput: optionInput): optionInput is colorInput => typeof optionInput === 'object' && 'color' in optionInput;
const isColorInput = (optionInput: optionInput): optionInput is color | colorInput => isColorObjectInput(optionInput) || typeof optionInput === 'string';
const getColor = (colorInput: color | colorInput) => isColorObjectInput(colorInput) ? colorInput.color : colorInput;
const isValidOneUxPaletteToken = (color: color): color is OneUxPaletteToken => paletteKeys.includes(color);
const isValidColorKeyword = (color: color): color is colorKeyword => colorKeywords.includes(color as colorKeyword);
export function toNormalizedGroups(optionInputs: optionInput[], translations: LanguageSet): groupData[] {
  const getLabel = (color: color) => isValidColorKeyword(color) ? translations[color] : undefined;
  const colorToOption = (colorInput: colorInput | color): optionData => {
    const value = getColor(colorInput);
    const hexColor = getHex(value);
    const label = getLabel(value);
    const isKeyword = isValidColorKeyword(value);
    const isPale = getGrayscale(hexColor) > THRESHOLD_GRAYSCALE;
    return {
      value,
      hexColor,
      label,
      isKeyword,
      isPale
    };
  };
  const uncategorizedGroup = {
    heading: '',
    options: optionInputs.filter((optionInput): optionInput is color | colorInput => isColorInput(optionInput) && isValidColor(optionInput)).map(colorToOption)
  };
  const groups = optionInputs.filter(isGroupInput).map(group => ({
    heading: group.heading,
    options: group.colors.filter(isValidColor).map(colorToOption)
  })).filter(group => {
    const hasHeading = !!group.heading;
    const hasColors = !!group.options.length;
    if (!hasHeading) {
      log.warning('Color group is missing heading, will be removed');
    }
    if (!hasColors) {
      log.warning('Color group is missing valid colors, will be removed');
    }
    return hasHeading && hasColors;
  });
  return uncategorizedGroup.options.length ? [uncategorizedGroup, ...groups] : groups;
}
function getHex(color: color): hexColor {
  if (isValidColorKeyword(color)) {
    return TRANSPARENT;
  }
  if (isValidOneUxPaletteToken(color)) {
    return palette[color] as hexColor;
  }
  return color;
}
function isValidColor(input: colorInput | color) {
  const color = getColor(input);
  const hexColorPattern = /^#(?:[0-9a-f]{3,4}){1,2}$/i;
  const isValidHexColor = hexColorPattern.test(color);
  const isValidColor = isValidHexColor || isValidColorKeyword(color) || isValidOneUxPaletteToken(color);
  if (!isValidColor) {
    log.warning(`The value "${color}" is not a valid color`);
  }
  return isValidColor;
}