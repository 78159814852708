export class AfterOpenEvent extends Event {
  static get eventName() {
    return 'afteropen';
  }
  constructor() {
    super(AfterOpenEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    });
  }
}