import { consume } from '@lit/context';
import { css, html } from 'lit';
import { property } from 'lit/decorators.js';
import { OneUxElement } from '../../OneUxElement.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { styleMap } from 'lit/directives/style-map.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { defaultTabsNextContext, tabsNextContext } from '../one-ux-tabs-next/TabsNextContext.js';
const Styled = StyledFactory(css`
  :host {
    display: contents !important;
  }
`);
const Purpose = PurposeFactory({
  purposes: ['default', 'ai', 'placeholder']
});
const BaseClass = Styled(Purpose(OneUxElement));
export class OneUxTabsIndicator extends BaseClass {
  static get elementType() {
    return 'one-ux-tabs-indicator';
  }
  @consume({
    context: tabsNextContext,
    subscribe: true
  })
  private _tabsContext = defaultTabsNextContext;
  @property({
    attribute: 'variant',
    type: String
  })
  public accessor variant!: 'active' | 'hover';
  @property({
    attribute: 'indicator-left',
    type: String
  })
  public accessor indicatorLeft = '';
  @property({
    attribute: 'no-animation',
    type: Boolean
  })
  public accessor noAnimation = false;
  @property({
    attribute: 'indicator-width',
    type: String
  })
  public accessor indicatorWidth = '';
  render() {
    const weight = this._tabsContext.weight;
    const implicit = this._tabsContext.implicit;
    const getColor = () => {
      if (this.purpose === 'ai') {
        return {
          'background-color': 'var(--one-ux-palette--ai-core-400)',
          'background-image': 'linear-gradient(to right, var(--one-ux-palette--ai-core-400) 0%, var(--one-ux-palette--ai-accent-400) 100%)'
        };
      }
      if (this._tabsContext.hasAccessibilityStyling) {
        return {
          'background-color': 'var(--one-ux-palette--keyboard-focus-700)'
        };
      }
      return {
        'background-color': weight === 'low' ? 'var(--one-ux-palette--brand-neutral-400)' : 'var(--one-ux-palette--brand-core-400)'
      };
    };
    const indicatorMargin = implicit ? this.variant === 'active' ? '0px' : `var(--one-ux-spacing--200)` : `var(--one-ux-spacing--300)`;
    const height = weight === 'low' ? 2 : 4;
    const borderRadius = implicit ? 'var(--one-ux-radius--300)' : 'var(--one-ux-radius--300) var(--one-ux-radius--300) 0 0';
    if (this.purpose === 'placeholder') return null;
    return html`<div
      style=${styleMap({
      ...getColor(),
      'border-radius': borderRadius,
      position: 'absolute',
      bottom: this.variant === 'active' || implicit ? '0' : '-1px',
      left: this.indicatorLeft,
      width: `calc(${this.indicatorWidth} - ${indicatorMargin} * 2)`,
      height: `${height}px`,
      margin: `0 ${indicatorMargin}`,
      'transition-duration': !this.hasUpdated || this.noAnimation ? '0ms' : 'var(--one-ux-duration--500)',
      'transition-timing': 'var(--one-ux-easing--enter)',
      'pointer-events': 'none',
      'z-index': 1
    })}
    ></div>`;
  }
}