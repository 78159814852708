import { Log, updateSettings } from '../../pdr/log/Log.js';
if (!window.SKIP_OVERRIDE_LOG_SETTINGS) {
  updateSettings({
    message: true,
    info: true,
    debug: true,
    error: true,
    timer: true,
    warning: true,
    tracker: true
  });
}
export const log = new Log('OneUx');