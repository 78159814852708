import { isKeyboardOnly } from '../utils/mouse-helper.js';
const oneUxKeyboardOnlyAttribute = 'one-ux-keyboard-only';
const clearKeyboardStyling = () => {
  document.body?.removeAttribute(oneUxKeyboardOnlyAttribute);
};
document.addEventListener('mousedown', clearKeyboardStyling, {
  capture: true
});
document.addEventListener('mouseup', clearKeyboardStyling, {
  capture: true
});
document.addEventListener('focus', () => {
  document.body?.toggleAttribute(oneUxKeyboardOnlyAttribute, isKeyboardOnly());
}, true);