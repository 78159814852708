import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
export interface IHidableTooltip {
  hideTooltip: boolean;
}
export const HidableTooltip = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  class HidableTooltipClass extends SuperClass {
    /**
     * Prevents any internal tooltip from being rendered.
     * Please note that this can make a component substantially harder to use for the end user.
     */
    @property({
      attribute: 'hide-tooltip',
      type: Boolean
    })
    public accessor hideTooltip = false;
  }
  return HidableTooltipClass as Constructor<IHidableTooltip> & TSuperClass;
};