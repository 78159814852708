import { createContext } from '@lit/context';
export interface IDashboardContext {
  columns: number;
  gap: number;
  rowHeight: number;
}
const contextKey = Symbol('one-ux-dashboard-context');
export const defaultDashboardContext: IDashboardContext = {
  columns: 0,
  gap: 0,
  rowHeight: 0
};
export const dashboardContext = createContext<IDashboardContext>(contextKey);