import { css, unsafeCSS } from 'lit';
import { weightToSize } from './weightToSize.js';
export const style = css`
  :host {
    vertical-align: middle;
  }

  .one-ux-element--root {
    --one-ux-avatar--background: var(--one-ux-palette--magenta-500);
    --one-ux-avatar--font: var(--one-ux-font--body-variant-200);
    --one-ux-avatar--font-color: var(--one-ux-palette--gray-100);
    --one-ux-avatar--letter-spacing: var(--one-ux-letter-spacing--body-variant-200);
    --one-ux-avatar--shadow: 0 0 2px 0 var(--one-ux-palette--brand-neutral-600);
    --one-ux-avatar--stroke-dasharray: 0;
    --one-ux-avatar--stroke-dashoffset: 0;
    --one-ux-avatar--stroke-rotation: 0deg;
    --one-ux-avatar--size: ${unsafeCSS(`${weightToSize['normal']}px`)};

    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    border-radius: var(--one-ux-radius--max);
    background: var(--one-ux-avatar--background);

    width: var(--one-ux-avatar--size);
    height: var(--one-ux-avatar--size);
    max-width: var(--one-ux-avatar--size);
    max-height: var(--one-ux-avatar--size);
    color: var(--one-ux-avatar--font-color);
    font: var(--one-ux-avatar--font);
    letter-spacing: var(--one-ux-avatar--letter-spacing);

    box-sizing: border-box;
    user-select: none;
    box-shadow: var(--one-ux-avatar--shadow);
    cursor: default;

    &:has(img) {
      overflow: hidden;
      --one-ux-avatar--background: transparent;
    }

    img {
      width: var(--one-ux-avatar--size);
      height: var(--one-ux-avatar--size);
      object-fit: cover;
      pointer-events: none;
    }

    [one-ux-element='one-ux-icon'] {
      position: absolute;
      margin-top: -1px;
    }

    svg {
      min-width: var(--one-ux-avatar--size);
      min-height: var(--one-ux-avatar--size);
      width: var(--one-ux-avatar--size);
      height: var(--one-ux-avatar--size);
      overflow: visible;

      text {
        fill: currentColor;
      }
    }

    &.has-state {
      --one-ux-avatar--background: var(--one-ux-palette--gray-100) !important;
      --one-ux-avatar--font-color: var(--one-ux-palette--brand-neutral-500) !important;

      circle {
        stroke: var(--one-ux-palette--brand-neutral-400);
        stroke-width: 1;
        stroke-dasharray: var(--one-ux-avatar--stroke-dasharray);
        stroke-dashoffset: var(--one-ux-avatar--stroke-dashoffset);
        transform: rotate(var(--one-ux-avatar--stroke-rotation));
        transform-origin: center;
        fill: transparent;
      }
    }
  }

  :host([weight='high']) .one-ux-element--root {
    --one-ux-avatar--size: ${unsafeCSS(`${weightToSize['high']}px`)};
    --one-ux-avatar--font: var(--one-ux-font--body-variant-400);
    --one-ux-avatar--letter-spacing: var(--one-ux-letter-spacing--body-variant-400);
    --one-ux-avatar--stroke-dasharray: 3.85;
    --one-ux-avatar--stroke-dashoffset: 0;
    --one-ux-avatar--stroke-rotation: 14deg;
  }

  :host([weight='normal']) .one-ux-element--root {
    --one-ux-avatar--size: ${unsafeCSS(`${weightToSize['normal']}px`)};
    --one-ux-avatar--font: var(--one-ux-font--body-variant-300);
    --one-ux-avatar--letter-spacing: var(--one-ux-letter-spacing--body-variant-300);
    --one-ux-avatar--stroke-dasharray: 4.05;
    --one-ux-avatar--stroke-dashoffset: 0;
    --one-ux-avatar--stroke-rotation: 22deg;
  }

  :host([weight='low']) .one-ux-element--root {
    --one-ux-avatar--size: ${unsafeCSS(`${weightToSize['low']}px`)};
    --one-ux-avatar--font: var(--one-ux-font--body-variant-200);
    --one-ux-avatar--letter-spacing: var(--one-ux-letter-spacing--body-variant-200);
    --one-ux-avatar--stroke-dasharray: 4.5;
    --one-ux-avatar--stroke-dashoffset: 3;
    --one-ux-avatar--stroke-rotation: 4deg;
  }

  :host([implicit]) .one-ux-element--root {
    --one-ux-avatar--background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09) !important;
    --one-ux-avatar--font-color: var(--one-ux-palette--magenta-500) !important;

    &.has-state {
      --one-ux-avatar--background: transparent !important;
      --one-ux-avatar--shadow: none !important;
    }
  }
`;