import { Action } from './Action.js';
export class Load extends Action {
  tag: string;
  $script: HTMLScriptElement;
  promise: Promise<unknown>;
  completeAction!: (value: unknown) => void;
  failAction!: (reason?: Error) => void;
  constructor(tag: string, url: string) {
    super();
    this.tag = tag;
    this.$script = this.createScriptElement(tag, url);
    this.promise = new Promise((resolve, reject) => {
      this.completeAction = resolve;
      this.failAction = reject;
    });
  }
  execute() {
    if (!this.started) {
      if (document.getElementById(this.$script.id)) {
        throw new Error(`A script tag with the same id ${this.$script.id} already exists, this should not be possible.`);
      }
      document.head.appendChild(this.$script);
    }
    return this.promise;
  }
  createScriptElement(tag: string, url: string) {
    const $script = document.createElement('script');
    $script.id = `mfe-load-${tag}`;
    $script.src = this.applyCacheQuery(url);
    $script.onload = () => {
      this.completed = true;
      this.completeAction(true);
    };
    $script.onerror = () => {
      this.failAction(new Error(`Could not download script for ${tag}.`));
    };
    $script.defer = true;
    return $script;
  }
  get started() {
    return this.$script.parentNode !== null;
  }
}