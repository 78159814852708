import { css, unsafeCSS } from 'lit';
import checkEmpty from '../../assets/check-empty.svg?data';
import checkEmptyHover from '../../assets/check-empty-hover.svg?data';
import checkEmptyDisabled from '../../assets/check-empty-disabled.svg?data';
import checkIndeterminate from '../../assets/check-indeterminate.svg?data';
import checkIndeterminateHover from '../../assets/check-indeterminate-hover.svg?data';
import checkIndeterminateDisabled from '../../assets/check-indeterminate-disabled.svg?data';
import checkFull from '../../assets/check-full.svg?data';
import checkFullDisabled from '../../assets/check-full-disabled.svg?data';
import radioEmpty from '../../assets/radio-empty.svg?data';
import radioEmptyHover from '../../assets/radio-empty-hover.svg?data';
import radioEmptyDisabled from '../../assets/radio-empty-disabled.svg?data';
import radioFull from '../../assets/radio-full.svg?data';
import radioFullDisabled from '../../assets/radio-full-disabled.svg?data';
import treeNodeCollapsed from '../../assets/tree-node-collapsed.svg?data';
import treeNodeCollapsedDisabled from '../../assets/tree-node-collapsed-disabled.svg?data';
import treeNodeExpanded from '../../assets/tree-node-expanded.svg?data';
import treeNodeExpandedDisabled from '../../assets/tree-node-expanded-disabled.svg?data';
export const style = css`
  :host {
    --contextual-one-ux-tree-element--row-border-radius: 0;
  }

  .one-ux-element--root {
    --contextual-one-ux-tree-element--min-row-width: 150px;
    --contextual-one-ux-tree-element--line-color: var(--one-ux-palette--brand-neutral-300);

    background: var(--one-ux-palette--gray-100);
    border: var(--one-ux-border-width--100) solid var(--one-ux-palette--brand-neutral-300);
    border-radius: var(--one-ux-radius--300);
    user-select: none;
  }

  :host([implicit]) .one-ux-element--root {
    background: none;
    border: none;
    border-radius: 0;
  }

  :host(:not([implicit]):focus-within) .one-ux-element--root {
    border-color: var(--one-ux-palette--focus-400);
  }

  :host([state-keyboard-focus]:not([implicit]):focus-within) .one-ux-element--root {
    border-color: var(--one-ux-palette--keyboard-focus-400);
  }

  .tree {
    outline: none;
    box-sizing: border-box;
    width: fit-content;
    min-width: 100%;
    max-width: 100%;
  }

  .tree-node--row {
    padding: 2px 8px 2px calc(8px + 20px * var(--contextual-one-ux-tree-element--depth));
    display: flex;
    cursor: default;
    align-items: start;
    border-radius: var(--contextual-one-ux-tree-element--row-border-radius);

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  .tree-node--content {
    margin-left: var(--one-ux-spacing--small);
    min-width: var(--contextual-one-ux-tree-element--min-row-width);
    flex-grow: 1;
  }

  .tree-node--default-content {
    display: flex;
    flex: 1 1 auto;
    gap: var(--one-ux-spacing--small);
    color: var(--one-ux-palette--brand-neutral-600);
  }

  .tree-node--disabled .tree-node--default-content {
    color: var(--one-ux-palette--gray-400);
  }

  .tree-node--disabled .tree-node--default-content .tree-node--icon {
    color: inherit !important;
  }

  .tree-node--default-content .tree-node--icon {
    height: 20px;
    flex: 0 0 auto;
    font-size: var(--one-ux-size--icon-200);
  }

  :host(:not([disabled])) .tree-node--active {
    background: var(--one-ux-palette--brand-neutral-150);
  }

  :host(:not([disabled])) .tree-node--row:not(.tree-node--disabled),
  :host(:not([disabled])) .tree-node--expandable > .tree-node--expander {
    cursor: pointer;
  }

  :host([implicit]:not([disabled])) .tree-node--active {
    background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
  }

  :host([implicit][state-keyboard-focus]:not([disabled]):focus) .tree-node--active {
    background: var(--one-ux-palette--keyboard-focus-300);
  }

  .tree-node--checkbox,
  .tree-node--radio {
    width: 20px;
    height: 20px;
    min-width: 0;
    flex: none;
    background-position: center;
    background-repeat: no-repeat;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  .tree-node--checkbox {
    background-image: url(${unsafeCSS(checkEmpty)});
  }

  .tree-node--active:not(.tree-node--disabled, .tree-node--selected, .tree-node--has-child-selected)
    .tree-node--checkbox {
    background-image: url(${unsafeCSS(checkEmptyHover)});
  }

  .tree-node--has-child-selected .tree-node--checkbox {
    background-image: url(${unsafeCSS(checkIndeterminate)});
  }

  .tree-node--active:not(.tree-node--disabled, .tree-node--selected).tree-node--has-child-selected
    .tree-node--checkbox {
    background-image: url(${unsafeCSS(checkIndeterminateHover)});
  }

  .tree-node--selected .tree-node--checkbox {
    background-image: url(${unsafeCSS(checkFull)});
  }

  .tree-node--disabled .tree-node--checkbox {
    background-image: url(${unsafeCSS(checkEmptyDisabled)});
  }

  .tree-node--disabled.tree-node--has-child-selected .tree-node--checkbox {
    background-image: url(${unsafeCSS(checkIndeterminateDisabled)});
  }

  .tree-node--disabled.tree-node--selected .tree-node--checkbox {
    background-image: url(${unsafeCSS(checkFullDisabled)});
  }

  .tree-node--radio {
    background-image: url(${unsafeCSS(radioEmpty)});
  }

  .tree-node--active:not(.tree-node--disabled, .tree-node--selected) .tree-node--radio {
    background-image: url(${unsafeCSS(radioEmptyHover)});
  }

  .tree-node--selected .tree-node--radio {
    background-image: url(${unsafeCSS(radioFull)});
  }

  .tree-node--disabled .tree-node--radio {
    background-image: url(${unsafeCSS(radioEmptyDisabled)});
  }

  .tree-node--disabled.tree-node--selected .tree-node--radio {
    background-image: url(${unsafeCSS(radioFullDisabled)});
  }

  .tree-node--text {
    flex: 1 1 auto;
    min-width: 0;
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    line-height: 20px;
    overflow-wrap: break-word;
  }

  .tree-node--expander {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    flex: 0 0 auto;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--one-ux-size--icon-200);
  }

  .tree-node--expandable > .tree-node--expander {
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${unsafeCSS(treeNodeCollapsed)});
  }

  .tree-node--expandable.tree-node--expanded > .tree-node--expander {
    background-image: url(${unsafeCSS(treeNodeExpanded)});
  }

  :host([disabled]) .tree-node--expandable > .tree-node--expander {
    background-image: url(${unsafeCSS(treeNodeCollapsedDisabled)});
  }

  :host([disabled]) .tree-node--expandable.tree-node--expanded > .tree-node--expander {
    background-image: url(${unsafeCSS(treeNodeExpandedDisabled)});
  }

  .tree-node--row,
  .tree-node--children {
    position: relative;
  }

  .tree-node--row:before,
  .tree-node--row:after,
  .tree-node--children:after {
    content: '';
    display: block;
    position: absolute;
    background: var(--contextual-one-ux-tree-element--line-color);
    /* some padding - half icon width + icon width * depth  */
    left: calc(8px - 10px + 20px * var(--contextual-one-ux-tree-element--depth));
  }

  .tree-node--row:before {
    top: -2px;
    height: calc(100% + 2px);
    width: 1px;
  }

  .tree-node--container:last-child > .tree-node--row:before {
    height: 14px;
  }

  .tree-node--row:after {
    top: 12px;
    width: 25px;
    height: 1px;
  }

  .tree-node--row.tree-node--expandable:after {
    width: 10px;
  }

  .tree-node--children:after {
    top: 0;
    height: 100%;
    width: 1px;
  }

  .tree > .tree-node--container > .tree-node--row:before,
  .tree > .tree-node--container > .tree-node--row:after,
  .tree > .tree-node--container > .tree-node--children:after,
  .tree-node--container:last-child > .tree-node--children:after {
    display: none;
  }
`;