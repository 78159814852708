import { PDRModule } from '../PDRModule.js';
const libraries = {
  'oidc-client': {
    version: require('oidc-client/package.json').version as string,
    package: import('oidc-client') as any
  },
  lit: {
    version: require('../../../node_modules/lit/package.json').version as string,
    package: import('lit') as any
  }
} as const;
export class LibrariesModule extends PDRModule {
  get(name: keyof typeof libraries, version = '') {
    const library = libraries[name];
    if (!library || version && library.version !== version) {
      return null;
    }
    return library.package;
  }
  version(name: keyof typeof libraries) {
    const library = libraries[name];
    if (!library) {
      return null;
    }
    return library.version;
  }
  list() {
    return Object.keys(libraries).reduce((result, library) => Object.assign(result, {
      [library]: libraries[library as keyof typeof libraries].version
    }), {});
  }
}