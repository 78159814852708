import { OneUxElement } from '../../OneUxElement.js';
import { Constructor } from '../../utils.js';
import { ContextProvider } from '@lit/context';
import { property } from 'lit/decorators.js';
import { treeContext, ITreeContext } from './ITreeContext.js';
import { InputNode, InputIcon, NodeData, NodeIcon } from './contextual-one-ux-tree/types.js';
import { IValue, ValueFactory } from '../../mixins/Value.js';
import { InternalValueChangedEvent } from '../../events/internal/InternalValueChangedEvent.js';
export type expandOption = 'none' | 'selected' | 'all';
export interface ITreeProps extends IValue<string[] | string> {
  nodes: InputNode[];
  multiple: boolean;
  expand: expandOption;
  expandMin: number;
  expandMax: number;
}
export interface ITreeContextMixin extends ITreeProps {
  _treeContextProvider: ContextProvider<{
    __context__: ITreeContext;
  }>;
}
type internalState = {
  nodes: InputNode[];
  expand: expandOption;
  expandMin: number;
  expandMax: number;
};
export const TreeContextMixin = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  const Value = ValueFactory<string[] | string, TreeContextMixinClass>({
    getter: function (): string[] | string {
      if (!this._treeContextProvider.value?.value.length) {
        return this.multiple ? [] : '';
      }
      if (this.multiple) {
        return this._treeContextProvider.value?.value;
      }
      return this._treeContextProvider.value?.value[0];
    },
    setter: function (value: string[] | string) {
      let newValue: string[] = [];
      if (Array.isArray(value)) {
        newValue = value.map(x => x + '');
      } else if (typeof value !== 'undefined') {
        newValue = [value + ''];
      }
      for (const node of this._treeContextProvider.value!.tree) {
        this._updateTreeNode(node, new Set(newValue));
      }
      this._updateContext('value', newValue);
    },
    converter: function (value) {
      if (value == null) {
        return '';
      }
      try {
        return JSON.parse(value).map((x: any) => x.toString());
      } catch {
        return (value ?? '').toString();
      }
    },
    isEmpty(value) {
      return !(Array.isArray(value) ? value.length : value);
    }
  });
  const BaseClass = Value(SuperClass);
  class TreeContextMixinClass extends BaseClass {
    @property({
      type: Array
    })
    public set nodes(value: InputNode[]) {
      this.#updateInternalState('nodes', value);
    }
    public get nodes() {
      return this.#internalState.nodes;
    }
    @property({
      type: Boolean
    })
    public set multiple(value: boolean) {
      this._updateContext('multiple', value);
    }
    public get multiple() {
      return this._treeContextProvider.value?.multiple;
    }
    @property({
      type: String
    })
    public set expand(value: expandOption) {
      this.#updateInternalState('expand', value);
    }
    public get expand() {
      return this.#internalState.expand;
    }
    @property({
      attribute: 'expand-min',
      type: Number
    })
    public set expandMin(value: number) {
      this.#updateInternalState('expandMin', value);
    }
    public get expandMin() {
      return this.#internalState.expandMin;
    }
    @property({
      attribute: 'expand-max',
      type: Number
    })
    public set expandMax(value: number) {
      this.#updateInternalState('expandMax', value);
    }
    public get expandMax() {
      return this.#internalState.expandMax;
    }
    #microTask = Promise.resolve();
    #calculateTreeQueued = false;
    async #calculateTree() {
      if (this.#calculateTreeQueued) {
        return;
      }
      this.#calculateTreeQueued = true;
      await this.#microTask;
      const activeNodeRef = {
        value: null as NodeData | null
      };
      const tree = this.nodes.map((node, index) => this.#buildTreeNode(node, [index], activeNodeRef));
      if (activeNodeRef.value == null) {
        activeNodeRef.value = tree[0] || null;
      }
      this._treeContextProvider.setValue({
        ...this._treeContextProvider.value,
        tree,
        activeNode: activeNodeRef.value
      });
      this.#calculateTreeQueued = false;
    }
    #buildTreeNode(inputNode: InputNode, path: number[], activeNodeRef: {
      value: NodeData | null;
    }): NodeData {
      const children: NodeData[] = [];
      const value = inputNode.value == null ? '' : inputNode.value + '';
      const selected = this._treeContextProvider.value!.value.includes(value);
      const depth = path.length;
      let expanded = false;
      let anyChildSelected = false;
      if (inputNode.children?.length) {
        for (let i = 0; i < inputNode.children.length; ++i) {
          const inputChildNode = inputNode.children[i];
          const child = this.#buildTreeNode(inputChildNode, [...path, i], activeNodeRef);
          children.push(child);
          if (child.selected || child.expanded || inputChildNode.default) {
            expanded = true;
          }
          if (child.selected || child.anyChildSelected) {
            anyChildSelected = true;
          }
        }
      }
      if (!expanded) {
        if (this.expand === 'all') {
          expanded = depth < this.expandMax;
        }
        if (this.expand === 'selected') {
          expanded = depth < this.expandMin;
        }
      }
      if (!children.length || this.expand === 'none') {
        expanded = false;
      }
      const icons = inputNode.icons ? inputNode.icons.map((icon: InputIcon): NodeIcon => ({
        set: icon.set || 'default',
        name: icon.name,
        text: icon.text,
        color: icon.color,
        position: icon.position || 'before'
      })) : [];
      const node: NodeData = {
        path,
        value,
        text: inputNode.text,
        icons,
        children: children || [],
        expanded,
        selected,
        anyChildSelected,
        disabled: !!inputNode.disabled,
        tooltip: inputNode.tooltip
      };
      if (inputNode.default && activeNodeRef.value === null) {
        activeNodeRef.value = node;
      }
      return node;
    }
    _updateTreeNode(node: NodeData, newValue: Set<string>) {
      let anyChildSelected = false;
      if (node.children?.length) {
        for (let i = 0; i < node.children.length; ++i) {
          const child = node.children[i];
          this._updateTreeNode(child, newValue);
          if (child.selected || child.anyChildSelected) {
            anyChildSelected = true;
          }
        }
      }
      node.selected = !!node.value && newValue.has(node.value);
      node.anyChildSelected = anyChildSelected;
    }
    #internalState = {
      nodes: [],
      expand: 'selected',
      expandMin: 2,
      expandMax: Infinity
    } as internalState;
    #updateInternalState<TKey extends keyof internalState>(key: TKey, value: internalState[TKey]) {
      this.#internalState[key] = value;
      this.#calculateTree();
    }
    #setValue = (node: NodeData) => {
      let newValue = [...this._treeContextProvider.value!.value];
      if (this.multiple) {
        const index = newValue.indexOf(node.value!);
        if (index === -1) {
          newValue.push(node.value!);
        } else {
          newValue.splice(index, 1);
        }
      } else {
        newValue = [node.value!];
      }
      for (const node of this._treeContextProvider.value!.tree) {
        this._updateTreeNode(node, new Set(newValue));
      }
      this._updateContext('value', newValue);
      this.dispatchEvent(new InternalValueChangedEvent());
    };
    #setActiveNode = (node: NodeData) => {
      this._updateContext('activeNode', node);
    };
    _treeContextProvider = new ContextProvider(this, {
      context: treeContext,
      initialValue: {
        tree: [],
        activeNode: null,
        value: [],
        multiple: false,
        setValue: this.#setValue,
        setActiveNode: this.#setActiveNode
      }
    });
    _updateContext<TKey extends keyof ITreeContext>(name: TKey, value: ITreeContext[TKey]) {
      const context = this._treeContextProvider.value!;
      const oldValue = context[name];
      if (oldValue !== value) {
        this._treeContextProvider.setValue({
          ...this._treeContextProvider.value,
          [name]: value
        });
      }
    }
  }
  return TreeContextMixinClass as Constructor<ITreeContextMixin> & TSuperClass;
};