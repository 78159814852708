export type internalComponentStateChangedDetails = {
  property: string;
  value: unknown;
};
export class InternalElementStateChangedEvent extends CustomEvent<internalComponentStateChangedDetails> {
  static get eventName() {
    return 'internal-one-ux-component-state-changed';
  }
  constructor(detail: internalComponentStateChangedDetails) {
    super(InternalElementStateChangedEvent.eventName, {
      bubbles: true,
      cancelable: false,
      detail
    });
  }
}