import { OneUxElement } from '../../OneUxElement.js';
import { PropertyValues, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { Focusable } from '../../mixins/Focusable.js';
import { Disabled } from '../../mixins/Disabled.js';
import type { DividerData, GroupData, InputDivider, InputGroup, InputOption, ItemData, OptionData } from './types.js';
import { cloneItem, findIndexBasedOnType } from './utils.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { style } from './style.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { consume } from '@lit/context';
import { defaultPopoutContext, popoutContext } from '../../contexts/PopoutContext.js';
import { traverseNodes } from '../../utils.js';
import { MenuItem } from './fragments/MenuItem.js';
import { register as _registerElement } from "../one-ux-scroll/register-element.js";
_registerElement("scroll-d42bea42ed1544a4549451391c6d6453");
const Styled = StyledFactory(style);
const BaseClass = Disabled(Focusable(Styled(OneUxElement)));
type RootRefs = {
  $menu: Ref<HTMLElement>;
};
export class OneUxMenuElement extends BaseClass {
  static get elementType() {
    return 'one-ux-menu';
  }
  @consume({
    context: popoutContext,
    subscribe: true
  })
  protected _popoutContext = defaultPopoutContext;
  constructor() {
    super();
    this.addEventListener('mouseleave', () => {
      if (!this._hasFocus) {
        this._collapseItems();
      }
    });
    this.addEventListener('focus', () => {
      if (this.shadowRoot!.activeElement === this._refs.$menu.value) {
        this.#tryFocusFirstItem();
      }
      this.requestUpdate();
    });
    this.addEventListener('blur', () => {
      this._collapseItems();
      this.requestUpdate();
    });
  }
  @property({
    type: Array
  })
  public accessor items = [] as (InputDivider | InputOption | InputGroup)[];
  @state()
  protected accessor _internalItems = [] as (OptionData | DividerData | GroupData)[];
  protected get _hasFocus() {
    return !!this.shadowRoot!.activeElement;
  }
  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('items')) {
      this._internalItems = this.items.map(item => cloneItem(item, null));
      if (this._hasFocus) {
        this._refs.$menu.value!.focus();
        this.#tryFocusFirstItem();
      }
    }
  }
  protected render() {
    const items = this._internalItems.map((item: ItemData) => MenuItem.call(this, {
      item,
      itemTypeIndex: findIndexBasedOnType(this._internalItems, item)
    }));
    return html`<scroll-d42bea42ed1544a4549451391c6d6453 class="one-ux-element--root">
      <div
        ${ref(this._refs.$menu)}
        role="menu"
        aria-disabled=${this.disabled}
        class="menu"
        tabindex=${this._hasFocus || this.disabled ? '-1' : '0'}
      >
        ${items}
      </div>
    </scroll-d42bea42ed1544a4549451391c6d6453>`;
  }
  protected _collapseItems = () => {
    traverseNodes(this._internalItems, item => {
      if (item.type === 'group') {
        const group = item as GroupData;
        group.expanded = false;
        this.requestUpdate();
      }
    });
  };
  async #tryFocusFirstItem() {
    if (!this._hasFocus) return;
    await this.updateComplete;
    const firstItem = this.shadowRoot!.querySelector<HTMLElement>('[role="menuitem"]');
    firstItem?.focus();
  }
  protected _refs: RootRefs = {
    $menu: createRef()
  };
}