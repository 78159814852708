import { log } from '../log.js';
export class CancellationToken {
  private cancelCallbacks = [] as (() => void)[];
  cancel() {
    const callbacks = [...this.cancelCallbacks];
    this.cancelCallbacks.length = 0;
    callbacks.forEach(callback => {
      try {
        callback();
      } catch {
        log.warning('Could not execute callback in cancellation token.');
      }
    });
  }
  addCallback(callback: () => void) {
    const isCallable = callback instanceof Function;
    const isNotRegistered = !this.cancelCallbacks.includes(callback);
    if (isCallable && isNotRegistered) {
      this.cancelCallbacks.push(callback);
    }
  }
  removeCallback(callback: () => void) {
    if (this.cancelCallbacks.includes(callback)) {
      this.cancelCallbacks.splice(this.cancelCallbacks.indexOf(callback), 1);
    }
  }
}