const booleanProperties = {
  disabled: true,
  required: true,
  empty: true,
  readonly: true
} as Record<string, boolean>;
export function isBooleanAttribute(name: string) {
  return booleanProperties[name] ?? false;
}
const GLOBAL_ATTRIBUTES = ['accesskey', 'class', 'contenteditable', 'dir', 'draggable', 'enterkeyhint', 'hidden', 'id', 'inert', 'inputmode', 'lang', 'popover', 'role', 'spellcheck', 'style', 'tabindex', 'title', 'translate'];
const ONE_UX_ATTRIBUTES = ['width', 'height'];
const ignoredAttributesLookUp = [...ONE_UX_ATTRIBUTES, ...GLOBAL_ATTRIBUTES].reduce((lookup, attr) => Object.assign(lookup, {
  [attr]: true
}), {}) as Record<string, boolean>;
export function isIgnoredAttributeForContextAndAdapter(name: string) {
  return !!ignoredAttributesLookUp[name] || name.startsWith('one-ux-') || name.startsWith('data-') || name.startsWith('aria-');
}