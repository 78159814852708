import { ApiModuleConfig, PDRConfig, PDRWebviewConfig } from '../types.js';
export class ApiSettings {
  #config;
  constructor(config: ApiModuleConfig) {
    this.#config = config;
  }
  static build(config: PDRConfig | PDRWebviewConfig) {
    return new ApiSettings(config.http || {});
  }
  get loginAndRetryCodes() {
    return this.#config.loginAndRetryCodes || [401];
  }
  get extraHeaders() {
    return this.#config.extraHeaders || {};
  }
}