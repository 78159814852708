import { css } from 'lit';
export const style = css`
  :host {
    width: 1em;
    height: 1em;
    vertical-align: middle;
  }

  .one-ux-element--root {
    container-type: size;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  progress {
    appearance: none;
    box-sizing: border-box;
    width: 100cqmin;
    height: 100cqmin;
    border: 12.5cqmin solid var(--one-ux-palette--focus-400);
    border-top-color: transparent;
    border-radius: var(--one-ux-radius--max);
    background-color: transparent;
    background-image: none;

    transform-origin: center;
    transform: rotate(60deg);
    animation: rotate 800ms infinite cubic-bezier(0.45, 0.05, 0.81, 0.94);

    &::-webkit-progress-bar,
    &::-webkit-progress-value {
      background-color: transparent;
    }

    &::-moz-progress-bar {
      background-color: transparent;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(420deg);
    }
  }
`;