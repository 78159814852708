export class BeforeActiveEvent extends Event {
  nextActiveTab: string;
  static get eventName() {
    return 'beforeactive';
  }
  constructor(name: string) {
    super(BeforeActiveEvent.eventName, {
      bubbles: false,
      cancelable: true,
      composed: false
    });
    this.nextActiveTab = name;
  }
}
export class ActiveEvent extends Event {
  static get eventName() {
    return 'active';
  }
  constructor() {
    super(ActiveEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    });
  }
}