import { css } from 'lit';
export const style = css`
  .one-ux-element--root {
    display: flex;
    align-items: baseline;
  }

  .label {
    flex: 1;
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    color: var(--one-ux-palette--brand-neutral-600);
    position: relative;
    top: -3px;
    margin-bottom: -3px;
    padding-left: var(--one-ux-spacing--200);
    padding-right: var(--one-ux-spacing--200);
    overflow-wrap: anywhere;
    user-select: none;
  }

  .label:not(.has-disabled-child) {
    cursor: pointer;
  }

  .label.has-disabled-child {
    color: var(--one-ux-palette--gray-400);
  }
`;