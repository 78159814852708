import { OneUxElement } from '../../OneUxElement.js';
import { html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { classMap } from 'lit/directives/class-map.js';
import { style } from './style.js';
import { Weight } from '../../mixins/Weight.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { Implicit } from '../../mixins/Implicit.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { parseLinks } from './link-parser.js';
import oneUxDuration from '../../generated/json/duration/duration.json';
import { messageSets, type messageType, type icon, type badge } from './message-sets.js';
import { getLanguage } from './language.js';
import { getAnimationOptions } from './animation.js';
import { flushAnimations } from '../../utils/animation-utils.js';
import { log } from '../../utils/log.js';
import { OpenClosedFactory } from '../../mixins/OpenClosed.js';
import { SlotController } from '../../controllers/SlotController.js';
import type { Optional } from '../../types.js';
import { register as _registerElement } from "../one-ux-icon/register-element.js";
import { register as _registerElement2 } from "../one-ux-badge/register-element.js";
import { register as _registerElement3 } from "../one-ux-widget-button/register-element.js";
_registerElement3("widget-button-bf902f6d0895222b21f9d98e1c7bc0f3");
_registerElement2("badge-c3c8bb88e5b6f83ee3ab6fc6bb8baedf");
_registerElement("icon-4cfe279eb5461a1db3cf0190f8cbfd14");
const Styled = StyledFactory(style);
const {
  Closed
} = OpenClosedFactory<{
  mode: mode;
}>({
  type: 'closed',
  async action() {
    if (!this.shadowRoot) {
      return;
    }
    const animationPendingAttribute = 'animation-pending';
    this.setAttribute(animationPendingAttribute, '');
    const {
      animation,
      $animated,
      height
    } = getAnimationOptions(this.mode === 'collapsible', this.closed, this.shadowRoot);
    if (height != null) {
      await $animated?.animate(animation(height), {
        duration: oneUxDuration[200] * 2
      }).finished;
    }
    this.removeAttribute(animationPendingAttribute);
  },
  beforeAction() {
    const $animatedContent = this.shadowRoot?.querySelector('.content');
    if ($animatedContent) {
      flushAnimations($animatedContent);
    }
  }
});
const Purpose = PurposeFactory({
  purposes: ['default', 'main', 'caution', 'notice']
});
const BaseClass = Closed(Implicit(Purpose(Weight(Styled(OneUxElement)))));
type mode = 'none' | 'collapsible' | 'dismissible';
type screenReaderUrgency = 'none' | 'normal' | 'high';
export class OneUxMessageElement extends BaseClass {
  static get elementType() {
    return 'one-ux-message';
  }
  @property({
    type: String
  })
  public accessor heading = '';
  @property({
    type: String
  })
  public accessor content = '';
  @property({
    type: String,
    reflect: true
  })
  public accessor message: Optional<messageType>;
  @property({
    type: String
  })
  public accessor mode: mode = 'none';
  @property({
    type: String,
    attribute: 'screen-reader-urgency'
  })
  public accessor screenReaderUrgency: screenReaderUrgency = 'normal';
  @property({
    type: Boolean
  })
  public accessor parse = false;
  @property({
    attribute: 'parse-links',
    type: Boolean
  })
  public accessor parseLinks = false;
  @property({
    attribute: 'parse-emails',
    type: Boolean
  })
  public accessor parseEmails = false;
  #slots: SlotController = new SlotController(this, {
    slots: ['content', 'header', 'header-end']
  });
  constructor() {
    super();
    this.width = 'max';
  }
  protected willUpdate(): void {
    this.#logDeprecations();
  }
  protected render() {
    const {
      icon,
      badge,
      ...messageSet
    } = messageSets[this.message || this.#getMessageFromPurpose()];
    const hideHeading = !this.#slots.hasNamedSlot('header') && (!this.heading || this.weight === 'low');
    const title = hideHeading ? this.heading : undefined;
    const isCollapsed = this.mode === 'collapsible' && this.closed;
    return html`<div
      aria-live=${ifDefined(this.#getAriaLive())}
      aria-atomic=${this.screenReaderUrgency === 'high'}
      class=${classMap({
      'one-ux-element--root': true,
      message: true,
      hidden: this.mode !== 'collapsible' && this.closed
    })}
      style=${styleMap({
      '--one-ux-message--icon-color': messageSet.iconColor,
      '--one-ux-message--font-color': messageSet.textColor,
      '--one-ux-message--background-color': messageSet.backgroundColor,
      '--one-ux-message--border-color': messageSet.borderColor,
      '--one-ux-message--content-collapsed-height': this._contentCollapsedHeight
    })}
      one-ux-tooltip=${ifDefined(title)}
      one-ux-tooltip-custom-aria
    >
      <div class="graphic">${this.#renderSymbol(icon, badge)}</div>
      <div
        class=${classMap({
      content: true,
      'hide-heading': hideHeading,
      collapsed: isCollapsed
    })}
      >
        <div aria-expanded=${ifDefined(this.mode === 'collapsible' ? !this.closed : undefined)} class="inner-content">
          <div class="header">
            <strong class="heading">
              <slot name="header">${!hideHeading ? this.heading : nothing}</slot>
            </strong>
            <div class="header-end">
              <slot name="header-end"></slot>
            </div>
          </div>
          <div class="slotted-content">
            <slot name="content">${this.#renderLegacyContent()}</slot>
          </div>
        </div>
      </div>
      ${this.#renderActionButton()}
    </div>`;
  }
  #renderSymbol = (icon?: icon, badge?: badge) => {
    if (icon) return html`<icon-4cfe279eb5461a1db3cf0190f8cbfd14 set=${icon.set} icon=${icon.icon}></icon-4cfe279eb5461a1db3cf0190f8cbfd14>`;
    if (badge) return html`<badge-c3c8bb88e5b6f83ee3ab6fc6bb8baedf
        set=${badge.set}
        badge=${badge.badge}
        weight=${this.weight}
        lang=${ifDefined(getLanguage(this).lang)}
      ></badge-c3c8bb88e5b6f83ee3ab6fc6bb8baedf>`;
    return nothing;
  };
  #renderLegacyContent = () => {
    if (!this.content) return nothing;

    // NOTE: Whitespace sensitive markup!
    return html`<span class="legacy-content" pdr-test-hook="one-ux-message-content"
      >${parseLinks(this.content.trim(), {
      parse: this.parse,
      parseEmails: this.parseEmails,
      parseLinks: this.parseLinks
    })}</span
    >`;
  };
  #renderActionButton = () => {
    if (!this.mode || this.mode === 'none') return nothing;
    const {
      translations,
      lang
    } = getLanguage(this);
    const labelKey = this.mode === 'dismissible' ? 'close' : this.closed ? 'expand' : 'minimize';
    const label = translations[labelKey];
    const icon = this.mode === 'dismissible' ? 'close' : this.closed ? 'toggle-down' : 'toggle-up';
    const handleClick = this.mode === 'dismissible' ? this.#close : this.#toggle;
    return html`<widget-button-bf902f6d0895222b21f9d98e1c7bc0f3
      accessible
      label=${label}
      lang=${ifDefined(lang)}
      icon="${icon}"
      @click=${handleClick}
    ></widget-button-bf902f6d0895222b21f9d98e1c7bc0f3>`;
  };
  #close() {
    this.closed = true;
  }
  #toggle() {
    this.closed = !this.closed;
  }
  #getMessageFromPurpose(): messageType {
    switch (this.purpose) {
      case 'caution':
        return 'error';
      case 'notice':
        return 'warning';
      case 'main':
        return 'success';
      case 'default':
      default:
        return 'info';
    }
  }
  #logDeprecations() {
    if (!this.hasUpdated) {
      if (!this.message && this.purpose !== 'default') {
        log.deprecation('The "purpose" attribute is deprecated in favor of "message" attribute.');
      }
      if (this.heading) {
        log.deprecation('The "heading" attribute is deprecated in favor of "header" slot.');
      }
      if (this.content) {
        log.deprecation('The "content" attribute is deprecated in favor of "content" slot.');
      }
    }
  }
  #getAriaLive() {
    switch (this.screenReaderUrgency.trim()) {
      case 'high':
        return 'assertive';
      case 'none':
        return undefined;
      case 'normal':
      default:
        return 'polite';
    }
  }
  get _contentCollapsedHeight() {
    const hasHeader = this.#slots.hasNamedSlot('header') || !!this.heading.trim();
    return hasHeader ? 'var(--one-ux-message--line-height-heading)' : 'var(--one-ux-message--line-height-body)';
  }
}