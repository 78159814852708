import { OneUxElement } from '../../OneUxElement.js';
import { html, nothing, PropertyValues } from 'lit';
import { property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { style } from './style.js';
import { Weight } from '../../mixins/Weight.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { Implicit } from '../../mixins/Implicit.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { Focusable } from '../../mixins/Focusable.js';
import { Disabled } from '../../mixins/Disabled.js';
import { Label } from '../../mixins/Label.js';
import { Busy } from '../../mixins/Busy.js';
import { Optional } from '../../types.js';
import { HidableTooltip } from '../../mixins/HidableTooltip.js';
import { classMap } from 'lit/directives/class-map.js';
import { keyCodes } from '../../utils.js';
import type { OneUxPopoutElement } from '../one-ux-popout/OneUxPopoutElement.js';
import { provide } from '@lit/context';
import { IPopoutContext, popoutContext } from '../../contexts/PopoutContext.js';
import { SlotController } from '../../controllers/SlotController.js';
import { styleMap } from 'lit/directives/style-map.js';
import { TABBABLE_TARGETS_SELECTOR } from '../../utils/focusable.js';
import { register as _registerElement } from "../one-ux-popout/register-element.js";
import { register as _registerElement2 } from "../one-ux-scroll/register-element.js";
import { register as _registerElement3 } from "../one-ux-icon/register-element.js";
_registerElement3("icon-465911ba4015fe64053f1df74e59eac0");
_registerElement2("scroll-4ce33f0cfff31eec894e8757ac81c7ce");
_registerElement("popout-7c49eaf1dc3d0e2edda08af5cb969f6d");
const Styled = StyledFactory(style);
const Purpose = PurposeFactory({
  purposes: ['default', 'main', 'caution', 'notice', 'muted', 'placeholder']
});
const BaseClass = Busy(HidableTooltip(Label(Disabled(Focusable(Implicit(Purpose(Weight(Styled(OneUxElement)))))))));
export class OneUxButtonElement extends BaseClass {
  static get elementType() {
    return 'one-ux-button';
  }
  static formAssociated = true;
  @property({
    attribute: 'form-action',
    type: String
  })
  public accessor formAction: Optional<'submit' | 'reset'>;

  /** @internal */
  @state()
  accessor _active = false;

  /** @internal */
  @provide({
    context: popoutContext
  })
  _popoutContext: IPopoutContext = {
    closePopout: (skipAutomaticFocus = false) => {
      if (skipAutomaticFocus) new Error('Not implemented');
      if (this._active) {
        this._buttonElement.focus();
        this.#toggleActive();
      }
    },
    openPopout: (_skipAutomaticFocus = false) => {
      throw new Error('Not implemented');
    },
    isOpen: this._active
  };
  #elementInternals: ElementInternals;
  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['start', 'end', 'popout', 'adornment']
  });
  constructor() {
    super();
    this.#elementInternals = this.attachInternals();
    this.addEventListener('blur', this.#handleBlur, {
      capture: true
    });
    this.addEventListener('keydown', this.#handleKeydown, {
      capture: true
    });
  }
  public click() {
    this.shadowRoot!.querySelector('button')!.click();
  }
  protected willUpdate(changed: PropertyValues<this>) {
    if (changed.has('_active')) {
      this._popoutContext = {
        ...this._popoutContext,
        isOpen: this._active
      };
    }
  }
  protected guardedRender() {
    const isCompact = this.#isCompact;
    const compactLabel = isCompact ? this.label : undefined;
    const tooltip = this.hideTooltip ? undefined : compactLabel;
    const content = this.#isDefaultMenuButton ? html`<icon-465911ba4015fe64053f1df74e59eac0 set="internal" icon="context-menu"></icon-465911ba4015fe64053f1df74e59eac0>` : html`<span class="button-text">${this.label}</span>`;
    return html`<button
        id="button"
        @click=${this.#handleClick}
        class=${classMap({
      'one-ux-element--root': true,
      compact: isCompact
    })}
        one-ux-tooltip=${ifDefined(tooltip)}
        ?one-ux-tooltip-custom-aria=${!!tooltip}
        ?one-ux-tooltip-fixed=${tooltip && isCompact}
        aria-label=${ifDefined(compactLabel)}
        aria-haspopup=${ifDefined(this.#hasPopoutContent || undefined)}
        aria-expanded=${ifDefined(this.#hasPopoutContent ? this._active : undefined)}
        aria-pressed=${ifDefined(this.#hasPopoutContent ? this._active : undefined)}
        aria-disabled=${ifDefined(this.busy || this.disabled ? true : undefined)}
        type="button"
      >
        <slot name="start"></slot>
        <slot></slot>
        ${this.#hasDefaultContent ? nothing : content}
        <slot name="end"></slot>
        <div
          class=${classMap({
      adornment: true,
      'is-empty': !this.#slots.hasNamedSlot('adornment')
    })}
        >
          <slot name="adornment"></slot>
        </div>
        <div class="spinner-clip">
          <div
            class=${classMap({
      spinner: true,
      busy: this.busy,
      done: this.done,
      round: isCompact && this.weight === 'high'
    })}
          ></div>
        </div>
      </button>
      ${this.#hasPopoutContent && this._active ? html`<popout-7c49eaf1dc3d0e2edda08af5cb969f6d
            class="popout"
            reference="previous"
            indent="none"
            style=${styleMap({
      minWidth: `${(this.shadowRoot!.querySelector('#button')! as HTMLButtonElement).offsetWidth}px`
    })}
          >
            <scroll-4ce33f0cfff31eec894e8757ac81c7ce
              style="width: 100%; max-height: var(--one-ux-button-element--popout-max-height, max(35vh, 250px))"
            >
              <slot name="popout"></slot>
            </scroll-4ce33f0cfff31eec894e8757ac81c7ce>
          </popout-7c49eaf1dc3d0e2edda08af5cb969f6d>` : html`<slot hidden name="popout"></slot>`}`;
  }
  #handleClick = (originalEvent: MouseEvent) => {
    originalEvent.stopPropagation();
    if (this.busy) return;
    if (this.dispatchEvent(new MouseEvent('click', originalEvent))) {
      if (this.#hasPopoutContent) {
        this.#toggleActive();
      } else {
        switch (this.formAction) {
          case 'submit':
            {
              this.#elementInternals.form?.requestSubmit();
              break;
            }
          case 'reset':
            {
              this.#elementInternals.form?.reset();
              break;
            }
        }
      }
    }
  };
  @queryAssignedElements({
    slot: 'popout'
  })
  private accessor _popoutContent!: Array<HTMLElement>;
  @query('#button')
  private accessor _buttonElement!: OneUxPopoutElement;
  #getFirstFocusableInPopout() {
    const content = this._popoutContent;
    for (const $element of content) {
      if ($element.matches(TABBABLE_TARGETS_SELECTOR)) {
        return $element;
      }
      const $focusableChild = $element.querySelector<HTMLElement | SVGElement>(TABBABLE_TARGETS_SELECTOR);
      if ($focusableChild) {
        return $focusableChild;
      }
    }
  }
  #toggleActive = async () => {
    if (this._active) {
      this._active = false;
      await this.updateComplete;
      this.dispatchEvent(new Event('close'));
    } else {
      this._active = true;
      await this.updateComplete;
      const $child = this.#getFirstFocusableInPopout();
      if ($child) {
        const observer = new IntersectionObserver(async () => {
          if ('updateComplete' in $child) {
            await $child.updateComplete;
          }
          $child.focus();
          this.dispatchEvent(new Event('open'));
          observer.disconnect();
        });
        observer.observe($child);
      }
    }
  };
  #handleBlur = (event: FocusEvent) => {
    if (this._active) {
      const $newFocus = event.relatedTarget as HTMLElement;
      if (this.contains($newFocus) || this.shadowRoot?.contains($newFocus)) {
        return;
      }
      this.#toggleActive();
    }
  };
  #handleKeydown = (event: KeyboardEvent) => {
    if (!this.#hasPopoutContent || this.disabled) {
      return;
    }
    const handled = () => {
      event.stopPropagation();
      event.preventDefault();
    };
    if (!this._active) {
      switch (event.code) {
        case keyCodes.SPACE:
        case keyCodes.UP:
        case keyCodes.DOWN:
        case keyCodes.RETURN:
          if (this._active) {
            this._buttonElement.focus();
          }
          this.#toggleActive();
          return handled();
      }
    } else {
      switch (event.code) {
        case keyCodes.ESCAPE:
          this._buttonElement.focus();
          this.#toggleActive();
          return handled();
      }
    }
  };
  get #hasPopoutContent() {
    return this.#slots.hasNamedSlot('popout');
  }
  get #hasDefaultContent() {
    return this.#slots.hasDefaultSlot();
  }
  get #isDefaultMenuButton() {
    return this.#hasPopoutContent && !this.#slots.hasNamedSlot('start') && !this.#slots.hasNamedSlot('end');
  }
  get #isCompact() {
    if (this.#slots.hasDefaultSlotTextContent() || this.#slots.hasNamedSlot('start') || this.#slots.hasNamedSlot('end')) {
      return false;
    }
    if (!this.#slots.hasSingleDefaultSlot('[one-ux-element="one-ux-icon"]') && !this.#slots.hasNamedSlot('popout')) {
      return false;
    }
    return true;
  }
}