import { html } from 'lit';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { Label } from '../../mixins/Label.js';
import { property, state } from 'lit/decorators.js';
import { SlotController } from '../../controllers/SlotController.js';
import { FocusableFactory } from '../../mixins/Focusable.js';
import { consume } from '@lit/context';
import { dashboardContext, defaultDashboardContext } from '../../contexts/DashboardContext.js';
import { Busy } from '../../mixins/Busy.js';
import { ResizeEvent } from './events/ResizeEvent.js';
import { BeforeResizeEvent } from './events/BeforeResizeEvent.js';
import { MoveEvent } from './events/MoveEvent.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { getLanguage } from './lang.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { handleKeyboard } from './handleKeyboard.js';
import { handleMouseResize } from './handleMouseResize.js';
import { GadgetContent } from './fragments/GadgetContent.js';
import { SkeletonContent } from './fragments/SkeletonContent.js';
import { register as _registerElement } from "../one-ux-icon/register-element.js";
_registerElement("icon-0d7e22b87198c74087af05766b0579d8");
const Styled = StyledFactory(style);
const Focusable = FocusableFactory(false);
const Purpose = PurposeFactory({
  purposes: ['default', 'placeholder']
});
const BaseClass = Purpose(Busy(Focusable(Label(Styled(OneUxElement)))));
export class OneUxGadgetElement extends BaseClass {
  static get elementType() {
    return 'one-ux-gadget';
  }
  @property({
    type: Number,
    attribute: 'column-span'
  })
  public accessor columnSpan = 1;
  @property({
    type: Number,
    attribute: 'row-span'
  })
  public accessor rowSpan = 1;
  @property({
    type: Number,
    attribute: 'max-column-span'
  })
  public accessor maxColumnSpan = 1;
  @property({
    type: Number,
    attribute: 'max-row-span'
  })
  public accessor maxRowSpan = 1;
  @property({
    type: Boolean
  })
  public accessor movable!: boolean;
  @property({
    type: Boolean
  })
  public accessor resizable!: boolean;

  /** @internal */
  @state()
  @consume({
    context: dashboardContext,
    subscribe: true
  })
  _dashboardContext = defaultDashboardContext;

  /** @internal */
  @state()
  accessor _draggingState: {
    width: number;
    height: number;
    top: number;
    left: number;
    columnSpan?: number;
    rowSpan?: number;
  } | null = null;
  protected _slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['header-start', 'header-end', 'custom-content', 'footer']
  });
  protected get _hasDashboardParent() {
    return !!this.parentElement?.matches('[one-ux-element="one-ux-dashboard"]');
  }
  public focus() {
    this.shadowRoot?.querySelector<HTMLElement>('.gadget')?.focus();
  }
  protected guardedRender() {
    const lang = getLanguage(this);
    const toPx = (size?: number) => size ? `${size}px` : null;
    return html`
      <div class="one-ux-element--root">
        ${this._draggingState ? html`<div class="placement-preview"></div>` : null}
        ${this._hasDashboardParent ? html`<div
              class="extended-cursor-hit-area-for-dashboard"
              style=${styleMap({
      inset: `calc(-0.5 * ${this._dashboardContext.gap}px)`
    })}
            ></div>` : null}

        <div
          class=${classMap({
      gadget: true,
      dragging: !!this._draggingState
    })}
          tabindex=${ifDefined(this._hasDashboardParent && (this.resizable || this.movable) ? 0 : undefined)}
          role=${this._hasDashboardParent ? 'listitem' : 'group'}
          aria-roledescription=${lang.translations.gadget}
          aria-label=${this.label}
          style=${styleMap({
      width: toPx(this._draggingState?.width),
      height: toPx(this._draggingState?.height),
      top: toPx(this._draggingState?.top),
      left: toPx(this._draggingState?.left)
    })}
          @keydown=${handleKeyboard.bind(this)}
        >
          ${this.busy ? SkeletonContent.call(this) : GadgetContent.call(this)}
          ${this._hasDashboardParent && this.resizable ? html`
                <icon-0d7e22b87198c74087af05766b0579d8
                  class="resize-handle"
                  set="internal"
                  icon="resize-handle"
                  @mousedown=${handleMouseResize.bind(this)}
                  @dragstart=${(event: DragEvent) => event.preventDefault()}
                ></icon-0d7e22b87198c74087af05766b0579d8>
              ` : null}
        </div>
      </div>
    `;
  }
  protected updated() {
    this.toggleAttribute('state-dashboard-context', this._hasDashboardParent);
    this.#updateInternalColumnAndRowSpan();
  }

  // We can not shuffle this to internal ShadowDOM as that requires `display: contents` on host and that breaks the drag and drop move handler
  #updateInternalColumnAndRowSpan() {
    const columnSpan = Math.min(this._draggingState?.columnSpan ?? this.columnSpan, this.maxColumnSpan, this._dashboardContext.columns);
    const rowSpan = Math.min(this._draggingState?.rowSpan ?? this.rowSpan, this.maxRowSpan);
    this.style.setProperty('--one-ux-gadget-element--internal-column-span', this._hasDashboardParent ? String(columnSpan) : null, 'important');
    this.style.setProperty('--one-ux-gadget-element--internal-row-span', this._hasDashboardParent ? String(rowSpan) : null, 'important');
  }
  protected _move(newIndex: number) {
    if (!this._hasDashboardParent) return;
    const dashboardChildren = Array.from(this.parentElement!.children);
    const validNewIndex = newIndex >= 0 && newIndex < dashboardChildren.length;
    const oldIndex = dashboardChildren.indexOf(this);
    const sameIndex = newIndex === oldIndex;
    if (!validNewIndex || sameIndex) return;
    this.dispatchEvent(new MoveEvent(oldIndex, newIndex));
  }
  protected _resize(newColumnSpan: number, newRowSpan: number) {
    const validSize = newColumnSpan > 0 && newColumnSpan <= this.maxColumnSpan && newRowSpan > 0 && newRowSpan <= this.maxRowSpan;
    const sameSize = newColumnSpan === this.columnSpan && newRowSpan === this.rowSpan;
    if (!validSize || sameSize) return;
    if (this.dispatchEvent(new BeforeResizeEvent(newColumnSpan, newRowSpan))) {
      this.columnSpan = newColumnSpan;
      this.rowSpan = newRowSpan;
      this.dispatchEvent(new ResizeEvent());
    }
  }
}