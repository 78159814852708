import { css } from 'lit';
export const style = css`
  :host {
    box-sizing: border-box;
    position: fixed;
    backface-visibility: hidden;
    width: max-content;

    /* Remove styling applied by browser to popovers */
    inset: auto;
    padding: 0;
    border: none;
    background: none;
    overflow: visible;
  }

  :host([state-initial]) {
    display: none !important;
    pointer-events: none !important;
  }

  .one-ux-element--root {
    background-clip: padding-box;
    overflow: auto;

    :host(:not([implicit])) & {
      background: var(--one-ux-palette--gray-100);
      box-shadow: var(--one-ux-shadow--drop-400);
      border-radius: var(--one-ux-radius--400);
    }
  }
`;