import { createContext } from '@lit/context';
type registeredCallbacks = {
  set(force?: boolean): boolean;
  reset(): void;
};
type registerConfig<TElement extends HTMLElement> = {
  element: TElement;
} & registeredCallbacks;
export interface IMutexWriteContext<TElement extends HTMLElement = HTMLElement> {
  register(config: registerConfig<TElement>): void;
  unregister(element: TElement): void;
  lock(element: TElement): void;
  release(element: TElement): void;
}
export interface IMutexReadContext<TElement extends HTMLElement = HTMLElement> {
  current: TElement | null;
  notify(callback: (element: TElement | null) => void): void;
}
const writeContextKey = Symbol('one-ux-mutex-write-context');
const readContextKey = Symbol('one-ux-mutex-read-context');
export const getDefaultMutexWriteContext = <TElement extends HTMLElement = HTMLElement,>(): IMutexWriteContext<TElement> => ({
  register(_config: registerConfig<TElement>) {},
  unregister(_element: TElement) {},
  lock(_element: TElement) {},
  release(_element: TElement) {}
});
export const getDefaultMutexReadContext = <TElement extends HTMLElement = HTMLElement,>(): IMutexReadContext<TElement> => ({
  current: null,
  notify(_callback: (element: TElement | null) => void) {}
});
export const mutexWriteContext = <TElement extends HTMLElement = HTMLElement,>() => createContext<IMutexWriteContext<TElement>>(writeContextKey);
export const mutexReadContext = <TElement extends HTMLElement = HTMLElement,>() => createContext<IMutexReadContext<TElement>>(readContextKey);
export class MutexContext<TElement extends HTMLElement = HTMLElement> implements IMutexWriteContext<TElement>, IMutexReadContext<TElement> {
  #registered: Map<TElement, registeredCallbacks> = new Map();
  #lockedElement: TElement | null = null;
  get #locked() {
    return this.#lockedElement;
  }
  set #locked(element: TElement | null) {
    const previousLockedElement = this.#lockedElement;
    this.#lockedElement = element;
    this.#notifications.forEach(callback => callback(previousLockedElement));
  }
  get current() {
    return this.#locked;
  }
  register({
    element,
    set,
    reset
  }: registerConfig<TElement>) {
    this.#registered.set(element, {
      set,
      reset
    });
  }
  lockFirst() {
    const ordered = Array.from(this.#registered.entries()).toSorted((a, b) => a[0].compareDocumentPosition(b[0]) === Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1);
    for (const [element, callbacks] of ordered) {
      const isSuccess = callbacks.set();
      if (isSuccess) {
        this.#locked = element;
        return;
      }
    }
  }
  lock(element: TElement) {
    this.#locked = element;
    for (const [key, callbacks] of this.#registered.entries()) {
      if (key !== element) {
        callbacks.reset();
      }
    }
  }
  force(element: TElement) {
    this.#registered.get(element)?.set(true);
  }
  release(element: TElement) {
    if (element === this.#locked) {
      this.#locked = null;
    }
    if (!this.#locked) {
      this.lockFirst();
    }
  }
  unregister(element: TElement) {
    this.#registered.delete(element);
    this.release(element);
  }
  #notifications: ((element: TElement | null) => void)[] = [];
  notify(callback: (element: TElement | null) => void) {
    this.#notifications.push(callback);
  }
}