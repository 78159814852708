import { timingFunction } from '../../utils/animation-utils.js';
import oneUxEasing from '../../generated/json/easing/easing.json';
import oneUxDuration from '../../generated/json/duration/duration.json';
export const animationOptions: KeyframeAnimationOptions = {
  duration: oneUxDuration[500],
  easing: timingFunction(oneUxEasing.enter)
};
const activatedState = {
  opacity: 1,
  visibility: 'visible',
  left: 0
};
type direction = -1 | 1;
const deactivatedState = (direction: direction) => ({
  opacity: 0,
  visibility: 'hidden',
  left: `${direction * 100}%`,
  'pointer-events': 'none'
});
export const tabpanelAnimation = (transition: 'enter' | 'leave', direction: direction) => transition === 'enter' ? enterTabpanelAnimation(direction) : leaveTabpanelAnimation(direction);
const reverse = (d: direction): direction => d === 1 ? -1 : 1;
const enterTabpanelAnimation = (direction: direction) => [deactivatedState(direction), activatedState];
const leaveTabpanelAnimation = (direction: direction) => [activatedState, deactivatedState(reverse(direction))];