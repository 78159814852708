import { Toast } from '../Toast.js';
const animationTimeEnter = 500;
const animationTimeExit = 300;
export async function show(toast: Toast) {
  const $toastElement = toast.$ref.value;
  if (!$toastElement) {
    return;
  }
  $toastElement.style.position = 'relative';
  $toastElement.animate([
  // keyframes
  {
    right: '-600px'
  }, {
    right: '0px'
  }], {
    // timing options
    duration: animationTimeEnter,
    easing: 'ease-in-out',
    fill: 'forwards'
  });
  return new Promise(resolve => setTimeout(resolve, animationTimeEnter));
}
export async function hide(toast: Toast) {
  const $toastElement = toast.$ref.value;
  if (!$toastElement) {
    return;
  }
  $toastElement.animate([
  // keyframes
  {
    right: '0px'
  }, {
    right: '-600px'
  }], {
    // timing options
    duration: animationTimeExit,
    easing: 'ease-in-out',
    fill: 'forwards'
  });
  return new Promise(resolve => setTimeout(resolve, animationTimeExit));
}