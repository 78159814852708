import versionFactory from '../versions/Factory.js';
import { MicrofrontendObservedAttributes, MicrofrontendProvided, MicrofrontendApplicationModule } from './types.js';
import { Version } from '../versions/Version.js';
import { MicrofrontendDependency } from '../../../pdr/types.js';
const noop = (() => {}) as () => string | HTMLElement;
export type ImplementationConfig = Partial<{
  tag: string;
  version: string;
  application(): Promise<MicrofrontendApplicationModule>;
  placeholder: () => string | HTMLElement;
  methods: Record<string, (...args: unknown[]) => unknown>;
  dependencies: MicrofrontendDependency[];
  style: CSSStyleDeclaration;
  provide: MicrofrontendProvided;
  root: string | boolean;
  attributes: MicrofrontendObservedAttributes;
  cleanupDelay: number;
}>;
export class Implementation {
  config: ImplementationConfig;
  tag: string;
  version: Version;
  application: () => Promise<MicrofrontendApplicationModule>;
  placeholder: () => string | HTMLElement;
  methods: Record<string, (...args: unknown[]) => unknown>;
  dependencies: MicrofrontendDependency[];
  style: {
    display: string;
  } & CSSStyleDeclaration;
  provide: MicrofrontendProvided;
  root: string | boolean;
  attributes: MicrofrontendObservedAttributes;
  useMutationObserverForAttributes: boolean;
  cleanupDelay: number;
  constructor(config: ImplementationConfig) {
    this.validate(config);
    this.config = config;
    this.tag = config.tag!;
    this.version = versionFactory.create(config.version!);
    this.application = config.application!;
    this.placeholder = config.placeholder || noop;
    this.methods = config.methods || {};
    this.dependencies = config.dependencies || [];
    this.style = Object.assign({
      display: 'block '
    }, config.style);
    this.provide = config.provide || {};
    this.root = typeof config.root === 'undefined' ? true : config.root;
    this.attributes = config.attributes || [];
    this.useMutationObserverForAttributes = !config.attributes;
    this.cleanupDelay = typeof config.cleanupDelay === 'number' ? config.cleanupDelay : 30_000;
  }
  validate(config: ImplementationConfig) {
    this.validationError(!config.tag, 'tag is required');
    this.validationError(!config.application || !(config.application instanceof Function), 'application is required and has to be a function returning a Promise');
    this.validationError(!config.version, 'version is required');
    this.validationError(!!(config.placeholder && typeof config.placeholder !== 'function'), 'placeholder has to be a function returning a DOM node');
    this.validationError(!!(config.dependencies && !Array.isArray(config.dependencies)), 'dependencies has to be a array');
    this.validationError(!!(config.attributes && !Array.isArray(config.attributes)), 'attributes has to be a array');
  }
  validationError(failed: boolean, message: string) {
    if (failed) {
      throw new Error(`Bad microfrontend implementation: ${message}`);
    }
  }
}