import { html } from 'lit';
import type { SlotTemplateResult } from '../types.js';
import type { OneUxCardElement } from '../OneUxCardElement.js';
import { classMap } from 'lit/directives/class-map.js';
import { toSlotTemplateResult } from '../toSlotTemplateResult.js';
export function VerticalContainerFragment(this: OneUxCardElement, results: SlotTemplateResult[]): SlotTemplateResult {
  const hasSlot = results.some(({
    hasSlot
  }) => hasSlot);
  const template = html`<div
    class=${classMap({
    'card-element': true,
    'vertical-container': true,
    'has-slot': hasSlot
  })}
  >
    ${results}
  </div>`;
  return toSlotTemplateResult(template, hasSlot);
}