import { LanguageSets, getLangCode, langCode } from '../../one-ux/utils/getLangCode.js';
export type LanguageSet = {
  copy: string;
  copied: string;
};
export type LanguageSetConfig = {
  translations: LanguageSet;
  lang: langCode;
};
const languages: LanguageSets<LanguageSet> = {
  en: {
    copy: 'Copy',
    copied: 'Copied!'
  },
  sv: {
    copy: 'Kopiera',
    copied: 'Kopierad!'
  },
  nb: {
    copy: 'Kopiere',
    copied: 'Kopiert!'
  },
  fi: {
    copy: 'Kopio',
    copied: 'Kopioitu!'
  },
  da: {
    copy: 'Kopi',
    copied: 'Kopieret!'
  }
};
export const getLanguage = (reference?: Element): LanguageSetConfig => {
  const element = reference || document.documentElement;
  const closestLang = getLangCode(element);
  return {
    translations: languages[closestLang],
    lang: closestLang
  };
};