import { html } from 'lit';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { Label } from '../../mixins/Label.js';
import { property } from 'lit/decorators.js';
import { getLanguage } from './language.js';
import { register as _registerElement } from "../one-ux-widget-button/register-element.js";
_registerElement("widget-button-3c0871dde32b8f90168f7d57fc1e5b2c");
const Styled = StyledFactory(style);
const BaseClass = Label(Styled(OneUxElement));
export class OneUxFeedbackElement extends BaseClass {
  static get elementType() {
    return 'one-ux-feedback';
  }
  @property({
    type: Boolean
  })
  public accessor dismissible!: boolean;
  protected guardedRender() {
    const {
      translations
    } = getLanguage(this);
    return html`
      <div class="one-ux-element--root" role="alert">
        <slot name="start"></slot>
        <div class="label">${this.label}</div>
        <slot name="end"></slot>
        ${this.dismissible ? html`<widget-button-3c0871dde32b8f90168f7d57fc1e5b2c
              accessible
              label=${translations.close}
              icon="close"
              color="gray-100"
              implicit
              @click=${this.#handleClick}
            ></widget-button-3c0871dde32b8f90168f7d57fc1e5b2c>` : null}
      </div>
    `;
  }
  #handleClick = () => {
    this.dispatchEvent(new Event('dismiss'));
  };
}