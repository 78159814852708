import { LanguageSets, getLangCode, langCode } from '../../utils/getLangCode.js';
export type LanguageSet = {
  close: string;
};
export type LanguageSetConfig = {
  translations: LanguageSet;
  lang: langCode;
};
const languages: LanguageSets<LanguageSet> = {
  en: {
    close: 'Close'
  },
  sv: {
    close: 'Stäng'
  },
  nb: {
    close: 'Lukk'
  },
  fi: {
    close: 'Kiinni'
  },
  da: {
    close: 'Tæt'
  }
};
export const getLanguage = (reference?: Element): LanguageSetConfig => {
  const element = reference || document.documentElement;
  const closestLang = getLangCode(element);
  return {
    translations: languages[closestLang],
    lang: closestLang
  };
};