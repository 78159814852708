import type { TemplateResult } from 'lit';
import type { GroupData, ItemData, OptionData } from '../types.js';
import { Divider } from './Divider.js';
import { Group } from './Group.js';
import { Option } from './Option.js';
import { OneUxMenuElement } from '../OneUxMenuElement.js';
export type MenuItemOptions<T extends ItemData> = {
  item: T;
  itemTypeIndex: number;
};
export function MenuItem(this: OneUxMenuElement, options: MenuItemOptions<ItemData>): TemplateResult {
  switch (options.item.type) {
    case 'divider':
      return Divider();
    case 'group':
      return Group.call(this, options as MenuItemOptions<GroupData>);
    default:
      return Option.call(this, options as MenuItemOptions<OptionData>);
  }
}