import { PDRModule } from '../PDRModule.js';
import { CancellationToken } from './CancellationToken.js';
import { HttpClient, HTTPMethod } from './HttpClient.js';
import { ApiErrors, RequestError } from './ApiErrors.js';
import { HttpResult } from './HttpResult.js';
import { ApiSettings } from './ApiSettings.js';
import { PDRLibrary } from '../PDRLibrary.js';
import { User } from 'oidc-client';
import { log } from '../log.js';
export type ApiBuildOptions = {
  scopes: string[];
  headers: Record<string, string>;
  responseCallbacks: ((response: HttpResult) => void)[];
};
export type ApiModuleConstructorOptions = ApiBuildOptions & {
  local: boolean;
  started: Promise<PDRLibrary> | null;
  configured: Promise<ApiSettings> | null;
  initialized: Promise<[PDRLibrary, ApiSettings]> | null;
};
export class ApiModule extends PDRModule<ApiSettings> {
  #responseCallbacks = [] as ((response: HttpResult) => void)[];
  #httpClient = new HttpClient();
  #local = false;
  #headers = {} as Record<string, string>;
  #scopes = [] as string[];
  constructor({
    scopes = [],
    headers = {},
    responseCallbacks = [],
    local = false,
    started = null,
    configured = null,
    initialized = null
  } = {} as ApiModuleConstructorOptions) {
    super(ApiSettings.build);
    this.#local = local;
    Object.assign(this.#headers, headers);
    this.#scopes.push(...scopes);
    this.#responseCallbacks.push(...responseCallbacks.filter(c => typeof c === 'function'));
    if (local) {
      this._started = started!;
      this._configured = configured!;
      this._initialized = initialized!;
    }
  }
  get(url: string, options = {}) {
    return this.request('GET', url, options);
  }
  delete(url: string, options = {}) {
    return this.request('DELETE', url, options);
  }
  post(url: string, data?: unknown, options = {}) {
    return this.request('POST', url, Object.assign({}, options, {
      data
    }));
  }
  put(url: string, data?: unknown, options = {}) {
    return this.request('PUT', url, Object.assign({}, options, {
      data
    }));
  }
  patch(url: string, data: unknown, options = {}) {
    return this.request('PATCH', url, Object.assign({}, options, {
      data
    }));
  }
  async download(url: string, {
    method = 'GET' as HTTPMethod,
    fileName = '',
    headers = {},
    cancellationToken = null
  } = {}) {
    const httpResult = await this.#request(method, url, {
      options: {
        responseType: 'blob'
      },
      headers,
      cancellationToken
    });
    fileName = fileName.length ? fileName : getFileNameFromContentDispositionHeader(httpResult);
    const {
      utils
    } = await this._started;
    utils.download(httpResult.response, fileName);
  }
  async request(method: HTTPMethod, url: string, {
    data = null as unknown,
    headers = {},
    options = {},
    cancellationToken = null
  } = {}) {
    const httpResult = await this.#request(method, url, {
      data,
      headers,
      options,
      cancellationToken
    });
    return httpResult.response;
  }
  async #request(method: HTTPMethod, url: string, {
    data = null as unknown,
    headers = {},
    options = {},
    cancellationToken = null
  } = {}) {
    const [{
      session
    }, config] = await this._initialized;
    const user = await session.getUser(false, this.#scopes);
    if (!user) {
      throw ApiErrors.error('No user found in session, request failed');
    }
    const consolidatedHeaders = this.#consolidateHeaders(user, config, headers);
    let httpResult;
    try {
      try {
        httpResult = await this.#httpClient.send(method, url, {
          data,
          headers: consolidatedHeaders,
          options,
          cancellationToken
        });
      } catch (e: unknown) {
        const error = e as RequestError;
        if (config.loginAndRetryCodes.includes(error.status)) {
          const user = await session.getUser(true, this.#scopes);
          httpResult = await this.#httpClient.send(method, url, {
            data,
            headers: Object.assign({}, consolidatedHeaders, {
              Authorization: `Bearer ${user.access_token}`
            }),
            options,
            cancellationToken
          });
        } else {
          throw error;
        }
      }
    } catch (e: unknown) {
      const error = e as RequestError;
      if (error.result instanceof HttpResult) {
        httpResult = error.result;
      }
      throw error;
    } finally {
      this.#executeResponseCallbacks(httpResult);
    }
    return httpResult;
  }
  createCancellationToken() {
    return new CancellationToken();
  }
  build({
    scopes = [],
    headers = {},
    responseCallbacks = []
  } = {} as ApiBuildOptions) {
    const api = new ApiModule({
      scopes,
      headers,
      responseCallbacks,
      local: true,
      started: this._started,
      configured: this._configured,
      initialized: this._initialized
    });
    return api;
  }
  withScopes(...scopes: string[]) {
    const headers = this.#headers;
    const responseCallbacks = this.#responseCallbacks;
    return this.build({
      scopes,
      headers,
      responseCallbacks
    });
  }
  withHeaders(headers: Record<string, string>) {
    const scopes = this.#scopes;
    const responseCallbacks = this.#responseCallbacks;
    return this.build({
      scopes,
      headers,
      responseCallbacks
    });
  }
  addResponseCallback(callback: (response: HttpResult) => void) {
    if (typeof callback !== 'function') {
      throw ApiErrors.error(`Invalid request callback with type ${typeof callback}`);
    }
    if (!this.#local) {
      throw ApiErrors.error('Operation is only available on local PDR.api instances.');
    }
    this.#responseCallbacks.push(callback);
    return this;
  }
  removeResponseCallback(callback: (response: HttpResult) => void) {
    const index = this.#responseCallbacks.indexOf(callback);
    if (index >= 0) {
      this.#responseCallbacks.splice(index, 1);
    } else {
      log.warning('Could not find and remove response callback.');
    }
    return this;
  }
  clearResponseCallbacks() {
    if (!this.#local) {
      return;
    }
    this.#responseCallbacks.length = 0;
    return this;
  }
  #consolidateHeaders(user: User, config: ApiSettings, headers: Record<string, string>) {
    return Object.assign({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      Authorization: `Bearer ${user.access_token}`
    }, config.extraHeaders, this.#headers, headers);
  }
  #executeResponseCallbacks(httpResult?: HttpResult) {
    const callbacks = [...this.#responseCallbacks];
    if (httpResult) {
      callbacks.forEach(callback => {
        try {
          callback(httpResult);
        } catch {
          log.warning('Could not execute request callback.');
        }
      });
    }
    callbacks.length = 0;
  }
}
function getFileNameFromContentDispositionHeader(httpResult: HttpResult) {
  if (!Object.prototype.hasOwnProperty.call(httpResult.headers, 'content-disposition')) {
    throw new Error('Couldn\'t access header "Content-Disposition". Make sure "Content-Disposition" is provided by the server and is exposed to CORS-requests by adding "Content-Disposition" to the header "Access-Control-Expose-Headers".');
  }
  const disposition = httpResult.headers['content-disposition'] || '';
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
  let fileNameFromDisposition = '';
  if (utf8FilenameRegex.test(disposition)) {
    fileNameFromDisposition = decodeURIComponent(utf8FilenameRegex.exec(disposition)![1]);
  } else {
    const matches = asciiFilenameRegex.exec(disposition);
    if (matches != null && matches[2]) {
      fileNameFromDisposition = matches[2];
    }
  }
  if (!fileNameFromDisposition.length) {
    throw new Error('The "Content-Disposition" header does not include a file name.');
  }
  return fileNameFromDisposition;
}