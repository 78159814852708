import { InputTab, InternalTab, InternalSubtab, InternalTabBase } from '../types.js';
export const mapState = (tabs: InputTab[], activeTab: string): InternalTab[] => {
  const _tabs = tabs.map((tab, index) => {
    const _tab: InternalTab = {
      ...toInternalTab(tab, 'parent', `tab-${index + 1}`),
      subtabs: []
    };
    _tab.subtabs = mapSubtabs(tab, _tab);
    return _tab;
  });
  setActiveTab(_tabs, activeTab);
  return _tabs;
};
export const mapSubtabs = (parentTab: InputTab, _parentTab: InternalTab): InternalSubtab[] => {
  return (parentTab.tabs ?? []).map((tab, index) => {
    return {
      ...toInternalTab(tab, 'sub', `${_parentTab.id}-${index + 1}`),
      parent: _parentTab
    };
  });
};
const toInternalTab = (tab: InputTab, type: 'parent' | 'sub', id: string): InternalTabBase => ({
  type: type,
  id: tab.slot ?? id,
  text: tab.text,
  isIconOnly: !tab.text?.length && !!tab.icon?.name?.length,
  label: tab.text ?? tab.icon?.text ?? '',
  disabled: tab.disabled ?? false,
  icon: tab.icon != null ? {
    ...tab.icon
  } : undefined,
  pill: tab.pill != null ? {
    ...tab.pill
  } : undefined,
  isActive: false
});
const setActiveTab = (tabs: InternalTab[], activeTab: string) => {
  for (const tab of tabs) {
    const subtabs = tab.subtabs;
    if (tab.id === activeTab) {
      tab.isActive = true;
      if (subtabs.length) {
        subtabs[0].isActive = true;
      }
      return;
    }
    for (const subtab of subtabs) {
      if (subtab.id === activeTab) {
        tab.isActive = true;
        subtab.isActive = true;
        return;
      }
    }
  }
  const tab = tabs.find(tab => !tab.disabled);
  if (tab) {
    tab.isActive = true;
    if (tab.subtabs.length) {
      tab.subtabs[0].isActive = true;
    }
  }
};