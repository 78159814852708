import { html, nothing } from 'lit';
import type { OneUxCardElement } from '../OneUxCardElement.js';
import { getLangCode } from '../../../utils/getLangCode.js';
import { strings } from '../language.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { register as _registerElement } from "../../one-ux-button/register-element.js";
import { register as _registerElement2 } from "../../one-ux-icon/register-element.js";
import type { SlotTemplateResult } from '../types.js';
import { toSlotTemplateResult } from '../toSlotTemplateResult.js';
import { classMap } from 'lit/directives/class-map.js';
_registerElement2("icon-9de0edc06b762e963df9c117e1512e66");
_registerElement("button-05d875a8b4061990886e9d2e6deca082");
export function SlotItemsEndFragment(this: OneUxCardElement): SlotTemplateResult {
  const hasSlotItemsEnd = this._hasSlot('items-end');
  const hasSlotExpandableContent = this._hasSlot('expandable-content');
  const hasSlot = hasSlotItemsEnd || hasSlotExpandableContent;
  const lang = getLangCode(this);
  const {
    close,
    expand
  } = strings[lang];
  const template = html` <div
    class=${classMap({
    'card-element': true,
    'items-end-parent': true,
    'has-slot': hasSlot
  })}
  >
    <slot
      name="items-end"
      class=${classMap({
    'card-element': true,
    'has-slot': hasSlotItemsEnd
  })}
      tabindex=${ifDefined(this.busy ? -1 : undefined)}
    ></slot>

    ${hasSlotExpandableContent ? html`<button-05d875a8b4061990886e9d2e6deca082
          class="card-toggle-button"
          ?disabled=${this.busy}
          implicit
          weight="low"
          label=${`${this.open ? close : expand}: ${this.label}`}
          hide-tooltip
          @click=${() => this.open = !this.open}
        >
          <icon-9de0edc06b762e963df9c117e1512e66 icon=${this.open ? 'toggle-up' : 'toggle-down'}></icon-9de0edc06b762e963df9c117e1512e66>
        </button-05d875a8b4061990886e9d2e6deca082>` : nothing}
  </div>`;
  return toSlotTemplateResult(template, hasSlot);
}