import type { OneUxGadgetElement } from './OneUxGadgetElement.js';
import { getCursorPosition } from '../../utils/mouse-helper.js';
import { startUpdatingAncestorScrollbarsByCursorPosition } from '../../utils/update-ancestor-scrollbars-by-cursor-position.js';
import { clamp } from '../../visualizations/common/math.js';
export function handleMouseResize(this: OneUxGadgetElement) {
  if (!this._hasDashboardParent || !this.resizable) {
    return;
  }
  const startingRect = this.getBoundingClientRect();
  const startingMouse = getCursorPosition();
  this._draggingState = {
    width: startingRect.width,
    height: startingRect.height,
    top: startingRect.top,
    left: startingRect.left
  };
  const gap = this._dashboardContext.gap;
  const columnWidth = (startingRect.width - gap * (this.columnSpan - 1)) / this.columnSpan;
  const rowHeight = this._dashboardContext.rowHeight;
  const dashboardRect = this.parentElement!.getBoundingClientRect();
  const currentColumnIndex = Math.round((startingRect.left - dashboardRect.left) / (columnWidth + gap));
  const maxColumnSpanOrUntilEndOfRow = Math.min(this.maxColumnSpan, this._dashboardContext.columns - currentColumnIndex);
  let newColumnSpan = this.columnSpan;
  let newRowSpan = this.rowSpan;
  const resizing = () => {
    const currentMouse = getCursorPosition();
    const currentRect = this.getBoundingClientRect();
    const deltaMouseX = currentMouse.cursorX - startingMouse.cursorX;
    const deltaMouseY = currentMouse.cursorY - startingMouse.cursorY;
    const scrollOffsetX = startingRect.left - currentRect.left;
    const scrollOffsetY = startingRect.top - currentRect.top;
    const width = startingRect.width + deltaMouseX + scrollOffsetX;
    const height = startingRect.height + deltaMouseY + scrollOffsetY;
    const maxWidth = maxColumnSpanOrUntilEndOfRow * columnWidth + (maxColumnSpanOrUntilEndOfRow - 1) * gap;
    const maxHeight = this.maxRowSpan * rowHeight + (this.maxRowSpan - 1) * gap;
    const newWidth = clamp(width, columnWidth, maxWidth);
    const newHeight = clamp(height, rowHeight, maxHeight);
    const columnSpanFromWidth = Math.ceil((newWidth + 0.5 * gap) / (columnWidth + gap));
    const rowSpanFromHeight = Math.ceil((newHeight + 0.5 * gap) / (rowHeight + gap));
    newColumnSpan = clamp(columnSpanFromWidth, 1, maxColumnSpanOrUntilEndOfRow);
    newRowSpan = clamp(rowSpanFromHeight, 1, this.maxRowSpan);
    this._draggingState = {
      ...this._draggingState!,
      top: currentRect.top,
      left: currentRect.left,
      width: newWidth,
      height: newHeight,
      columnSpan: newColumnSpan,
      rowSpan: newRowSpan
    };
  };
  document.addEventListener('mousemove', resizing, {
    capture: true
  });
  document.addEventListener('scroll', resizing, {
    capture: true
  });
  const {
    stopUpdatingAncestorScrollbars
  } = startUpdatingAncestorScrollbarsByCursorPosition(this);
  document.addEventListener('mouseup', () => {
    this._draggingState = null;
    document.removeEventListener('mousemove', resizing, {
      capture: true
    });
    document.removeEventListener('scroll', resizing, {
      capture: true
    });
    stopUpdatingAncestorScrollbars();
    this._resize(newColumnSpan, newRowSpan);
  }, {
    capture: true,
    once: true
  });
}