import { OneUxElement } from '../../OneUxElement.js';
import { PropertyValueMap, html } from 'lit';
import { property, query } from 'lit/decorators.js';
import { live } from 'lit/directives/live.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { style } from './style.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { Implicit } from '../../mixins/Implicit.js';
import { Focusable } from '../../mixins/Focusable.js';
import { Disabled } from '../../mixins/Disabled.js';
import { DelegateAria } from '../../mixins/DelegateAria.js';
import { FormAssociatedFactory } from '../../mixins/FormAssociated.js';
import { InternalValueChangedEvent } from '../../events/internal/InternalValueChangedEvent.js';
import { IValue, ValueFactory } from '../../mixins/Value.js';
import { Checked, IChecked } from '../../mixins/Checked.js';
import { consume } from '@lit/context';
import { labelContext, defaultLabelContext } from '../../contexts/LabelContext.js';
const Styled = StyledFactory(style);
type valueType = unknown;
const Value = ValueFactory<valueType, IChecked & IValue<unknown> & {
  indeterminate: boolean;
}>({
  getter: function () {
    if (this.checked && !this.indeterminate) {
      return this.internalValue;
    }
    return null;
  }
});
const FormAssociated = FormAssociatedFactory<valueType, IChecked & {
  _oldIndeterminate: boolean;
  indeterminate: boolean;
}>({
  reset: function () {
    this.indeterminate = this._oldIndeterminate;
    this.checked = this.initialChecked;
  }
});
const Purpose = PurposeFactory({
  purposes: ['default', 'main', 'caution', 'notice']
});
const BaseClass = FormAssociated(Value(Checked(DelegateAria(Disabled(Focusable(Implicit(Purpose(Styled(OneUxElement)))))))));
export class OneUxCheckboxElement extends BaseClass {
  static get elementType() {
    return 'one-ux-checkbox';
  }
  @consume({
    context: labelContext,
    subscribe: true
  })
  labelContext = defaultLabelContext;
  @property({
    type: Boolean
  })
  public accessor indeterminate = false;
  protected willUpdate(changed: PropertyValueMap<this>): void {
    const hasIndeterminate = changed.has('indeterminate');
    const hasChecked = changed.has('checked');
    const hasValue = changed.has('value');
    if (typeof this._oldIndeterminate === 'undefined' && hasIndeterminate) {
      this._oldIndeterminate = this.indeterminate;
    }
    if (hasIndeterminate || hasChecked || hasValue) {
      this.dispatchEvent(new InternalValueChangedEvent());
    }
  }
  private _oldIndeterminate?: boolean;
  constructor() {
    super();
    this.addEventListener('focus', () => {
      // Webkit bug where the focus is not delegated properly in a delegateFocus chain (A delegates to B delegates to C)
      if (!this.shadowRoot?.activeElement) {
        this.shadowRoot?.querySelector('input')?.focus();
      }
    });
  }
  protected render() {
    const label = this.labelContext.label || undefined;
    return html`
      <input
        ${this._ariaTarget()}
        type="checkbox"
        .checked=${live(this.checked)}
        .indeterminate=${live(this.indeterminate)}
        aria-disabled=${this.disabled}
        aria-label=${ifDefined(label)}
        @input=${this.#handleInput}
      />
    `;
  }
  @query('input')
  accessor _checkboxElement!: HTMLInputElement;
  click() {
    if (this._checkboxElement) {
      this._checkboxElement.click();
    }
  }
  #handleInput = (event: Event) => {
    const $target = event.target as HTMLInputElement;
    event.stopPropagation();
    const beforeInputEvent = new InputEvent('beforeinput', {
      bubbles: true,
      composed: true,
      cancelable: true,
      data: $target.checked as never as string
    });
    if (this.dispatchEvent(beforeInputEvent)) {
      if (this.indeterminate) {
        this.checked = true;
        this.indeterminate = false;
      } else {
        this.checked = !this.checked;
      }
      this.dispatchEvent(new Event('input'));
      this.dispatchEvent(new Event('change'));
    } else {
      this.requestUpdate();
    }
  };
}