import { PropertyValues, html } from 'lit';
import { property } from 'lit/decorators.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { Focusable } from '../../mixins/Focusable.js';
import { IValue, ValueFactory } from '../../mixins/Value.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { Disabled } from '../../mixins/Disabled.js';
import { Placeholder } from '../../mixins/Placeholder.js';
import { classMap } from 'lit/directives/class-map.js';
import { DelegateAria } from '../../mixins/DelegateAria.js';
import { IRequired, Required } from '../../mixins/Required.js';
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../../mixins/Validated.js';
import { Readonly } from '../../mixins/Readonly.js';
const Styled = StyledFactory(style);
type valueType = string;
const Value = ValueFactory<valueType>({
  type: String
});
const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult;
    }
    const {
      fieldIsRequired
    } = getFormValidationLanguage(this);
    const valid = hasValue(this.value);
    return {
      valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldIsRequired]
    };
  }
});
function hasValue(value: unknown) {
  return typeof value === 'string' ? !!value : value != null;
}
const BaseClass = Readonly(Validated(Required(DelegateAria(Placeholder(Disabled(Value(Focusable(Styled(OneUxElement)))))))));
export class OneUxEditableTextElement extends BaseClass {
  static get elementType() {
    return 'one-ux-editable-text';
  }
  @property({
    attribute: 'enable-line-breaks',
    type: Boolean
  })
  accessor enableLineBreaks = false;
  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('value') || changed.has('enableLineBreaks')) {
      if (!this.enableLineBreaks) {
        const scrubbed = this.value?.replace(/\r?\n/g, '');
        if (this.value !== scrubbed) {
          this.value = scrubbed;
        }
      }
    }
  }
  #valueBeforeChange: string;
  render() {
    return html`<div
      class=${classMap({
      'one-ux-element--root': true,
      'resize-container': true,
      empty: !this.value && !this.placeholder
    })}
      replicated-value="${ifDefined(this.value || this.placeholder)}"
    >
      <textarea
        ${this._ariaTarget()}
        aria-multiline="${this.enableLineBreaks}"
        placeholder=${ifDefined(this.placeholder)}
        ?disabled=${this.disabled}
        ?readonly=${this.readonly}
        .value=${live(this.value || '')}
        @input=${(e: InputEvent) => {
      e.stopPropagation();
      const $textarea = e.target as HTMLTextAreaElement;
      this.#executeInputProcedure($textarea);
    }}
        @beforeinput=${(e: InputEvent) => {
      e.stopPropagation();
      if (this.enableLineBreaks) {
        return;
      }
      if (e.inputType === 'insertLineBreak' || e.inputType === 'insertParagraph') {
        e.preventDefault();
      }
    }}
        @paste=${(e: ClipboardEvent) => {
      e.preventDefault();
      if (this.disabled || this.readonly) {
        return;
      }
      const data = e.clipboardData?.getData('text/plain');
      const rawText = data || '';
      const text = this.enableLineBreaks ? rawText : rawText.replace(/\r?\n/g, ' ');
      const $textarea = e.target as HTMLTextAreaElement;
      $textarea.setRangeText(text, $textarea.selectionStart, $textarea.selectionEnd, 'end');
      this.#executeInputProcedure($textarea);
    }}
        @focus=${() => {
      this.#valueBeforeChange = this.value;
    }}
        @blur=${() => {
      if (this.#valueBeforeChange !== this.value) {
        this.dispatchEvent(new Event('change', {
          bubbles: true,
          composed: true
        }));
      }
      this.#valueBeforeChange = undefined;
    }}
      ></textarea>
    </div>`;
  }
  #executeInputProcedure($textarea: HTMLTextAreaElement) {
    const beforeInputEvent = new InputEvent('beforeinput', {
      bubbles: true,
      composed: true,
      cancelable: true,
      data: $textarea.value
    });
    if (this.dispatchEvent(beforeInputEvent)) {
      this._applyUserValue($textarea.value);
      const inputEvent = new InputEvent('input', {
        bubbles: true,
        composed: true,
        cancelable: false
      });
      this.dispatchEvent(inputEvent);
    } else {
      $textarea.value = this.value ?? '';
    }
  }
}