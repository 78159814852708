import { CancellationToken } from './CancellationToken.js';
import { HttpResult } from './HttpResult.js';
import { ApiErrors } from './ApiErrors.js';
export type HTTPMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'HEAD' | 'PATCH';
export type Options = {
  data: unknown;
  headers: Record<string, string>;
  options: Record<string, string>;
  cancellationToken: CancellationToken | null;
};
export class HttpClient {
  async send(method: HTTPMethod, url: string, {
    data = null,
    headers = {},
    options = {},
    cancellationToken = null
  } = {} as Partial<Options>) {
    const isValidCancellationToken = cancellationToken instanceof CancellationToken;
    return new Promise<HttpResult>((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open(method, url);
      if (headers) {
        Object.keys(headers).forEach(key => {
          request.setRequestHeader(key, headers[key]);
        });
      }
      if (options) {
        if (typeof options.responseType === 'string') {
          request.responseType = options.responseType as XMLHttpRequestResponseType;
        }
        if (typeof options.stream === 'function') {
          let charactersLoaded = 0;
          request.addEventListener('readystatechange', () => {
            if (request.readyState === XMLHttpRequest.LOADING || request.readyState === XMLHttpRequest.DONE) {
              const callback = options.stream as any;
              callback(request.responseText.substring(charactersLoaded));
              charactersLoaded = request.responseText.length;
            }
          });
        }
      }
      if (isValidCancellationToken) {
        const cancel = () => {
          request.abort();
          reject(ApiErrors.requestCancelled());
        };
        cancellationToken.addCallback(cancel);
        request.addEventListener('loadend', () => {
          cancellationToken.removeCallback(cancel);
        });
      }
      request.addEventListener('load', () => {
        const result = new HttpResult(request);
        if (result.successful) {
          resolve(result);
        } else {
          reject(ApiErrors.requestErrorResponse(result));
        }
      });
      request.addEventListener('error', error => {
        reject(ApiErrors.requestFailed(error));
      });
      if (data === null) {
        request.send();
      } else if (data instanceof FormData || data instanceof Blob) {
        request.send(data);
      } else {
        request.setRequestHeader('Content-Type', 'application/json');
        request.send(JSON.stringify(data));
      }
    });
  }
}