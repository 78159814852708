import { OneUxElement } from '../../OneUxElement.js';
import { delegateAria } from '../../mixins/DelegateAria.js';
import type { Optional } from '../../types.js';
import { hasMouseActivity } from '../../utils/mouse-helper.js';
import { OneUxTooltipElement } from './OneUxTooltipElement.js';
import { HideTooltipEvent } from './events/HideTooltipEvent.js';
import { ShowTooltipEvent } from './events/ShowTooltipEvent.js';
const LONG_PRESS_DELAY_MS = 1500;
const HIDE_TOOLTIP_DELAY_MS = 60;
const SHOW_TOOLTIP_DELAY_MS = 600;
export const SHORTHAND_ID = '__internal_one_ux_shorthand_tooltip__';
let latestTriggerType: 'keyboard' | 'cursor' = 'cursor';
let $openTooltipReference = null as Element | null;
const openToolTips = new Set<WeakRef<OneUxTooltipElement>>();
const isTouchOnly = window.matchMedia('(any-pointer: coarse)').matches && !window.matchMedia('(pointer: fine)').matches;
setUpTooltipEvents();
setUpTooltipCleanUp();
if (isTouchOnly) {
  setUpTouchEvents();
} else {
  setUpFocusHandling();
  setUpMouseEvents();
  setUpScrollEvents();
}
function setUpFocusHandling() {
  let pendingTooltip: ReturnType<typeof setTimeout>;
  let $previousActive = null as Element | null;
  const checkAndHandleFocus = () => {
    const $active = getActualFocus(document.activeElement);
    if ($active !== $previousActive) {
      closeOpenTooltip(true);
      if (!$active) {
        $previousActive = null;
      } else {
        clearTimeout(pendingTooltip);
        $previousActive = $active;
        if (!hasMouseActivity()) {
          const $target = findClosestTooltip($active);
          if ($target) {
            pendingTooltip = setTimeout(() => {
              dispatchShowTooltipEvent($target, 'keyboard');
              $target!.removeAttribute('one-ux-tooltip-pending');
            }, SHOW_TOOLTIP_DELAY_MS);
          }
        }
      }
    }
    requestAnimationFrame(checkAndHandleFocus);
  };
  requestAnimationFrame(checkAndHandleFocus);
}
function findClosestTooltip($el: Element) {
  let $target: Optional<Element>;
  const findClosestEvent = new Event('one-ux-tooltip-find-closest-tooltip', {
    composed: true,
    bubbles: true
  });
  $el.addEventListener(findClosestEvent.type, e => {
    e.stopImmediatePropagation();
    $target = findReference(e.composedPath());
  }, {
    once: true,
    capture: true
  });
  $el.dispatchEvent(findClosestEvent);
  return $target;
}
function setUpTouchEvents() {
  document.addEventListener('touchstart', handleTouchStart, {
    capture: true
  });
  document.addEventListener('touchend', handleTouchEnd, {
    capture: true
  });
  const longPress = {
    timeout: null as ReturnType<typeof setTimeout> | null,
    start: 0 as ReturnType<typeof performance.now>,
    $target: null as Element | null,
    shouldClose: false
  };
  function handleTouchStart(event: Event) {
    if (longPress.timeout) {
      clearTimeout(longPress.timeout);
    }
    const $reference = findReference(event.composedPath());
    if ($reference) {
      if (longPress.$target !== $reference) {
        longPress.$target?.dispatchEvent(new HideTooltipEvent());
      }
      longPress.$target = $reference;
      longPress.start = performance.now();
      longPress.timeout = setTimeout(() => {
        dispatchShowTooltipEvent($reference, 'cursor');
        longPress.shouldClose = false;
      }, LONG_PRESS_DELAY_MS);
    }
  }
  function handleTouchEnd(event: Event) {
    if (longPress.$target) {
      if (longPress.shouldClose) {
        longPress.$target?.dispatchEvent(new HideTooltipEvent());
      } else {
        longPress.shouldClose = true;
      }
    }
    if (performance.now() - longPress.start < LONG_PRESS_DELAY_MS) {
      if (longPress.timeout) {
        clearTimeout(longPress.timeout);
      }
    } else {
      const $target = findReference(event.composedPath());
      if ($target && $target === longPress.$target && event?.cancelable) {
        event.preventDefault();
      }
    }
  }
}
function setUpMouseEvents() {
  document.addEventListener('mousemove', handleMouseMove, {
    capture: true
  });
  document.addEventListener('mousedown', handleMouseDown, {
    capture: true
  });
  document.addEventListener('mouseenter', handleIframes, {
    capture: true
  });
  let pendingTooltip: ReturnType<typeof setTimeout>;
  let $previousMouseOver: undefined | EventTarget;
  let $previousReference: undefined | Element;
  function handleMouseMove(event: MouseEvent) {
    const path = event.composedPath();
    if (path[0] === $previousMouseOver) {
      return;
    } else {
      $previousMouseOver = path[0];
    }
    const $reference = findReference(path);
    if ($reference === $previousReference) {
      return;
    }
    clearTimeout(pendingTooltip);
    $previousReference = $reference;
    if ($reference) {
      pendingTooltip = setTimeout(() => {
        dispatchShowTooltipEvent($reference, 'cursor');
      }, SHOW_TOOLTIP_DELAY_MS);
    } else {
      closeOpenTooltip(true);
    }
  }
  function handleMouseDown(event: MouseEvent) {
    const $reference = findReference(event.composedPath());
    if ($reference !== $openTooltipReference) {
      closeOpenTooltip();
    }
  }
  function handleIframes(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target?.tagName === 'IFRAME') {
      clearTimeout(pendingTooltip);
      $previousMouseOver = undefined;
      $previousReference = undefined;
      closeOpenTooltip(true);
    }
  }
}
function setUpScrollEvents() {
  document.addEventListener('scroll', handleScroll, {
    capture: true
  });
  function handleScroll() {
    if (!$openTooltipReference) return;
    const $root = $openTooltipReference.getRootNode() as ShadowRoot | Document;
    const tooltipId = $openTooltipReference.getAttribute('one-ux-tooltip');
    if (!tooltipId) return;
    const $tooltip = $root.querySelector<OneUxTooltipElement>(tooltipId);
    if ($tooltip && $tooltip.useCursorPosition) {
      closeOpenTooltip();
    }
  }
}
function setUpTooltipEvents() {
  document.addEventListener<any>(ShowTooltipEvent.eventName, handleTooltipShow, {
    capture: true
  });
  document.addEventListener(HideTooltipEvent.eventName, handleTooltipHide, {
    capture: true
  });
  const shortHandReferenceObserver = new MutationObserver(() => {
    if (!$openTooltipReference) return;
    dispatchShowTooltipEvent($openTooltipReference, latestTriggerType);
  });
  const attachShortHandReferenceObserver = ($reference: Element, $host?: Element) => {
    shortHandReferenceObserver.disconnect();
    shortHandReferenceObserver.observe($reference, {
      attributeFilter: ['one-ux-tooltip'],
      attributeOldValue: true,
      attributes: true,
      childList: false,
      subtree: false,
      characterData: false
    });
    if ($host) {
      shortHandReferenceObserver.observe($host, {
        attributeFilter: ['one-ux-tooltip'],
        attributeOldValue: true,
        attributes: true,
        childList: false,
        subtree: false,
        characterData: false
      });
    }
  };
  function handleTooltipHide(event: Event) {
    shortHandReferenceObserver.disconnect();
    const $reference = findReference(event.composedPath());
    if (!$reference) {
      return;
    }
    const tooltipId = getTooltipId($reference);
    if (!tooltipId.length) {
      return;
    }
    handleAriaOnHide($reference);
    let $tooltip!: OneUxElement;
    if ($reference.shadowRoot) {
      $tooltip = $reference.shadowRoot.querySelector(tooltipId) as OneUxElement;
    }
    if (!$tooltip) {
      const $root = $reference.getRootNode() as ShadowRoot | Document;
      $tooltip = $root.querySelector(tooltipId) as OneUxElement;
    }
    if ($tooltip instanceof OneUxTooltipElement) {
      $tooltip.reference = undefined;
      $tooltip.visible = false;
      if ($tooltip.hasAttribute('one-ux-tooltip-clone')) {
        $tooltip.remove();
      }
    }
  }
  function handleAriaOnHide($reference: Element) {
    if ($reference.hasAttribute('one-ux-tooltip-custom-aria')) {
      return;
    }
    if ('delegateAria' in $reference) {
      $reference.delegateAria = {
        ...($reference.delegateAria as delegateAria),
        'aria-describedby': null
      };
    } else {
      if ($reference.hasAttribute('preserve-aria-describedby')) {
        $reference.removeAttribute('preserve-aria-describedby');
      } else {
        $reference.removeAttribute('aria-describedby');
      }
    }
  }
  function getOrCreateShorthandTooltip($root: Document | ShadowRoot): OneUxTooltipElement {
    let $tooltip = ($root instanceof ShadowRoot ? $root : document).querySelector<OneUxTooltipElement>(`#${SHORTHAND_ID}`);
    if (!$tooltip) {
      // @scoped-element-loader-ignore
      $tooltip = document.createElement('one-ux-tooltip');
      $tooltip.id = SHORTHAND_ID;
    }
    if ($root instanceof Document) {
      document.body.appendChild($tooltip);
    } else {
      $root.appendChild($tooltip);
    }
    return $tooltip;
  }
  function handleTooltipShow(event: ShowTooltipEvent) {
    closeOpenTooltip();
    const $reference = findReference(event.composedPath());
    if ($reference) {
      $openTooltipReference = $reference;
      const $root = $reference.getRootNode() as ShadowRoot | Document;
      const $host = $root instanceof ShadowRoot ? $root.host : undefined;
      const tooltipId = getTooltipId($reference);
      const tooltipText = getTooltipText($reference);
      const getComposedTooltipText = () => {
        const tooltip = $reference.getAttribute('one-ux-tooltip-text');
        const hostDescription = $host ? $host.getAttribute('one-ux-tooltip-text') || getTooltipText($host) : '';
        return hostDescription ? [tooltip, hostDescription].join('\n\n') : tooltip;
      };
      let $tooltip!: OneUxTooltipElement;
      if (tooltipId.length) {
        const $target = $root.querySelector(tooltipId);
        if ($target instanceof OneUxTooltipElement) {
          if (tooltipText.length && tooltipText !== $reference.getAttribute('one-ux-tooltip-text')) {
            $reference.setAttribute('one-ux-tooltip-text', tooltipText);
          }
          if ($reference.hasAttribute('one-ux-tooltip-text')) {
            $target.textContent = getComposedTooltipText();
          }
          if ($root instanceof Document) {
            if ('delegateAria' in $reference) {
              $tooltip = cloneTooltipToShadowDom($reference, tooltipId, $target, $tooltip);
            } else {
              $tooltip = $target;
              if ($target.id === SHORTHAND_ID) {
                document.body.appendChild($tooltip);
              }
            }
          } else {
            $tooltip = $target;
          }
        }
      } else {
        $tooltip = getOrCreateShorthandTooltip($root);
        $reference.toggleAttribute('one-ux-tooltip-shorthand', true);
        if (!$reference.hasAttribute('one-ux-tooltip-text')) {
          $reference.setAttribute('one-ux-tooltip-text', tooltipText);
        }
        $tooltip.textContent = getComposedTooltipText();
        $reference.setAttribute('one-ux-tooltip', `#${$tooltip.id}`);
      }
      if ($tooltip) {
        $tooltip.reference = $reference;
        $tooltip.visible = true;
        if ($tooltip.id === SHORTHAND_ID) {
          if ($reference.hasAttribute('one-ux-tooltip-placement')) {
            $tooltip.placement = $reference.getAttribute('one-ux-tooltip-placement') as any;
          } else {
            $tooltip.placement = undefined;
          }
          $tooltip.fixed = $reference.hasAttribute('one-ux-tooltip-fixed');
          $tooltip.useCursorPosition = event.triggerType === 'cursor' && $reference.hasAttribute('one-ux-tooltip-use-cursor-position');
        }
        handleAriaOnShow($reference, $tooltip);
        openToolTips.add(new WeakRef($tooltip));
      }
      attachShortHandReferenceObserver($reference, $host);
    }
  }
  function cloneTooltipToShadowDom($reference: Element, tooltipIdOrText: string, $target: OneUxTooltipElement, $tooltip: OneUxTooltipElement) {
    if (($reference.constructor as any)?.shadowRootOptions?.delegatesFocus) {
      const $localTarget = $reference.shadowRoot?.querySelector(tooltipIdOrText);
      if ($localTarget && $localTarget.hasAttribute('one-ux-tooltip-clone')) {
        $localTarget.remove();
      }
      const $clone = $target.cloneNode(true) as OneUxTooltipElement;
      $clone.toggleAttribute('one-ux-tooltip-clone', true);
      $reference.shadowRoot?.appendChild($clone);
      $tooltip = $clone;
    }
    return $tooltip;
  }
  function handleAriaOnShow($reference: Element, $tooltip: OneUxTooltipElement) {
    if ($reference.hasAttribute('one-ux-tooltip-custom-aria')) {
      return;
    }
    if ('delegateAria' in $reference) {
      $reference.delegateAria = {
        ...($reference.delegateAria as delegateAria),
        'aria-describedby': $tooltip.id
      };
    } else {
      if ($reference.hasAttribute('aria-describedby')) {
        $reference.toggleAttribute('preserve-aria-describedby', true);
      } else {
        $reference.setAttribute('aria-describedby', $tooltip.id);
      }
    }
  }
}
function setUpTooltipCleanUp() {
  const clean = () => {
    const toRemove = new Set<WeakRef<OneUxTooltipElement>>();
    for (const ref of openToolTips) {
      const $tooltip = ref.deref();
      if (!$tooltip) {
        // If an tooltip is not referenced it has been garbage-collected and is not considered open.
        toRemove.add(ref);
      } else {
        if (!$tooltip.isConnected || !$tooltip.reference || !$tooltip.visible) {
          // If a tooltip is no longer connected, does not have a reference or is not visible it removed from the open collection.
          toRemove.add(ref);
        } else if ($tooltip.reference && !$tooltip.reference.isConnected) {
          // If a tooltip's reference is no longer connected the tooltip is not considered open.
          $tooltip.reference = undefined;
          $tooltip.visible = false;
          toRemove.add(ref);
        }
      }
    }
    for (const ref of toRemove) {
      openToolTips.delete(ref);
    }
    requestAnimationFrame(clean);
  };
  requestAnimationFrame(clean);
}
function findReference(path: EventTarget[]) {
  while (path.length) {
    const $current = path.shift() as Element;
    if ($current instanceof Element && $current.hasAttribute('one-ux-tooltip')) {
      return $current;
    }
  }
}
function getActualFocus($element: Element | null) {
  if (!$element) {
    return null;
  }

  // Shorthand for when the active element is a web component and has the tooltip-definition
  if ($element.hasAttribute('one-ux-tooltip') && $element?.shadowRoot?.activeElement) {
    return $element;
  }
  let $focused = $element;
  // Drill down all activeElements across shadowRoot boundaries.
  while ($focused?.shadowRoot) {
    const $active = $focused?.shadowRoot?.activeElement;
    if (!$active) {
      break;
    }
    $focused = $active;
  }

  // Drill down all aria-activedescendant connections.
  let activeDescendant = $focused.getAttribute('aria-activedescendant') ?? '';
  if (activeDescendant) {
    let $descendant = $focused;
    while (activeDescendant) {
      const activeDescendantId = `#${activeDescendant}`;
      const $child = $descendant.querySelector(activeDescendantId);
      if ($child) {
        $descendant = $child;
        activeDescendant = $descendant?.getAttribute('aria-activedescendant') ?? '';
      } else {
        activeDescendant = '';
      }
    }
    $focused = $descendant;
  }
  return $focused;
}
function closeOpenTooltip(delay = false) {
  if ($openTooltipReference) {
    if (delay) {
      const $reference = $openTooltipReference;
      setTimeout(() => {
        $reference?.dispatchEvent(new HideTooltipEvent());
      }, HIDE_TOOLTIP_DELAY_MS);
    } else {
      $openTooltipReference?.dispatchEvent(new HideTooltipEvent());
    }
    $openTooltipReference = null;
  }
}
function getTooltipId($el: Element) {
  const tooltipId = $el.hasAttribute('one-ux-tooltip-shorthand') ? `#${SHORTHAND_ID}` : $el.getAttribute('one-ux-tooltip');
  if (tooltipId?.startsWith('#')) {
    return tooltipId;
  }
  return '';
}
function getTooltipText($el: Element) {
  const value = $el.getAttribute('one-ux-tooltip') ?? '';
  if (value.startsWith('#')) {
    return '';
  }
  return value;
}
function dispatchShowTooltipEvent($target: Node, triggerType: 'keyboard' | 'cursor') {
  latestTriggerType = triggerType;
  $target.dispatchEvent(new ShowTooltipEvent(triggerType));
}