import { LanguageSets } from '../../utils/getLangCode.js';
export type LanguageSet = {
  default: {
    new: string;
    ai: string;
    beta: string;
    preview: string;
  };
  activities: {
    notStarted: string;
    ongoing: string;
    done: string;
    doneWithDeviation: string;
    soonDelayed: string;
    notAccordingToPlan: string;
    paused: string;
    delayed: string;
  };
  measurements: {
    empty: string;
    fulfilled: string;
    mostlyFulfilled: string;
    partiallyFulfilled: string;
    marginallyFulfilled: string;
    unfulfilled: string;
  };
  features: {
    activity: string;
    forms: string;
    meetings: string;
    risk: string;
    report: string;
    doBoard: string;
    measurement: string;
    goal: string;
    processWheel: string;
  };
};
export const lang: LanguageSets<LanguageSet> = {
  en: {
    default: {
      new: 'NEW',
      ai: 'Stratsys AI',
      beta: 'BETA',
      preview: 'PREVIEW'
    },
    activities: {
      notStarted: 'Not started',
      ongoing: 'Ongoing',
      done: 'Done',
      doneWithDeviation: 'Done with deviation',
      soonDelayed: 'Soon delayed',
      notAccordingToPlan: 'Not according to plan',
      paused: 'Paused',
      delayed: 'Delayed'
    },
    measurements: {
      empty: 'None',
      fulfilled: 'Fulfilled',
      mostlyFulfilled: 'Mostly fulfilled',
      partiallyFulfilled: 'Partially fulfilled',
      marginallyFulfilled: 'Marginally fulfilled',
      unfulfilled: 'Unfulfilled'
    },
    features: {
      activity: 'Activity',
      forms: 'Forms',
      meetings: 'Meetings',
      risk: 'Risk',
      report: 'Report',
      doBoard: 'Do-Board',
      measurement: 'Measurement',
      goal: 'Goal',
      processWheel: 'Process wheel'
    }
  },
  sv: {
    default: {
      new: 'NY',
      ai: 'Stratsys AI',
      beta: 'BETA',
      preview: 'FÖRHANDSVISNING'
    },
    activities: {
      notStarted: 'Ej påbörjad',
      ongoing: 'Pågående',
      done: 'Klar',
      doneWithDeviation: 'Klar med avvikelse',
      soonDelayed: 'Snart försenad',
      notAccordingToPlan: 'Ej enligt plan',
      paused: 'Pausad',
      delayed: 'Försenad'
    },
    measurements: {
      empty: 'Ingen',
      fulfilled: 'Uppfylld',
      mostlyFulfilled: 'Mestadels uppfylld',
      partiallyFulfilled: 'Delvis uppfylld',
      marginallyFulfilled: 'Marginellt uppfylld',
      unfulfilled: 'Ej uppfylld'
    },
    features: {
      activity: 'Aktivitet',
      forms: 'Formulär',
      meetings: 'Meetings',
      risk: 'Risk',
      report: 'Rapport',
      doBoard: 'Do-Board',
      measurement: 'Mått',
      goal: 'Mål',
      processWheel: 'Årshjul'
    }
  },
  nb: {
    default: {
      new: 'NY',
      ai: 'Stratsys AI',
      beta: 'BETA',
      preview: 'FORHÅNDSVISNING'
    },
    activities: {
      notStarted: 'Ikke startet',
      ongoing: 'Pågående',
      done: 'Fullført',
      doneWithDeviation: 'Fullført med avvik',
      soonDelayed: 'Snart forsinket',
      notAccordingToPlan: 'Ikke i henhold til planen',
      paused: 'Pause',
      delayed: 'Forsinket'
    },
    measurements: {
      empty: 'Tom',
      fulfilled: 'Oppfylt',
      mostlyFulfilled: 'Mest oppfylt',
      partiallyFulfilled: 'Delvis oppfylt',
      marginallyFulfilled: 'Marginelt oppfylt',
      unfulfilled: 'Ikke oppfylt'
    },
    features: {
      activity: 'Aktivitet',
      forms: 'Skjema',
      meetings: 'Møter',
      risk: 'Risiko',
      report: 'Rapport',
      doBoard: 'Gjøre-Bord',
      measurement: 'Mål',
      goal: 'Mål',
      processWheel: 'Prosesshjul'
    }
  },
  fi: {
    default: {
      new: 'UUSI',
      ai: 'Stratsys AI',
      beta: 'BETA',
      preview: 'ESIKATSELU'
    },
    activities: {
      notStarted: 'Ei aloitettu',
      ongoing: 'Käynnissä',
      done: 'Valmis',
      doneWithDeviation: 'Valmis poikkeamalla',
      soonDelayed: 'Kohta myöhässä',
      notAccordingToPlan: 'Ei suunnitelman mukainen',
      paused: 'Keskeytetty',
      delayed: 'Myöhässä'
    },
    measurements: {
      empty: 'Tyhjä',
      fulfilled: 'Täytetty',
      mostlyFulfilled: 'Enimmäkseen täytetty',
      partiallyFulfilled: 'Osittain täytetty',
      marginallyFulfilled: 'Vähän täytetty',
      unfulfilled: 'Täyttämätön'
    },
    features: {
      activity: 'Toiminta',
      forms: 'Lomake',
      meetings: 'Kokoukset',
      risk: 'Riski',
      report: 'Raportti',
      doBoard: 'Do-board',
      measurement: 'Mittaa',
      goal: 'Tavoite',
      processWheel: 'Vuosipyörä'
    }
  },
  da: {
    default: {
      new: 'NY',
      ai: 'Stratsys AI',
      beta: 'BETA',
      preview: 'FORHÅNDSVISNING'
    },
    activities: {
      notStarted: 'Ikke påbegyndt',
      ongoing: 'I gang',
      done: 'Færdig',
      doneWithDeviation: 'Færdig med afvigelse',
      soonDelayed: 'Snart forsinket',
      notAccordingToPlan: 'Ikke i henhold til planen',
      paused: 'På pause',
      delayed: 'Forsinket'
    },
    measurements: {
      empty: 'Tom',
      fulfilled: 'Opfyldt',
      mostlyFulfilled: 'Stort set opfyldt',
      partiallyFulfilled: 'Delvist opfyldt',
      marginallyFulfilled: 'Marginalt opfyldt',
      unfulfilled: 'Uopfyldt'
    },
    features: {
      activity: 'Aktivitet',
      forms: 'Formular',
      meetings: 'Møder',
      risk: 'Risiko',
      report: 'Rapport',
      doBoard: 'Gør-Bræt',
      measurement: 'Mål',
      goal: 'Mål',
      processWheel: 'Proceshjul'
    }
  }
};