import { html } from 'lit';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { style as styleCard } from './contexts/card/style.js';
import { OpenClosedFactory } from '../../mixins/OpenClosed.js';
import { StackContextResolver } from './StackContextResolver.js';
import type { IResolveOpenAction } from './types.js';
const Styled = StyledFactory(style, styleCard);
const {
  Open
} = OpenClosedFactory<IResolveOpenAction>({
  type: 'open',
  async action() {
    return this.openAction();
  },
  beforeAction() {
    this.beforeOpenAction();
  }
});
const BaseClass = Open(Styled(OneUxElement));
export class OneUxStackElement extends BaseClass implements IResolveOpenAction {
  static get elementType() {
    return 'one-ux-stack';
  }
  constructor() {
    super();
    this.width = 'max';
  }
  beforeOpenAction(): void {
    this.#contextResolver.resolve().beforeOpenAction();
  }
  openAction() {
    return this.#contextResolver.resolve().openAction();
  }
  protected render() {
    const context = this.#contextResolver.resolve();
    context.onRender();
    return html`<div class="one-ux-element--root context-${context.contextType}">
      <slot></slot>
    </div>`;
  }
  #contextResolver = new StackContextResolver(this);
}