import { AvatarContextCacheRepository } from './AvatarContextCacheRepository.js';
type fullNameCacheEntry = {
  fullName: string;
  createdAt: string;
};
const EXPIRED_AFTER_MS = 1000 * 60 * 60 * 24 * 7; // one week

export class FullNameContextCacheRepository extends AvatarContextCacheRepository<fullNameCacheEntry> {
  constructor() {
    super('full-name', EXPIRED_AFTER_MS);
  }
  public get(tenantId: string, userId: string): {
    fullName: string;
    expired: boolean;
  } {
    const entry = this.getEntry(tenantId, userId);
    if (!entry) {
      return {
        fullName: '',
        expired: true
      };
    }
    const expired = this.hasExpired(entry);
    return {
      fullName: entry.fullName,
      expired
    };
  }
  public update(tenantId: string, userId: string, fullName: string) {
    this.setEntry(tenantId, userId, {
      fullName,
      createdAt: new Date().toISOString()
    });
  }
}