import { css } from 'lit';
export const style = css`
  :host {
    margin: var(--one-ux-element-internal--popout-constraint-indent);
  }

  .one-ux-element--root {
    display: flex;
    flex-direction: column;
    gap: var(--one-ux-spacing--200);
  }

  .datepickers-container {
    display: flex;
    gap: var(--one-ux-spacing--300);
  }

  .datepicker {
    display: flex;
    flex-direction: column;
    user-select: none;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: var(--one-ux-spacing--400);

    [one-ux-element='one-ux-dropdown'] {
      width: auto;
    }
  }

  .month-and-year {
    color: var(--one-ux-palette--brand-neutral-600);
    font: var(--one-ux-font--heading-200);
    letter-spacing: var(--one-ux-letter-spacing--heading-200);
    text-transform: capitalize;

    [one-ux-element='one-ux-dropdown'][disabled] > & {
      color: var(--one-ux-palette--gray-400);
    }
  }

  .calendar {
    --date-size: 40px;

    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    outline: none;

    :host([state-keyboard-focus]) &:focus {
      background: var(--one-ux-palette--keyboard-focus-100);
    }

    &:is([aria-disabled='true'], [aria-readonly='true']) {
      pointer-events: none;
    }

    th {
      width: var(--date-size);
      height: 1em;
      color: var(--one-ux-palette--gray-400);
      font: var(--one-ux-font--body-200);
      letter-spacing: var(--one-ux-letter-spacing--body-200);
      padding: 0;
    }

    td {
      height: var(--date-size);
      padding: 0;
    }
  }

  .date {
    --date-background: transparent;
    --range-connector-background: transparent;

    text-align: center;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    color: var(--one-ux-palette--brand-neutral-600);
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);

    &:hover:not([aria-disabled='true']) {
      --date-background: var(--one-ux-palette--focus-200);
    }

    &[aria-disabled='true'] {
      &.in-range {
        --date-background: transparent !important;
        --range-connector-background: var(--one-ux-palette--gray-200) !important;
      }

      &.range-start,
      &.range-end {
        --date-background: var(--one-ux-palette--gray-300) !important;
        --range-connector-background: var(--one-ux-palette--gray-200) !important;
      }

      cursor: default;
      background: var(--one-ux-palette--gray-150);
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      bottom: 4px;
      right: 0;
      left: 0;
      z-index: 0;
      background: var(--range-connector-background);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      bottom: 4px;
      border-left: 16px solid var(--date-background);
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      opacity: 0;
    }

    .date-active-highlight {
      position: absolute;
      pointer-events: none;
      inset: 0;
      border: 2px solid transparent;
      border-radius: var(--one-ux-radius--300);
    }

    &.active {
      .calendar:focus & {
        .date-active-highlight {
          border-color: var(--one-ux-palette--focus-400);
        }
      }

      :host([state-keyboard-focus]) .calendar:focus & {
        .date-active-highlight {
          border-color: var(--one-ux-palette--keyboard-focus-400);
        }
      }

      .calendar:focus &[aria-disabled='true'],
      .calendar[aria-disabled='true']:focus & {
        .date-active-highlight {
          border-color: var(--one-ux-palette--gray-500);
        }
      }

      .calendar[aria-readonly='true']:focus & {
        .date-active-highlight {
          border-color: transparent;
        }
      }

      :host([state-keyboard-focus]) .calendar[aria-readonly='true']:focus & {
        .date-active-highlight {
          border-color: var(--one-ux-palette--gray-500);
        }
      }
    }

    .date-text {
      vertical-align: top;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: var(--one-ux-radius--200);
      z-index: 1;
      position: relative;
      background: var(--date-background);
      box-sizing: border-box;
    }

    &.today {
      .date-text {
        &:after {
          content: '';
          position: absolute;
          bottom: 6px;
          left: 8px;
          right: 8px;
          height: 2px;
          background-color: currentColor;
          border-radius: var(--one-ux-radius--200);
        }
      }
    }

    &.padding {
      color: var(--one-ux-palette--gray-400);
    }

    &.in-range {
      --range-connector-background: var(--one-ux-palette--focus-200);

      &:hover {
        --date-background: var(--one-ux-palette--focus-300);
        --range-connector-background: var(--one-ux-palette--focus-300);
      }
    }

    &.range-start,
    &.range-end {
      --date-background: var(--one-ux-palette--focus-300);
      --range-connector-background: var(--one-ux-palette--focus-200);

      &:hover {
        --date-background: var(--one-ux-palette--focus-400);
      }

      &:after {
        opacity: 1;
      }
    }

    &.range-start {
      &:before {
        left: 50%;
      }

      &:after {
        right: 0;
        scale: 1 1;
      }
    }

    &.range-end {
      &:before {
        right: 50%;
      }

      &:after {
        left: 0;
        scale: -1 1;
      }
    }

    &.range-start.range-end {
      &:before,
      &:after {
        opacity: 0;
      }
    }

    &.overlapping {
      &.in-range,
      &.range-start,
      &.range-end {
        &:before {
          border-top: 2px solid var(--range-connector-background);
          border-bottom: 2px solid var(--range-connector-background);
          background: none;
        }
      }

      &.range-start,
      &.range-end {
        &:after {
          display: none;
        }

        .date-text {
          border-top: 2px solid var(--range-connector-background);
          border-bottom: 2px solid var(--range-connector-background);
          background: none;

          &:after {
            bottom: 4px;
          }
        }
      }
    }
  }
`;