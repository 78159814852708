import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
import { PropertyValues } from 'lit';
import { InternalElementStateChangedEvent } from '../events/internal/InternalElementStateChangedEvent.js';
export declare class IDisabled {
  disabled: boolean;
}
export const Disabled = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  // TODO: remove any
  if (!(SuperClass as any).__one_ux_mixin_focusable__) {
    throw new Error(`Can only apply Disabled mixin if Focusable mixin is already applied. `);
  }
  class DisabledClass extends SuperClass {
    /**
     * Disables the element. It will still be visible but not possible to interact with.
     * Affects visual appearance and accessibility.
     */
    @property({
      type: Boolean,
      reflect: true
    })
    public accessor disabled = false;
    protected update(changedProperties: PropertyValues): void {
      super.update(changedProperties);
      if (changedProperties.has('disabled')) {
        this.dispatchEvent(new InternalElementStateChangedEvent({
          property: 'disabled',
          value: this.disabled
        }));
      }
    }
    protected firstUpdated(changed: PropertyValues<this>): void {
      super.firstUpdated(changed);
      // The event listener must be registered on shadowRoot or else
      // form associated elements that are disabled will never catch the event.
      // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-disabled
      this.shadowRoot?.addEventListener('click', event => {
        if (this.disabled) {
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      }, {
        capture: true
      });
    }
  }
  return DisabledClass as Constructor<IDisabled> & TSuperClass;
};