import { IAvatarContext, avatarState } from '../../one-ux/contexts/AvatarContext.js';
import { AvatarApiService } from './AvatarApiService.js';
import { AvatarUrlContextCacheRepository } from './cache/AvatarUrlContextCacheRepository.js';
import { FullNameContextCacheRepository } from './cache/FullNameContextCacheRepository.js';
import { StateContextCacheRepository } from './cache/StateContextCacheRepository.js';
import { pdrUser, requestedImageSize, requestedImageSizes } from './types.js';
const avatarUrlCache = new AvatarUrlContextCacheRepository();
const fullNameCache = new FullNameContextCacheRepository();
const stateCache = new StateContextCacheRepository();
const avatarApiService = new AvatarApiService();
export class DefaultAvatarContext implements IAvatarContext {
  async getAvatarUrl(userId: string, preferredSize: number): Promise<string> {
    const size = this.#getRequestedImageSize(preferredSize);
    const tenantId = await this.#getTenantId();
    const {
      url,
      expired
    } = avatarUrlCache.get(tenantId, userId, size);
    if (!expired) {
      return url;
    }
    const newUrl = await avatarApiService.getAvatarUrl(tenantId, userId, size);
    avatarUrlCache.update(tenantId, userId, size, newUrl);
    return newUrl;
  }
  async getFullName(userId: string): Promise<string> {
    const tenantId = await this.#getTenantId();
    const {
      fullName,
      expired
    } = fullNameCache.get(tenantId, userId);
    if (!expired) {
      return fullName;
    }
    const newFullName = await avatarApiService.getFullName(tenantId, userId);
    fullNameCache.update(tenantId, userId, newFullName);
    return newFullName;
  }
  async getState(userId: string): Promise<avatarState> {
    const tenantId = await this.#getTenantId();
    const {
      state,
      expired
    } = stateCache.get(tenantId, userId);
    if (!expired) {
      return state;
    }
    let newState: avatarState = 'none';
    try {
      newState = await avatarApiService.getState(tenantId, userId);
    } catch {
      /* empty */
    }
    stateCache.update(tenantId, userId, newState);
    return newState;
  }
  #getRequestedImageSize(preferredSize: number): requestedImageSize {
    const fitSize = [...requestedImageSizes].sort((a, b) => Math.abs(a - preferredSize) - Math.abs(b - preferredSize))[0];
    const nextIndex = requestedImageSizes.indexOf(fitSize) + 1;
    return requestedImageSizes[Math.min(nextIndex, requestedImageSizes.length - 1)];
  }
  async #getTenantId(): Promise<string> {
    const user: pdrUser = await window.PDR.session.getUser();
    return user.profile.tid;
  }
}