import { css } from 'lit';
export const style = css`
  :host {
    vertical-align: middle;
    max-width: 100%;
  }

  .one-ux-element--root {
    display: flex;
    align-items: center;
  }

  .field-inner {
    flex: 1 1 auto;
    min-width: 0;
    display: flex;
    align-items: center;
    padding-left: var(--one-ux-spacing--normal);
    padding-right: var(--one-ux-spacing--normal);
    gap: var(--one-ux-spacing--small);
    outline: none;
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-font--letter-spacing-300);

    &.empty::before {
      content: '\\200b';
      align-self: center;
    }

    &.empty .field-inner-content {
      color: var(--one-ux-palette--brand-neutral-400);
    }
  }

  .field-inner:not([aria-disabled='true'], [aria-readonly='true']) {
    cursor: pointer;
  }

  .field-inner-content {
    flex: 1 1 auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .field-icon {
    color: var(--one-ux-palette--brand-neutral-600);

    :host([disabled]) & {
      color: var(--one-ux-palette--gray-400);
    }
  }

  slot:not([name='preview'])::slotted(:not([one-ux-element])) {
    max-width: 49vw;
    max-height: 35vh;
    width: 100%;
  }

  [one-ux-element='one-ux-popout'] {
    --one-ux-element-internal--popout-constraint-max-width: 49vw; /* This is a fallback, actual value is set dynamically */
    --one-ux-element-internal--popout-constraint-max-height: 35vh;
    --one-ux-element-internal--popout-constraint-width: 100%;
    --one-ux-element-internal--popout-constraint-indent: var(--one-ux-spacing--300) var(--one-ux-spacing--500);
  }
`;