import { html, render } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { createRef, Ref, ref } from 'lit/directives/ref.js';
import { animateHide, animateShow } from './animations.js';
import { OneUxIconToken } from '../../one-ux/generated/design-tokens.js';
import { register as _registerElement } from "../../one-ux/elements/one-ux-feedback/register-element.js";
import { register as _registerElement2 } from "../../one-ux/elements/one-ux-icon/register-element.js";
_registerElement2("icon-797fa506076e7d0e73784ef85f4fac16");
_registerElement("feedback-afcc934037a2e44288290ffa03248d8b");
const DEFAULT_DURATION = 4000;
let FEEDBACK_ID_GENERATOR = 1;
export class Feedback {
  id: number;
  message: string;
  icon?: icon;
  duration?: number | boolean;
  constructor(message: string, icon?: icon, duration: number | boolean = DEFAULT_DURATION) {
    this.id = FEEDBACK_ID_GENERATOR++;
    this.message = message;
    this.icon = icon;
    this.duration = duration === false ? false : Number.isInteger(duration) ? duration : DEFAULT_DURATION;
  }
  $ref: Ref<HTMLElement> = createRef();
}
export type icon = {
  set: keyof OneUxIconToken;
  icon: OneUxIconToken[keyof OneUxIconToken];
};
export class FeedbackQueue {
  #feedbacks: Feedback[] = [];
  #currentFeedback: Feedback;
  #timeoutId: ReturnType<typeof setTimeout>;
  #mouseActive = false;
  #pendingMouseInactive: ReturnType<typeof setTimeout>;
  constructor() {
    document.addEventListener('keydown', event => {
      if (this.#currentFeedback && event.ctrlKey && event.key === 'q') {
        event.preventDefault();
        this.#handleDismiss(this.#currentFeedback);
      }
    });
    document.addEventListener('mousedown', this.#updateMouseActivity, {
      capture: true
    });
    document.addEventListener('mouseup', this.#updateMouseActivity, {
      capture: true
    });
  }
  public add(feedback: Feedback) {
    this.#feedbacks.push(feedback);
    this.#update();
    animateShow(feedback.$ref.value!);
  }
  async #remove(feedback: Feedback) {
    const index = this.#feedbacks.indexOf(feedback);
    await animateHide(feedback.$ref.value!);
    this.#feedbacks.splice(index, 1);
    if (!this.#feedbacks.length) {
      this.#currentFeedback = undefined;
    }
    this.#update();
  }
  #update() {
    this.#handleCurrentDuration();
    render(this.#render(), this.#root);
  }
  #handleCurrentDuration() {
    const hasNewCurrentFeedback = this.#currentFeedback !== this.#feedbacks[0] && this.#feedbacks.length;
    if (hasNewCurrentFeedback) {
      this.#currentFeedback = this.#feedbacks[0];
      if (typeof this.#currentFeedback.duration === 'number') {
        this.#timeoutId = setTimeout(() => {
          this.#remove(this.#currentFeedback!);
        }, this.#currentFeedback.duration);
      }
    }
  }
  get #root() {
    const rootId = '__PDR_feedback_root__';
    let $root = document.getElementById(rootId);
    if (!$root) {
      $root = document.createElement('div');
      $root.id = rootId;
      document.body.appendChild($root);
    }
    return $root;
  }
  #handleDismiss(feedback: Feedback) {
    if (feedback === this.#currentFeedback) {
      clearTimeout(this.#timeoutId);
    }
    this.#remove(feedback);
  }
  #updateMouseActivity = () => {
    this.#mouseActive = true;
    clearTimeout(this.#pendingMouseInactive);
    this.#pendingMouseInactive = setTimeout(() => {
      this.#mouseActive = false;
    }, 300_000);
  };
  #render() {
    return this.#feedbacks.length ? html`<div
          style=${styleMap({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 'var(--one-ux-spacing--large)',
      position: 'fixed',
      inset: 'auto 0 var(--one-ux-spacing--500) 0',
      pointerEvents: 'none',
      zIndex: 2147483646
    })}
        >
          ${repeat(this.#feedbacks.toReversed(), feedback => feedback.id, feedback => {
      return html`<div
                ${ref(feedback.$ref)}
                style=${styleMap({
        pointerEvents: 'auto',
        maxWidth: '460px'
      })}
              >
                <feedback-afcc934037a2e44288290ffa03248d8b dismissible @dismiss=${() => this.#handleDismiss(feedback)} label=${feedback.message}>
                  ${feedback.icon ? html`<icon-797fa506076e7d0e73784ef85f4fac16
                        slot="start"
                        icon=${feedback.icon.icon}
                        set=${feedback.icon.set}
                        color="gray-100"
                      ></icon-797fa506076e7d0e73784ef85f4fac16>` : null}
                  ${this.#currentFeedback === feedback && !this.#mouseActive ? html`<div
                        aria-hidden="true"
                        slot="end"
                        style=${styleMap({
        font: 'var(--one-ux-font--mono-variant-300)',
        lineHeight: 'var(--one-ux-size--icon-300)',
        background: 'var(--one-ux-palette--keyboard-focus-300)',
        padding: '0 var(--one-ux-spacing--200)',
        color: 'var(--one-ux-palette--keyboard-focus-700)',
        borderRadius: 'var(--one-ux-radius--300)'
      })}
                      >
                        Ctrl+Q
                      </div>` : null}
                </feedback-afcc934037a2e44288290ffa03248d8b>
              </div>`;
    })}
        </div>` : null;
  }
}