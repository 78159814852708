import { ReactiveController } from 'lit';
import { OneUxElement } from '../OneUxElement.js';
export type resizeProperty = {
  __resize__: number;
};
export class UpdateOnResizedController implements ReactiveController {
  #width = 0;
  #observer = new ResizeObserver(entries => {
    const newWidth = entries[0].contentRect.width;
    if (this.#width > 0 && this.#width !== newWidth) {
      this.host.requestUpdate('__resize__', this.#width);
    }
    this.#width = newWidth;
  });
  constructor(private host: OneUxElement) {
    this.host.addController(this);
  }
  hostConnected(): void {
    this.#observer.observe(this.host);
  }
  hostDisconnected(): void {
    this.#observer.disconnect();
  }
}