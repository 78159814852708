import { OneUxPaletteToken } from '../../../generated/design-tokens.js';
import { html, TemplateResult } from 'lit';
import { StyleInfo, styleMap } from 'lit/directives/style-map.js';
import { iconName, iconSet, listOption } from '../types.js';
import paletteKeys from '../../../generated/json/palette/palette-keys.json';
import { register as _registerElement } from "../../one-ux-icon/register-element.js";
_registerElement("icon-6858e235b6192e4ebea04206c157102b");
type Settings = {
  colorOverride?: OneUxPaletteToken;
  truncate?: boolean;
};
const Icon = (icon: iconName, set?: iconSet, color?: OneUxPaletteToken | string) => html`<icon-6858e235b6192e4ebea04206c157102b
    .icon=${icon}
    .set=${set ?? 'default'}
    size="300"
    style=${styleMap({
  color: color && paletteKeys.includes(color) ? `var(--one-ux-palette--${color})` : color
})}
  ></icon-6858e235b6192e4ebea04206c157102b>`;
export function OptionContent(option: listOption, settings?: Settings) {
  const {
    colorOverride,
    truncate
  } = settings || {};
  const beforeIcons = [] as TemplateResult[];
  const afterIcons = [] as TemplateResult[];
  if (option.icons?.length) {
    for (const optionIcon of option.icons) {
      if (optionIcon.position === 'after') {
        afterIcons.push(Icon(optionIcon.name, optionIcon.set, colorOverride ?? optionIcon.color));
      } else {
        beforeIcons.push(Icon(optionIcon.name, optionIcon.set, colorOverride ?? optionIcon.color));
      }
    }
  }
  const textStyles: StyleInfo = {
    flex: '1 1 auto',
    minWidth: '0'
  };
  if (truncate) {
    textStyles.maxWidth = '100%';
    textStyles.overflow = 'hidden';
    textStyles.textOverflow = 'ellipsis';
  } else {
    textStyles.overflowWrap = 'break-word';
  }

  // Style must be inline since the component is used in multiple shadowDom.
  return html`<div
    class="option-content"
    style=${styleMap({
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    minWidth: '0',
    gap: 'var(--one-ux-spacing--normal)'
  })}
  >
    ${beforeIcons}
    <div style=${styleMap(textStyles)}>${option.text}</div>
    ${afterIcons}
  </div>`;
}