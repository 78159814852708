const settings = {
  message: false,
  info: false,
  warning: false,
  error: false,
  debug: false,
  timer: false,
  tracker: false
};
updateSettings({}); // Set initial default values

export function updateSettings(newSettings: Partial<typeof settings>) {
  settings.message = typeof newSettings.message !== 'undefined' ? !!newSettings.message : true;
  settings.info = typeof newSettings.info !== 'undefined' ? !!newSettings.info : true;
  settings.warning = typeof newSettings.warning !== 'undefined' ? !!newSettings.warning : true;
  settings.error = typeof newSettings.error !== 'undefined' ? !!newSettings.error : true;
  settings.debug = typeof newSettings.debug !== 'undefined' ? !!newSettings.debug : false;
  settings.timer = typeof newSettings.timer !== 'undefined' ? !!newSettings.timer : false;
  settings.tracker = typeof newSettings.tracker !== 'undefined' ? !!newSettings.tracker : false;
}
export type message = {
  title: string;
  message?: string;
  details?: unknown;
  id?: string;
};
type messageType = 'message' | 'info' | 'warning' | 'deprecation' | 'error' | 'debug' | 'timer' | 'tracker';
type eventDetails = {
  type: messageType;
  message: message;
  time?: number;
};
const LOCAL_STORAGE_KEY = '__pdr_log_enable_trace__';
const A_DAY = 24 * 60 * 60 * 1000;
let stackTracesEnabled = false;
const stackTraceTimeStamp = Number(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '0');
if (Date.now() - stackTraceTimeStamp < A_DAY) {
  stackTracesEnabled = true;
  setTimeout(() => {
    PDR?.log?.info('Stack traces are enabled.');
  }, 50);
} else {
  localStorage.setItem(LOCAL_STORAGE_KEY, String(0));
}
export function enableStackTraces() {
  if (confirm(`Enabling stack traces for PDR.log incurs a performance penalty and should only be done for development and bug hunting.
      
They are automatically disabled after 24 hours and can be manually disabled prior to that by calling PDR.log.disableStackTraces().`)) {
    localStorage.setItem(LOCAL_STORAGE_KEY, String(Date.now()));
    stackTracesEnabled = true;
    PDR?.log?.info('Stack traces are enabled.');
  }
}
export function disableStackTraces() {
  localStorage.setItem(LOCAL_STORAGE_KEY, String(0));
  stackTracesEnabled = false;
  PDR?.log?.info('Stack traces are disabled.');
}
export class Log {
  constructor(private prefix = 'PDR') {}
  message(message: string | message) {
    if (!settings.message) {
      return;
    }
    message = this.#normalizeMessage(message);
    this.#output('message', message);
  }
  info(message: string | message) {
    if (!settings.info) {
      return;
    }
    message = this.#normalizeMessage(message);
    this.#output('info', message);
  }
  warning(message: string | message) {
    if (!settings.warning) {
      return;
    }
    message = this.#normalizeMessage(message);
    this.#output('warning', message);
  }
  deprecation(message: string | message) {
    message = this.#normalizeMessage(message);
    this.#output('deprecation', message);
  }
  error(message: string | message) {
    if (!settings.error) {
      return;
    }
    message = this.#normalizeMessage(message);
    this.#output('error', message);
  }
  debug(message: string | message) {
    if (!settings.debug) {
      return;
    }
    message = this.#normalizeMessage(message);
    this.#output('debug', message);
  }
  timer(message?: string | message) {
    const startTime = performance.now();
    return {
      stop: () => {
        if (!settings.timer) {
          return;
        }
        const time = performance.now() - startTime!;
        message = this.#normalizeMessage(message || '(Ellapsed time)');
        message.title = `[${Math.round(time)}ms] ${message.title}`;
        this.#output('timer', message, time);
      }
    };
  }
  tracker(trackName?: string) {
    const startTime = performance.now();
    const trackerId = (startTime * 200 + Math.random() * 10000).toFixed(0);
    return {
      track: (message: string | message) => {
        message = this.#normalizeMessage(message);
        message.id = trackerId;
        const elapsedTime = performance.now() - startTime!;
        message.title = trackName ? `${trackName} - ${message.title}` : message.title;
        message.details = Object.assign(message.details || {}, {
          __elapsedTime: elapsedTime
        });
        this.#output('tracker', message);
      }
    };
  }
  #normalizeMessage(message: string | message): message {
    if (typeof message !== 'object') {
      return {
        title: message + ''
      };
    }
    return message;
  }
  #output(type: messageType, message: message, time?: number) {
    const logo = '  '; // Intentional blank spaces reserved for CSS styling
    const prefix = this.#getFormattedPrefix(type);
    const title = message.title;
    console.groupCollapsed(`%c${logo}%c${prefix}%c${title}`, this.#getLogoStyle(), this.#getPrefixStyle(type), this.#getTitleStyle());
    if (message.message) {
      console.log((message as message).message);
    }
    if (message.details) {
      console.groupCollapsed('🗄️ Details');
      console.dir((message as message).details);
      console.groupEnd();
    }
    if (message.id) {
      console.groupCollapsed('🆔 Id');
      console.log((message as message).id);
      console.groupEnd();
    }
    console.groupCollapsed('🔍 Stacktrace');
    if (stackTracesEnabled) {
      console.trace('Log called from:');
    } else {
      console.log('Stack traces are disabled by default for performance reassons. To enable run PDR.log.enableStackTraces()');
    }
    console.groupEnd();
    console.groupEnd();
    this.#notify({
      type,
      message,
      time
    });
  }
  #getFormattedPrefix(type: messageType) {
    switch (type) {
      case 'info':
        return `💡 ${this.prefix}`;
      case 'warning':
        return `⚠️ ${this.prefix}`;
      case 'deprecation':
        return `💣 ${this.prefix}`;
      case 'error':
        return `🚩 ${this.prefix}`;
      case 'debug':
        return `🐞 ${this.prefix}`;
      case 'timer':
        return `⏱️ ${this.prefix}`;
      case 'tracker':
        return `👀 ${this.prefix}`;
      default:
        return this.prefix;
    }
  }
  #getLogoStyle() {
    return `
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="14 14 28 28" fill="rgb(1,156,162)"><g fill-rule="evenodd"><path d="M23.66 40.956l1.933-3.037a10.948 10.948 0 0 1-2.088-1.743 11.095 11.095 0 0 1-2.96-7.887c.104-4.014 2.253-7.439 5.419-9.278L24.03 16c-4.098 2.482-6.893 6.99-7.022 12.2a14.708 14.708 0 0 0 6.651 12.756z"></path><path d="M33.09 18.826h-.285c-5.366 0-9.809 4.093-10.266 9.366h3.546c.448-3.23 3.321-5.757 6.72-5.757h.19c.76.017 1.493.167 2.183.414l1.958-3.081a10.13 10.13 0 0 0-4.046-.942z"></path><path d="M38.896 31.15c.406-3.108-1.57-5.96-4.477-6.585a5.545 5.545 0 0 0-4.374.88l1.742 2.747c.5-.361 1.122-.555 1.77-.467.068.01.137.018.198.036 1.285.281 2.148 1.575 1.967 2.966a2.744 2.744 0 0 1-1.122 1.884l1.743 2.746a5.992 5.992 0 0 0 2.553-4.207z"></path></g></svg>') center no-repeat;
      background-size: 16px;
    `;
  }
  #getPrefixStyle(type: messageType) {
    const colors = this.#getPrefixColors(type);
    return `
      border-width: 0 2px;
      border-style: solid;
      border-color: ${colors[0]};
      background: ${colors[1]};
      padding: 2px 6px;
      margin: 0 5px;
      font-weight: 700;
    `;
  }
  #getPrefixColors(type: messageType) {
    switch (type) {
      case 'info':
        return ['#7dbbea', '#d6edfa'];
      case 'warning':
        return ['#ffdd00', '#fff9c3'];
      case 'deprecation':
        return ['#fba112', '#ffd57a'];
      case 'error':
        return ['#f5432f', '#fddedd'];
      default:
        return ['#bdc3c7', '#ecf0f1'];
    }
  }
  #getTitleStyle() {
    return `
      font-weight: 400;
    `;
  }
  #notify(details: eventDetails) {
    document.dispatchEvent(new CustomEvent('pdr-log', {
      detail: {
        prefix: this.prefix,
        ...details
      }
    }));
  }
}