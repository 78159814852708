import { LitElement, ReactiveController, PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
import { hasMouseActivity, hasMouseMovement } from '../utils/mouse-helper.js';
export declare class IFocusable {
  hasKeyboardFocus: boolean;
}
export const FocusableFactory = (delegatesFocus: boolean) => <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => Focusable(SuperClass, delegatesFocus);
export const Focusable = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass, delegatesFocus = true) => {
  class FocusableClass extends SuperClass {
    static shadowRootOptions = {
      ...LitElement.shadowRootOptions,
      delegatesFocus
    };
    static get __one_ux_mixin_focusable__() {
      return true as const;
    }
    constructor(...args: any[]) {
      super(...args);
      new FocusableController(this);
    }

    /**
     * @ignore
     */
    @property({
      type: Boolean,
      reflect: true,
      attribute: 'state-keyboard-focus'
    })
    public accessor hasKeyboardFocus = false;
    update(dirty: PropertyValues) {
      super.update(dirty);
      if (delegatesFocus && !this.hasAttribute('one-ux-focusable')) {
        this.toggleAttribute('one-ux-focusable', true);
      }
    }
  }
  return FocusableClass as Constructor<IFocusable> & TSuperClass;
};
export class FocusableController implements ReactiveController {
  constructor(private host: OneUxElement & IFocusable) {
    this.host.addController(this);
  }
  hostConnected(): void {
    this.host.addEventListener('focus', this.#handleFocus, true);
    this.host.addEventListener('blur', this.#handleBlur, true);
    this.host.addEventListener('mousedown', this.#handleMouseDown, true);
  }
  hostDisconnected(): void {
    this.host.removeEventListener('focus', this.#handleFocus, true);
    this.host.removeEventListener('blur', this.#handleBlur, true);
    this.host.removeEventListener('mousedown', this.#handleMouseDown, true);
  }
  #handleFocus = () => {
    this.host.hasKeyboardFocus = !(hasMouseActivity() || hasMouseMovement());
  };
  #handleBlur = () => {
    this.host.hasKeyboardFocus = false;
  };
  #handleMouseDown = () => {
    this.host.hasKeyboardFocus = false;
  };
}