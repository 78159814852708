import { css } from 'lit';
export const style = css`
  .one-ux-element--root {
    padding: 0 var(--one-ux-spacing--200) 0 calc(var(--one-ux-spacing--300) - var(--one-ux-spacing--100));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  span {
    color: var(--one-ux-palette--brand-neutral-600);
    white-space: nowrap;
    overflow: scroll;

    display: grid;
    place-content: start;

    &:focus {
      outline: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      grid-area: 1 / 1;

      &[hidden] {
        visibility: hidden;
      }
    }
  }
`;