import { LanguageSets } from '../../utils/getLangCode.js';
export type LanguageSet = {
  close: string;
  expand: string;
  card: string;
};
export const strings: LanguageSets<LanguageSet> = {
  en: {
    close: 'Close',
    expand: 'Expand',
    card: 'Card'
  },
  sv: {
    close: 'Stäng',
    expand: 'Expandera',
    card: 'Kort'
  },
  nb: {
    close: 'Lukk',
    expand: 'Utvid',
    card: 'Kort'
  },
  fi: {
    close: 'Sulje',
    expand: 'Laajenna',
    card: 'Kortti'
  },
  da: {
    close: 'Luk',
    expand: 'Udvid',
    card: 'Kort'
  }
};