import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
import { property } from 'lit/decorators.js';
import { AfterCloseEvent } from '../events/AfterCloseEvent.js';
import { AfterOpenEvent } from '../events/AfterOpenEvent.js';
import { BeforeCloseEvent } from '../events/BeforeCloseEvent.js';
import { BeforeOpenEvent } from '../events/BeforeOpenEvent.js';
export interface IClosed {
  closed: boolean;
}
export interface IOpen {
  open: boolean;
}
type sharedFactoryOptions = {
  action: () => Promise<void>;
  beforeAction?: () => void;
  type: 'open' | 'closed';
};
export type closedFactoryOptions<TExtraProperties = void> = ThisType<OneUxElement & TExtraProperties & IClosed> & sharedFactoryOptions & {
  type: 'closed';
};
export type openFactoryOptions<TExtraProperties = void> = ThisType<OneUxElement & TExtraProperties & IOpen> & sharedFactoryOptions & {
  type: 'open';
};
export const OpenClosedFactory = <TExtraProperties = void,>(options: closedFactoryOptions<TExtraProperties> | openFactoryOptions<TExtraProperties>) => {
  const Closed = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
    if (options.type !== 'closed') {
      throw new Error('Closed mixin can not be used if type is not "closed"');
    }
    class ClosedClass extends SuperClass {
      #closed = false;
      @property({
        type: Boolean,
        reflect: true,
        noAccessor: true
      })
      public set closed(closed: boolean) {
        if (!!closed === this.#closed) {
          return;
        }
        if (closed && !this.hasUpdated) {
          this.#update(closed);
          return;
        }
        const beforeEvent = closed ? new BeforeCloseEvent() : new BeforeOpenEvent();
        const afterEvent = closed ? new AfterCloseEvent() : new AfterOpenEvent();
        if (toggle(this, beforeEvent, afterEvent, options.action.bind(this), options.beforeAction?.bind(this))) {
          this.#update(closed);
        }
      }
      public get closed() {
        return this.#closed;
      }
      #update(newValue: boolean) {
        const oldValue = this.#closed;
        this.#closed = !!newValue;
        this.requestUpdate('closed', oldValue);
      }
    }
    return ClosedClass as Constructor<IClosed> & TSuperClass;
  };
  const Open = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
    if (options.type !== 'open') {
      throw new Error('Open mixin can not be used if type is not "open"');
    }
    class OpenClass extends SuperClass {
      #open = false;
      @property({
        type: Boolean,
        reflect: true,
        noAccessor: true
      })
      public set open(open: boolean) {
        if (!!open === this.#open) {
          return;
        }
        if (open && !this.hasUpdated) {
          this.#update(open);
          return;
        }
        const beforeEvent = open ? new BeforeOpenEvent() : new BeforeCloseEvent();
        const afterEvent = open ? new AfterOpenEvent() : new AfterCloseEvent();
        if (toggle(this, beforeEvent, afterEvent, options.action.bind(this), options.beforeAction?.bind(this))) {
          this.#update(open);
        }
      }
      public get open() {
        return this.#open;
      }
      #update(newValue: boolean) {
        const oldValue = this.#open;
        this.#open = !!newValue;
        this.requestUpdate('open', oldValue);
      }
    }
    return OpenClass as Constructor<IOpen> & TSuperClass;
  };
  return {
    Open,
    Closed
  };
};
function toggle($el: HTMLElement, beforeEvent: BeforeCloseEvent | BeforeOpenEvent, afterEvent: AfterCloseEvent | AfterOpenEvent, action: () => Promise<void>, beforeAction = () => {}) {
  if (!$el.dispatchEvent(beforeEvent)) {
    return false;
  }
  beforeAction();
  requestAnimationFrame(async () => {
    await action();
    requestAnimationFrame(() => $el.dispatchEvent(afterEvent));
  });
  return true;
}