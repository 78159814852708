import { property } from 'lit/decorators.js';
import { OneUxElement } from '../OneUxElement.js';
import { Constructor } from '../utils.js';
export type weight = 'high' | 'normal' | 'low';
export declare class IWeight {
  weight: weight;
}
export const Weight = <TSuperClass extends Constructor<OneUxElement>,>(SuperClass: TSuperClass) => {
  class WeightClass extends SuperClass {
    /**
     * Describes how heavy the component should feel on the page.
     *
     * It has a visual effect but can also have an effect on accessibility.
     */
    @property({
      type: String,
      reflect: true
    })
    public accessor weight: weight = 'normal';
  }
  return WeightClass as Constructor<IWeight> & TSuperClass;
};