import { css } from 'lit';
export const style = css`
  .one-ux-element--root {
    background: var(--one-ux-palette--brand-neutral-600);
    color: var(--one-ux-palette--gray-100);
    border-radius: var(--one-ux-radius--400);
    padding: var(--one-ux-spacing--300) var(--one-ux-spacing--500);
    display: flex;
    align-items: flex-start;
    gap: var(--one-ux-spacing--300);
    min-height: var(--one-ux-size--icon-300);

    &:has([one-ux-element='one-ux-widget-button']) {
      padding-right: var(--one-ux-spacing--300);
    }
  }

  ::slotted([one-ux-element='one-ux-icon']) {
    font-size: var(--one-ux-size--icon-300) !important;
    width: var(--one-ux-size--icon-300) !important;
    height: var(--one-ux-size--icon-300) !important;
    overflow: hidden !important;
    flex-shrink: 0 !important;
  }

  .label {
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    align-self: center;
    min-width: 0;
    overflow-wrap: break-word;
  }
`;