import { InternalSubtab, InternalTab, InternalTabBase } from '../types.js';
import { getTabSiblings } from './tabGetters.js';
export const setActiveTabState = (tabs: InternalTab[], activatedTab: InternalTabBase) => {
  for (const siblingTab of getTabSiblings(tabs, activatedTab)) {
    siblingTab.isActive = false;
  }
  activatedTab.isActive = true;
  switch (activatedTab.type) {
    case 'parent':
      {
        const subtabs = (activatedTab as InternalTab).subtabs;
        const subtab = subtabs.find(x => x.isActive && !x.disabled) || subtabs.find(x => !x.disabled);
        if (subtab) {
          for (const siblingTab of getTabSiblings(tabs, subtab)) {
            siblingTab.isActive = false;
          }
          subtab.isActive = true;
        }
      }
      break;
    case 'sub':
      {
        const parent = (activatedTab as InternalSubtab).parent;
        for (const siblingTab of getTabSiblings(tabs, parent)) {
          siblingTab.isActive = false;
        }
        parent.isActive = true;
      }
      break;
  }
};