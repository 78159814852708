import { createContext } from '@lit/context';
import { NodeData } from './contextual-one-ux-tree/types.js';
export interface ITreeContext {
  tree: NodeData[];
  activeNode: NodeData | null;
  multiple: boolean;
  value: string[];
  setValue(node: NodeData): void;
  setActiveNode(node: NodeData): void;
}
export const defaultTreeContext = {
  tree: [],
  activeNode: null,
  multiple: false,
  value: [],
  setValue() {},
  setActiveNode() {}
} satisfies ITreeContext;
export const treeContext = createContext<ITreeContext>('treeContext');