import { PropertyValues, TemplateResult, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { consume } from '@lit/context';
import { OneUxElement } from '../../OneUxElement.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { HidableTooltip } from '../../mixins/HidableTooltip.js';
import { Implicit } from '../../mixins/Implicit.js';
import { Weight } from '../../mixins/Weight.js';
import { avatarContext, avatarState, defaultAvatarContext } from '../../contexts/AvatarContext.js';
import { style } from './style.js';
import { getLangCode, langCode } from '../../utils/getLangCode.js';
import { LanguageSet, lang } from './language.js';
import { weightToSize } from './weightToSize.js';
import { classMap } from 'lit/directives/class-map.js';
import { Optional } from '../../types.js';
import { styleMap } from 'lit/directives/style-map.js';
import { avatarBackgroundColor, avatarBackgroundColors } from './avatarBackgroundColors.js';
import { register as _registerElement } from "../one-ux-icon/register-element.js";
_registerElement("icon-f0f18f22c948224193691d6ac968d43f");
type renderType = 'removed' | 'anonymized' | 'impersonated' | 'avatar-url' | 'initials' | 'unknown' | 'none';
const Styled = StyledFactory(style);
const BaseClass = HidableTooltip(Implicit(Weight(Styled(OneUxElement))));
export class OneUxAvatarElement extends BaseClass {
  static get elementType() {
    return 'one-ux-avatar';
  }
  @consume({
    context: avatarContext,
    subscribe: true
  })
  avatarContext = defaultAvatarContext;
  @property({
    type: String,
    attribute: 'user-id'
  })
  public accessor userId = '';
  @property({
    type: String,
    attribute: 'full-name'
  })
  public accessor fullName = '';
  @property({
    type: String,
    attribute: 'avatar-url'
  })
  public accessor avatarUrl = '';
  @property({
    type: String,
    attribute: 'state'
  })
  public accessor state: avatarState = 'none';
  @state()
  private accessor _state: Optional<avatarState>;
  @state()
  private accessor _fullName: Optional<string>;
  @state()
  private accessor _avatarUrl: Optional<string>;
  @state()
  private accessor _invalidAvatarUrl = false;
  #langCode: langCode;
  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('avatarUrl')) {
      this._invalidAvatarUrl = false;
    }
    if (changed.has('userId')) {
      this._state = undefined;
      this._fullName = undefined;
      this._avatarUrl = undefined;
    }
  }
  render() {
    this.#updateFromContext();
    const {
      tooltipText,
      accessibleText,
      hasState,
      avatarBackgroundColor,
      content
    } = this.#getRenderOptions();
    return html`
      <div
        class=${classMap({
      'one-ux-element--root': true,
      'has-state': hasState
    })}
        style=${styleMap({
      '--one-ux-avatar--background': `var(--one-ux-palette--${avatarBackgroundColor})`
    })}
        role="img"
        aria-roledescription=${this.#translationOf('avatar')}
        aria-label=${accessibleText}
        one-ux-tooltip=${ifDefined(!this.hideTooltip ? tooltipText : undefined)}
        one-ux-tooltip-fixed
        one-ux-tooltip-custom-aria
      >
        ${content}
      </div>
    `;
  }
  #getRenderOptions(): {
    tooltipText?: string;
    accessibleText: string;
    hasState: boolean;
    avatarBackgroundColor: avatarBackgroundColor;
    content: TemplateResult<1>;
  } {
    const fullName = this.fullName || this._fullName;
    const avatarUrl = this.avatarUrl || this._avatarUrl;
    const size = weightToSize[this.weight];
    const radius = size / 2;
    const avatarBackgroundColor = this.#getAvatarBackgroundColor();
    switch (this.#getRenderType()) {
      case 'initials':
        return {
          accessibleText: fullName || this.#translationOf('unknownUser'),
          tooltipText: fullName,
          hasState: false,
          avatarBackgroundColor,
          content: html`
          <svg aria-hidden="true">
            <text
              x=${radius}
              y=${radius}
              dy=${this.weight === 'high' ? 2 : 1}
              dominant-baseline="middle"
              text-anchor="middle">
              ${this.#initials}
            <text>
          </svg>`
        };
      case 'avatar-url':
        return {
          accessibleText: fullName || this.#translationOf('unknownUser'),
          tooltipText: fullName,
          hasState: false,
          avatarBackgroundColor,
          content: html` <img
            src=${avatarUrl!}
            aria-hidden="true"
            loading="lazy"
            @error=${() => {
            this._invalidAvatarUrl = true;
          }}
          />`
        };
      case 'removed':
        return {
          accessibleText: this.#translationOf('removedUser'),
          tooltipText: this.#translationOf('removedUser'),
          hasState: true,
          avatarBackgroundColor,
          content: html`
            <svg aria-hidden="true">
              <circle r=${radius - 0.5} cx=${radius} cy=${radius} />
            </svg>
            <icon-f0f18f22c948224193691d6ac968d43f set="user" icon="removed" size=${this.#iconSize}></icon-f0f18f22c948224193691d6ac968d43f>
          `
        };
      case 'anonymized':
        return {
          accessibleText: this.#translationOf('anonymizedUser'),
          tooltipText: this.#translationOf('anonymizedUser'),
          hasState: true,
          avatarBackgroundColor,
          content: html`
            <svg aria-hidden="true">
              <circle r=${radius - 0.5} cx=${radius} cy=${radius} />
            </svg>
            <icon-f0f18f22c948224193691d6ac968d43f set="user" icon="anonymous" size=${this.#iconSize}></icon-f0f18f22c948224193691d6ac968d43f>
          `
        };
      case 'impersonated':
        {
          return {
            accessibleText: this.#translationOf('impersonatedUser'),
            tooltipText: this.#translationOf('impersonatedUser'),
            hasState: true,
            avatarBackgroundColor,
            content: html`
            <svg aria-hidden="true">
              <circle r=${radius - 0.5} cx=${radius} cy=${radius} />
            </svg>
            <icon-f0f18f22c948224193691d6ac968d43f set="user" icon="impersonator" size=${this.#iconSize}></icon-f0f18f22c948224193691d6ac968d43f>
          `
          };
        }
      case 'unknown':
        {
          return {
            accessibleText: this.#translationOf('unknownUser'),
            tooltipText: this.#translationOf('unknownUser'),
            hasState: true,
            avatarBackgroundColor,
            content: html`
            <svg aria-hidden="true">
              <circle r=${radius - 0.5} cx=${radius} cy=${radius} />
            </svg>
            <icon-f0f18f22c948224193691d6ac968d43f set="user" icon="unknown" size=${this.#iconSize}></icon-f0f18f22c948224193691d6ac968d43f>
          `
          };
        }
      default:
        return {
          accessibleText: this.#translationOf('unknownUser'),
          hasState: false,
          avatarBackgroundColor,
          content: html`<icon-f0f18f22c948224193691d6ac968d43f set="user" icon="unknown" size=${this.#iconSize}></icon-f0f18f22c948224193691d6ac968d43f>`
        };
    }
  }
  #getRenderType(): renderType {
    const state = this.state !== 'none' ? this.state : this._state;
    if (state && state !== 'none') {
      return state;
    }
    const avatarUrl = this.avatarUrl || this._avatarUrl;
    if (avatarUrl && !this._invalidAvatarUrl) {
      return 'avatar-url';
    }
    if (this.#initials) {
      return 'initials';
    }
    return 'none';
  }
  async #updateFromContext() {
    if (!this.userId) {
      return;
    }
    try {
      const shouldUpdateState = this.state === 'none' && !this._state;
      if (shouldUpdateState) {
        this._state = await this.avatarContext.getState(this.userId);
      }
    } catch {
      this._state = 'unknown';
    }
    if (this._state !== 'none') {
      return;
    }
    const shouldUpdateFullName = !this.fullName && !this._fullName;
    if (shouldUpdateFullName) {
      try {
        this._fullName = await this.avatarContext.getFullName(this.userId);
      } catch {
        this._state = 'unknown';
        return;
      }
    }
    const shouldUpdateAvatarUrl = !this.avatarUrl && !this._avatarUrl;
    if (shouldUpdateAvatarUrl) {
      try {
        this._avatarUrl = await this.avatarContext.getAvatarUrl(this.userId, weightToSize[this.weight]);
      } catch {
        /* empty */
      }
    }
  }
  get #initials() {
    const fullName = this.fullName || this._fullName;
    if (!fullName) {
      return '';
    }
    const names = fullName.split(' ').filter(x => x);
    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();
    } else if (names.length > 1) {
      return `${names[0].charAt(0).toUpperCase()}${names.at(-1)!.charAt(0).toUpperCase()}`;
    }
    return '';
  }
  get #iconSize() {
    switch (this.weight) {
      case 'low':
        return '100';
      case 'high':
        return '500';
      default:
        return '300';
    }
  }
  #translationOf(key: keyof LanguageSet) {
    if (!this.#langCode) {
      this.#langCode = getLangCode(this);
      this.setAttribute('lang', this.#langCode);
    }
    return lang[this.#langCode][key];
  }
  #getAvatarBackgroundColor(): avatarBackgroundColor {
    if (!this.fullName) {
      return avatarBackgroundColors[0];
    }
    let seed = this.fullName.charCodeAt(0);
    for (let i = 1; i < this.fullName.length; i++) {
      seed += this.fullName.charCodeAt(i);
    }
    return avatarBackgroundColors[seed % avatarBackgroundColors.length];
  }
}