import { OneUxElement } from '../OneUxElement.js';
import { log } from './log.js';
export class Deprecation {
  #logged = false;
  constructor(private $element: OneUxElement, private title: string, private message: string) {}
  notify() {
    if (this.#logged) {
      return;
    }
    this.#logged = true;
    log.deprecation({
      title: this.title,
      message: this.message,
      details: {
        element: this.$element
      }
    });
  }
}