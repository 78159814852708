import { CardStackContext } from './contexts/card/CardStackContext.js';
import { OneUxStackElement } from './OneUxStackElement.js';
import type { IStackContext, stackContext } from './types.js';
class EmptyContext implements IStackContext {
  get contextType(): stackContext {
    return 'empty';
  }
  onRender(): void {}
  openAction(): Promise<void> {
    return Promise.resolve();
  }
  beforeOpenAction(): void {}
}
export class StackContextResolver {
  #context: IStackContext = new EmptyContext();
  #element: OneUxStackElement;
  constructor($element: OneUxStackElement) {
    this.#element = $element;
  }
  resolve() {
    const attrValue = this.#element.firstElementChild?.getAttribute('one-ux-element') ?? 'empty';
    if (this.#context.contextType === attrValue) {
      return this.#context;
    }
    switch (attrValue) {
      case 'one-ux-card':
        this.#context = new CardStackContext(this.#element);
        break;
      default:
        this.#context = new EmptyContext();
    }
    return this.#context;
  }
}