import { flushAnimations, timingFunction } from '../../utils/animation-utils.js';
import oneUxEasing from '../../generated/json/easing/easing.json';
import oneUxDuration from '../../generated/json/duration/duration.json';
import { IOpen } from '../../mixins/OpenClosed.js';
import type { OneUxElement } from '../../OneUxElement.js';
export const handleOpenCloseBeforeAction = ($el: OneUxElement) => {
  const $parent = queryParentElement($el);
  if ($parent) {
    flushAnimations($parent);
  }
};
export const handleOpenCloseAction = async ($el: OneUxElement & IOpen) => {
  const $parent = queryParentElement($el);
  const $content = queryContentElement($el);
  if (!$content || !$parent) {
    return;
  }
  const height = getAnimationHeight($parent, $content, $el.open);
  const animation = $el.open ? openAnimation(height) : closeAnimation(height);
  const hasTallContent = height > 300;
  await $parent.animate(animation, {
    duration: oneUxDuration[hasTallContent ? 400 : 200] * 2
  }).finished;
};
const openAnimation = (height: number) => [{
  height: 0,
  opacity: 0,
  easing: timingFunction(oneUxEasing.enter)
}, {
  height: height + 'px',
  opacity: 0,
  easing: timingFunction(oneUxEasing.default)
}, {
  height: height + 'px',
  opacity: 1
}];
const closeAnimation = (height: number) => [{
  height: height + 'px',
  opacity: 1,
  visibility: 'visible',
  easing: timingFunction(oneUxEasing.default)
}, {
  height: height + 'px',
  opacity: 0,
  visibility: 'visible',
  easing: timingFunction(oneUxEasing.exit)
}, {
  height: 0,
  opacity: 0,
  visibility: 'hidden'
}];
const getAnimationHeight = ($parent: Element, $content: Element, open: boolean) => {
  const attr = 'animation-height';
  let height = 0;
  if (open) {
    height = $parent.getBoundingClientRect().height;
    $parent.setAttribute(attr, String(height));
  } else {
    height = Number($parent.getAttribute(attr));
    $parent.removeAttribute(attr);
  }
  if (!height) {
    height = $content.getBoundingClientRect().height;
  }
  return height;
};
const queryParentElement = ($el: OneUxElement) => $el.shadowRoot!.querySelector('.expandable-content-parent') as Element;
const queryContentElement = ($el: OneUxElement) => $el.shadowRoot!.querySelector('.expandable-content') as Element;