import { PDRLibrary } from './PDRLibrary.js';
import { updateSettings } from './log/Log.js';
if (!window.SKIP_OVERRIDE_LOG_SETTINGS) {
  updateSettings({
    message: true,
    info: true,
    debug: true,
    error: true,
    timer: true,
    warning: true,
    tracker: true
  });
}
export const runtime = new PDRLibrary();
runtime.start();