import { css } from 'lit';
export const style = css`
  :host {
    max-width: var(--one-ux-element-internal--popout-constraint-max-width, 'none');
    max-height: var(--one-ux-element-internal--popout-constraint-max-height, 'none');
    width: var(--one-ux-element-internal--popout-constraint-width, 'auto');
  }

  :host(:not([explicit])) [one-ux-element='one-ux-contextual-tree'] {
    --contextual-one-ux-tree-element--row-border-radius: var(--one-ux-radius--300);
  }
`;