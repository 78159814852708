import { PropertyValueMap, html } from 'lit';
import { query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { Disabled } from '../../mixins/Disabled.js';
import { Focusable } from '../../mixins/Focusable.js';
import { Implicit } from '../../mixins/Implicit.js';
import { PurposeFactory } from '../../mixins/Purpose.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { InternalValueChangedEvent } from '../../events/internal/InternalValueChangedEvent.js';
import { FormAssociatedFactory } from '../../mixins/FormAssociated.js';
import { IValue, ValueFactory } from '../../mixins/Value.js';
import { Checked, IChecked } from '../../mixins/Checked.js';
import { consume } from '@lit/context';
import { defaultLabelContext, labelContext } from '../../contexts/LabelContext.js';
const Styled = StyledFactory(style);
type valueType = unknown;
const Value = ValueFactory<valueType, IValue<unknown> & IChecked>({
  getter: function () {
    if (this.checked) {
      return this.internalValue;
    }
    return null;
  }
});
const FormAssociated = FormAssociatedFactory<valueType, IChecked>({
  reset: function () {
    this.checked = this.initialChecked;
  }
});
const Purpose = PurposeFactory({
  purposes: ['default', 'main', 'caution', 'notice']
});
const BaseClass = FormAssociated(Value(Checked(Disabled(Focusable(Implicit(Purpose(Styled(OneUxElement))))))));

/**
 * A radio component to be used for user input
 */
export class OneUxRadioElement extends BaseClass {
  static get elementType() {
    return 'one-ux-radio';
  }
  @consume({
    context: labelContext,
    subscribe: true
  })
  labelContext = defaultLabelContext;
  protected willUpdate(changed: PropertyValueMap<this>): void {
    const hasChecked = changed.has('checked');
    const hasValue = changed.has('value');
    if (hasChecked || hasValue) {
      this.dispatchEvent(new InternalValueChangedEvent());
    }
  }
  render() {
    const label = this.labelContext.label || undefined;
    return html`<input
      type="radio"
      aria-label=${ifDefined(label)}
      .checked=${live(this.checked)}
      aria-disabled=${this.disabled}
      @input=${this.#handleInput}
    />`;
  }
  @query('input')
  accessor _radioElement!: HTMLInputElement;
  click() {
    if (this._radioElement) {
      this._radioElement.click();
    }
  }
  #handleInput = (event: Event) => {
    const $target = event.target as HTMLInputElement;
    event.stopPropagation();
    const beforeInputEvent = new InputEvent('beforeinput', {
      bubbles: true,
      composed: true,
      cancelable: true,
      data: $target.checked as never as string
    });
    if (this.dispatchEvent(beforeInputEvent)) {
      this.checked = !this.checked;
      this.dispatchEvent(new Event('input'));
      this.dispatchEvent(new Event('change'));
    } else {
      this.requestUpdate();
    }
  };
}